<div id="modal-password" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-password-label"
     aria-describedby="modal-password-desc">
    <div class="modal-dialog">
        <form [formGroup]="form" ngForm novalidate (submit)="submitModalForm()" role="form">
            <div class="modal-content" role="document" tabindex="0">
                <div class="modal-header">
                    <h2 class="modal-title" id="modal-password-label" data-loop="start" tabindex="0">
                        {{ 'ODC_QUESTIONNAIRE_SAVE_BUTTON' | translate }}
                    </h2>
                </div>

                <!--profile authentication-->
                <ng-container *ngIf="displayAuthenticatePasswordGroup">
                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <div formGroupName="authenticatePasswordGroup">

                            <div *ngIf="showInputPassword">
                                <p>{{'PROFIL_PASSWORD_ALREADY_A_PROFIL'|translate}}</p>

                                <div class="form-horizontal m-t-4 m-b-4">
                                    <div class="form-group">
                                        <label for="password" class="col-md-6 control-label">
                                            <b>{{'PROFIL_PASSWORD_AUTHENTICATE_PASSWORD' | translate}}</b>
                                        </label>
                                        <div class="col-md-18"
                                             [class.has-error]="isFieldInError('authenticatePasswordGroup.password') || errMsg != null">
                                            <label
                                                *ngIf="isFieldInError('authenticatePasswordGroup.password') || errMsg != null"
                                                for="password">
                                                <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                           [path]="'authenticatePasswordGroup.password'"></app-error>

                                                <span *ngIf="errMsg != null" class="help-block" tabindex="0">
                                                    {{errMsg|translate}}
                                                </span>

                                            </label>
                                            <input id="password" class="form-control" name="password" type="password"
                                                   maxlength="32"
                                                   formControlName="password" appRemoveError [errMsg]="errMsg"
                                                   (clearMsg)="clearErrMsg()">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p [innerHTML]="'PROFIL_PASSWORD_FORGOT_TITLE' | translate"></p>
                            <div class="text-center">
                                <a href="javascript:void(0)" class="lien-action"
                                   (click)="modalService.emitShowResetPassword(true)">
                                    {{'PROFIL_PASSWORD_FORGOT_LINK'|translate}}
                                </a>
                            </div>

                        </div>
                    </div>
                </ng-container>

                <!--create profile-->
                <ng-container *ngIf="displayCreateProfileGroup">
                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <div formGroupName="createProfileGroup">

                            <p>{{'PROFIL_REGISTRATION'|translate}}</p>

                            <div class="form-horizontal m-t-4">
                                <div class="form-group">
                                    <label for="newProfileSecurityQuestion" class="col-md-6 control-label">
                                        <b>{{'PROFIL_SECURITY_QUESTION' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('createProfileGroup.newProfileSecurityQuestion')">
                                        <label *ngIf="isFieldInError('createProfileGroup.newProfileSecurityQuestion')"
                                               for="newProfileSecurityQuestion">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'createProfileGroup.newProfileSecurityQuestion'"></app-error>
                                        </label>
                                        <select id="newProfileSecurityQuestion" class="custom-select form-control"
                                                name="newProfileSecurityQuestion"
                                                formControlName="newProfileSecurityQuestion">
                                            <option [ngValue]="null">{{ 'GLOBAL_SELECT' | translate }}</option>
                                            <option *ngFor="let item of questionSecuriteList" [value]="item.itemValue">
                                                {{item.itemLabel}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="newProfileSecurityAnswer" class="col-md-6 control-label">
                                        <b>{{'PROFIL_SECURITY_ANSWER' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('createProfileGroup.newProfileSecurityAnswer')">
                                        <label *ngIf="isFieldInError('createProfileGroup.newProfileSecurityAnswer')"
                                               for="newProfileSecurityAnswer">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'createProfileGroup.newProfileSecurityAnswer'"></app-error>
                                        </label>
                                        <input id="newProfileSecurityAnswer" class="form-control"
                                               name="newProfileSecurityAnswer" type="text"
                                               formControlName="newProfileSecurityAnswer" maxlength="64">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="newProfilePassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_PASSWORD_AUTHENTICATE_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('createProfileGroup.newPassword')
                                         || isFieldInError('passwordStrength')">
                                        <label *ngIf="isFieldInError('createProfileGroup.newPassword')
                                        || isFieldInError('passwordStrength')"
                                               for="newProfilePassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'createProfileGroup.newPassword'"></app-error>
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'passwordStrength'"></app-error>
                                        </label>
                                        <input id="newProfilePassword" class="form-control" name="newPassword"
                                               type="password"
                                               formControlName="newPassword" maxlength="32">
                                        <app-password-strength
                                            [password]="this.form.get('createProfileGroup.newPassword').value"
                                            (strengthChanges)="onPasswordStrengthChanges($event)"></app-password-strength>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="newProfileConfirmPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_CONFIRM_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('createProfileGroup.confirmNewPassword')">
                                        <label *ngIf="isFieldInError('createProfileGroup.confirmNewPassword')"
                                               for="newProfileConfirmPassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'createProfileGroup.confirmNewPassword'"></app-error>
                                        </label>
                                        <input id="newProfileConfirmPassword" class="form-control"
                                               name="confirmNewPassword" type="password"
                                               formControlName="confirmNewPassword" maxlength="32">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="errMsg != null" class="bloc-erreur-generique">
                            {{errMsg|translate}}
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="displayResetPasswordGroup">
                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <!--reset password model-->
                        <div formGroupName="resetPasswordGroup">

                            <div class="form-horizontal m-t-4 m-b-4">
                                <div class="form-group">
                                    <label for="oldPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_SECURITY_QUESTION' | translate}}</b>
                                    </label>
                                    <div class="col-md-18">
                                        <p class="form-control-static">{{questionSecurite.itemLabel}}</p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="resetPasswordSecurityAnswer" class="col-md-6 control-label">
                                        <b>{{'PROFIL_SECURITY_ANSWER' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('resetPasswordGroup.resetPasswordSecurityAnswer')">
                                        <label *ngIf="isFieldInError('resetPasswordGroup.resetPasswordSecurityAnswer')"
                                               for="resetPasswordSecurityAnswer">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'resetPasswordGroup.resetPasswordSecurityAnswer'"></app-error>
                                        </label>
                                        <input id="resetPasswordSecurityAnswer" class="form-control"
                                               name="resetPasswordSecurityAnswer" type="text"
                                               formControlName="resetPasswordSecurityAnswer" maxlength="64"
                                               appRemoveError
                                               [errMsg]="errMsg"
                                               (clearMsg)="clearErrMsg()">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="errMsg != null" class="bloc-erreur-generique">
                            {{errMsg|translate}}
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="displayAuthenticateTempPasswordGroup">

                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <!--authenticate temp password & set new password-->
                        <div formGroupName="authenticateTempPasswordGroup">

                            <p *ngIf="showEmailSend">{{'PROFIL_PASSWORD_EMAIL_SENDED'|translate}}</p>

                            <div class="form-horizontal m-t-4 m-b-4">
                                <div class="form-group">
                                    <label for="oldPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_OLD_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('authenticateTempPasswordGroup.oldPassword')">
                                        <label *ngIf="isFieldInError('authenticateTempPasswordGroup.oldPassword')"
                                               for="oldPassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'authenticateTempPasswordGroup.oldPassword'"></app-error>
                                        </label>
                                        <input id="oldPassword" class="form-control" name="oldPassword" type="password"
                                               formControlName="oldPassword" maxlength="32"
                                               appRemoveError
                                               [errMsg]="errMsg"
                                               (clearMsg)="clearErrMsg()">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="newPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_NEW_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('authenticateTempPasswordGroup.newPassword') || isFieldInError('passwordStrength')">
                                        <label
                                            *ngIf="isFieldInError('authenticateTempPasswordGroup.newPassword') || isFieldInError('passwordStrength')"
                                            for="newPassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'authenticateTempPasswordGroup.newPassword'"></app-error>
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'passwordStrength'"></app-error>
                                        </label>
                                        <input id="newPassword" class="form-control" name="newPassword" type="password"
                                               formControlName="newPassword" maxlength="32"
                                               appRemoveError
                                               [errMsg]="errMsg"
                                               (clearMsg)="clearErrMsg()">
                                        <app-password-strength
                                            [password]="this.form.get('authenticateTempPasswordGroup.newPassword').value"
                                            (strengthChanges)="onPasswordStrengthChanges($event)"
                                        ></app-password-strength>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="confirmNewPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_CONFIRM_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('authenticateTempPasswordGroup.confirmNewPassword')">
                                        <label
                                            *ngIf="isFieldInError('authenticateTempPasswordGroup.confirmNewPassword')"
                                            for="confirmNewPassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'authenticateTempPasswordGroup.confirmNewPassword'"></app-error>
                                        </label>
                                        <input id="confirmNewPassword" class="form-control" name="confirmNewPassword"
                                               type="password"
                                               formControlName="confirmNewPassword" maxlength="32">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <a href="javascript:void(0)" class="lien-action"
                               (click)="modalService.emitShowResetPassword(true)">
                                {{'PROFIL_PASSWORD_FORGOT_LINK'|translate}}
                            </a>
                        </div>

                        <div *ngIf="errMsg != null" class="bloc-erreur-generique">
                            {{errMsg|translate}}
                        </div>
                    </div>
                </ng-container>

                <!-- Support Service -->
                <ng-container *ngIf="displayCustomerService">
                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <p>{{customerServiceMsg|translate}}</p>
                    </div>
                </ng-container>


                <div class="modal-footer">
                    <div
                        *ngIf="displayAuthenticatePasswordGroup || displayCreateProfileGroup || displayResetPasswordGroup || displayAuthenticateTempPasswordGroup"
                        class="btn-resp btn-resp-1colonne-inline">
                        <div *ngIf="showInputPassword" class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-primary loadable"
                                    title="{{'BTN_PASSWORD_VALIDATION'|translate}}"
                                    type="submit"
                                    data-focus="initial"
                                    [class.loading]="loading"
                                    [attr.disabled]="loading ? true : null">
                                {{'BTN_PASSWORD_VALIDATION'|translate}}
                            </button>
                        </div>
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}"
                                    type="button">
                                {{'BTN_CANCEL'|translate}}
                            </button>
                        </div>
                    </div>
                    <button *ngIf="displayCustomerService" class="btn btn-primary"
                            data-dismiss="modal"
                            title="{{'BTN_ABANDON'|translate}}"
                            type="submit"
                            data-focus="initial">
                        {{'BTN_ABANDON'|translate}}
                    </button>
                </div>

                <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>

            </div>
        </form>
    </div>
</div>
