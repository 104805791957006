import {Component} from '@angular/core';
import {WebException} from '../../components/models';
import {ProfileService} from '../../components/services/profile.service';
import {Router} from '@angular/router';
import {ProfileRequestBean} from '../../components/models/ProfileRequestBean';
import {RecoveryService} from '../../components/services/recovery.service';

declare let $: any;

@Component({
    selector: 'app-profile-account-opening-request-list',
    templateUrl: './profile-account-opening-request-list.component.html'
})
export class ProfileAccountOpeningRequestListComponent {

    loading = false;
    list: any[] = null;
    errMsg: {id: number, message: string} = null;

    constructor(private router: Router, private profileService: ProfileService, private recoveryService: RecoveryService) {
    }

    getAccountOpeningRequestList() {
        this.loading = true;
        this.errMsg = null;

        this.profileService.getAccountOpeningRequestList().subscribe((response: any) => {
                this.loading = false;
                this.list = response.payload;
                if (!this.list) {
                    this.errMsg = { id: null, message: 'GLOBAL_ERROR_MSG' };
                } else if (this.list.length === 0) {
                    this.errMsg = { id: null, message: 'ODC_RESET_PASSWORD_ERR_EMPTY_ACCOUNT_OPENING_REQUEST_LIST' };
                } else if (this.list.length === 1) {
                    this.resumeAccountOpeningRequest(this.list[0]);
                }
            },
            (error: WebException) => {
                this.loading = false;
                this.setError(error);
            });
    }

    resumeAccountOpeningRequest(profileDemande: ProfileRequestBean) {
        this.loading = true;
        this.errMsg = null;

        this.recoveryService.recover(profileDemande.numeroDemande).subscribe(data => {
                this.loading = false;
                this.recoveryService.onRecoverySuccess(data);
            },
            (error: WebException) => {
                this.loading = false;
                this.setError(error, profileDemande.numeroDemande);
            });
    }

    setError(error: any, numeroDemande: number = null): void {
        this.errMsg = { id: numeroDemande, message: 'GLOBAL_ERROR_MSG' };

        if (error.listWarning && error.listWarning.length > 0) {
            const warning = error.listWarning[0];
            this.errMsg.message = warning.message;
            if (warning.message === 'odc.login.err.questionnaire.not.found' || warning.message === 'value.is.mandatory' ) {
                this.errMsg.message = 'ODC_LOGIN_ERR_QUESTIONNAIRE_NOT_FOUND';
            }
        }

        setTimeout(() => {
            const errElement = (numeroDemande === null) ? $('#errMsg') : $('#errMsg-' + numeroDemande);
            if (errElement.length > 0) {
                errElement.focus();
            }
        });
    }
}
