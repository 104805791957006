import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from './form.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/index';
import {RequestFollowUpSearchCriteria} from '../models/RequestFollowUpSearchCriteria';
import {Branch} from '../models/Branch';
import {environment} from '../../../environments/environment';

@Injectable()
export class RequestFollowUpService {

    private form: RequestFollowUpSearchCriteria;

    constructor(private translate: TranslateService, private formService: FormService,
                private router: Router, private http: HttpClient,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

        this.initForm({});
    }
    initForm(obj: any): void {

        this.form = new RequestFollowUpSearchCriteria(obj);
    }

    getForm(): RequestFollowUpSearchCriteria {
        return this.form;
    }

    setFormProperty(path, value): void {
        if (path != null && path !== '') {
            this.setObjectProperty(this.form, path, value);

            if (!environment.production && path !== null) {
                console.log('BranchListForm update: ', path, JSON.stringify(value));
            }
        }
    }

    private setObjectProperty(object, path, value): void {

        const propertiesStack = path.split('.');

        while (typeof object !== 'undefined' && propertiesStack.length > 1) {

            object = object[propertiesStack.shift()];
        }

        const lastProperty = propertiesStack.shift();

        if (typeof object !== 'undefined') {

            if (object[lastProperty] instanceof Array) {

                object[lastProperty].push(value);
            } else {

                object[lastProperty] = value;
            }

        }
    }

    public logBranchError(branch: Branch): Observable<any> {
        return this.http.post('api/logBranchError', JSON.stringify(branch));
    }
}
