export class AttachedDocument {

    id: string;
    type: string;
    name: string;
    content: string;
    contentHash : string;
    size: string;

    constructor(obj?: any) {

        this.id = obj && obj.id || null;
        this.type = obj && obj.type || null;
        this.name = obj && obj.name || null;
        this.content = obj && obj.content || null;
        this.contentHash = obj && obj.contentHash || null;
        this.size = obj && obj.size || null;
    }
}
