import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {HtmlSelectKV} from '../models/html-select-k-v';

@Injectable()
export class ListModelService {

    private cache: any = {};

    constructor(private http: HttpClient, private translate: TranslateService) {

    }

    public static getLabelFromList(list: HtmlSelectKV[], key: string) {

        if (list) {

            for (const element of list) {

                if (element.itemValue === key) {

                    return element.itemLabel;
                }
            }
        }

        return '';
    }

    public static getLabelsFromList(list: HtmlSelectKV[], keys: string[], separator?: string): string {

        const result = [];

        for (const key of keys) {
            result.push(list.find((obj) => {
                return obj.itemValue === key;
            }).itemLabel);
        }

        return result.join(separator);
    }

    getListModel(name: string, callback: any, paramKey: string = null, paramValue: string = null): any {

        this.getList(name, this.translate.currentLang, callback, paramKey, paramValue);
        this.translate.onLangChange.subscribe(
            (langChangeEvent: LangChangeEvent) => {
                this.getList(name, this.translate.currentLang, callback, paramKey, paramValue);
            }
        );
    }

    private getList(name: string, lang: string, callback: any, paramKey: string, paramValue: string) {

        let query = '';
        if (paramKey !== null && paramValue !== null) {

            query = '?' + paramKey + '=' + paramValue;
        }

        const path = name + '/' + lang + query;

        if (this.cache[path]) {

            callback(this.cache[path]);
        } else {

            this.http.get('api/list/' + name + '/' + lang + query).subscribe(data => {

                this.cache[path] = data;
                callback(data);
            });
        }
    }
}
