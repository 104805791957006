export const I18N_ERROR_MESSAGES_EN: any = {
    GLOBAL_ERROR_MSG: 'An error has occurred. Please try again later.',
    SERVER_400: 'ERROR',
    SERVER_400_DESCRIPTION: 'An error occurred while processing your request.',
    SERVER_404: 'PAGE NOT FOUND',
    SERVER_404_DESCRIPTION: 'The links below may help you find the information you are looking for.',
    SERVER_500: 'ERROR',
    SERVER_500_DESCRIPTION: 'An error occurred while processing your request.',

    GLOBAL_ERROR_MSG_MAX_LENGTH: 'The maximum length for this field is {{maxlength}} characters',
    GLOBAL_ERROR_CHOOSE_AN_OPTION: 'Choose an option',
    GLOBAL_ERROR_SELECT: 'Please select an answer',

    SERVER_BRANCH_PDO_ERROR_TITLE: 'Warning',
    SERVER_BRANCH_ERROR: 'Error',
    SERVER_BRANCH_ERROR_DESCRIPTION: 'An error has occurred while processing your request.',
    SERVER_PDO_ERROR_DESCRIPTION: 'Please find and select a member beforehand.',

    QUERY_CLIENT_CODE_ERROR_TITLE: 'Warning',
    QUERY_DECRYPTION_ERROR: 'Error',
    QUERY_DECRYPTION_ERROR_DESCRIPTION: 'An error has occurred while decrypting your request.',
    QUERY_CLIENT_CODE_ERROR_DESCRIPTION: 'Please find a client code beforehand.',

    ODC_ADD_ACCOUNT_AUTO_ERR_HOLD_ALL_ACCOUNT: 'The client already holds every type of account that can be added at the moment.',
    ODC_ADD_ACCOUNT_AUTO_ERR_HOLD_TFSA_CLOSED: 'The client has previously held a TFSA account, which was later closed. You cannot re-open this type of account using the same root.',
    ODC_ADD_ACCOUNT_AUTO_ERR_ADD_REQUEST_CREATED: 'A request to add an account has already been created for this client. Please resume request {{pendingrequest}} in order to complete it.',
    ODC_ADD_ACCOUNT_AUTO_ERR_HOLD_DISCRETIONARY_ACCOUNT: 'The client has a discretionary account. It is not possible to add an account using the "Account Opening" workspace.',
    ODC_ADD_ACCOUNT_AUTO_ERR_GUARANTEED_BY_FINANCIAL_INSTITUTION: 'The client\'s account is guaranteed by a financial institution. It is not possible to add an account using the "Account Opening" workspace.',
    ODC_ADD_ACCOUNT_AUTO_ERR_NOT_PERSONAL_ACCOUNT: 'This is not a Personal Account. Therefore, it is not possible to add a TFSA.',

    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_ERROR: 'Please select an answer.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_TYPE_ERROR: 'Please select an answer.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_BENEFICIARY_ERROR: 'Please enter a number.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_SUBSCRIBER_ERROR: 'Please select an answer.',

    ODC_LOGIN_ERR_INVALID_EMAIL: 'Please enter a valid email address.',
    ODC_LOGIN_ERR_MISSING_INFO: 'Please enter your email address and password.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_ESIGN: 'The application you are trying to retrieve is unavailable for the moment. Please try again later.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_NOT_FOUND: 'The information entered is wrong. Please verify and try again.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_RECEIVED: 'The request has already been received by Desjardins Online Brokerage. Please contact our client service department.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_COMPLETE_OBSOLETE: 'The completed application you are trying to retrieve has expired. Please complete a new application or contact our client service department.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_CURRENT_OBSOLETE: 'The in progress application you are trying to retrieve has expired. Please complete a new application or contact our client service department.',
    ODC_LOGIN_ERR_LIST_OBSOLETE: 'The applications you are trying to retrieve has expired. Please complete a new application or contact our client service department.',
    ERROR_FIELD_courriel: 'E-Mail',
    ERROR_FIELD_courriel_required: 'Please enter your email address.',
    ERROR_FIELD_courriel_email: 'Please enter a valid email address.',
    ERROR_FIELD_motDePasse: 'Password',
    ERROR_FIELD_motDePasse_required: 'Please enter your password.',

    ERROR_FIELD_accountNumberCad: 'Desjardins Trust Services account number (CAD)',
    ERROR_FIELD_accountNumberCad_required: 'Please enter a Desjardins Trust Services account number.',
    ERROR_FIELD_accountNumberCad_invalidRange: 'Please enter a valid Desjardins Trust Services account number.',
    ERROR_FIELD_productNumberCad: 'Product number (CAD)',
    ERROR_FIELD_productNumberCad_required: 'Please enter a product number.',
    ERROR_FIELD_productNumberCad_invalidRange: 'Please enter a valid product number.',
    ERROR_FIELD_accountNumberUsd: 'Desjardins Trust Services account number (USD)',
    ERROR_FIELD_accountNumberUsd_required: 'Please enter a Desjardins Trust Services account number.',
    ERROR_FIELD_accountNumberUsd_invalidRange: 'Please enter a valid Desjardins Trust Services account number.',
    ERROR_FIELD_productNumberUsd: 'Product number (USD)',
    ERROR_FIELD_productNumberUsd_required: 'Please enter a product number.',
    ERROR_FIELD_productNumberUsd_invalidRange: 'Please enter a valid product number.',

    ERROR_FIELD_email: 'E-Mail',
    ERROR_FIELD_email_required: 'Please enter your email address.',
    ERROR_FIELD_email_email: 'Please enter a valid email address.',
    ERROR_FIELD_reply: 'Answer',
    ERROR_FIELD_reply_required: 'Please enter your answer.',
    ODC_RESET_PASSWORD_ERR_INVALID_EMAIL: 'Please enter a valid email address.',
    ODC_RESET_PASSWORD_ERR_MISSING_EMAIL: 'Please enter your email address.',
    ODC_RESET_PASSWORD_ERR_CLIENTPROFILE_NOT_FOUND: 'This email is not valid.',
    ODC_RESET_PASSWORD_ERR_INVALID_PROFILE: 'You cannot reset your password online. Please contact our client service department.',
    ODC_RESET_PASSWORD_ERR_MISSING_REPLY: 'Please enter your answer.',
    ODC_RESET_PASSWORD_ERR_REPLY: 'This answer is not valid.',
    ODC_RESET_PASSWORD_ERR_PASSWORD: 'This temporary  password is not valid.',
    ODC_RESET_PASSWORD_ERR_CONTACT_CLIENT_SERVICE: 'Please contact our client service department.',
    ODC_RESET_PASSWORD_ERR_MISSING_OLD_PASSWORD: 'Please enter your temporary password.',
    ODC_RESET_PASSWORD_ERR_MISSING_NEW_PASSWORD: 'Please enter your new password.',
    ODC_RESET_PASSWORD_ERR_MISSING_CONFIRM_NEW_PASSWORD: 'Please enter your confirmation of password.',
    ODC_RESET_PASSWORD_ERR_INVALID_CONFIRM_NEW_PASSWORD: 'Confirmation of password does not match new password.',
    ODC_RESET_PASSWORD_ERR_EMPTY_ACCOUNT_OPENING_REQUEST_LIST: 'No application for this profile',
    ODC_RESET_PASSWORD_ERR_INVALID_ACCOUNT: 'You cannot reset your password online. Please contact our client service department.',

    PASSWORD_CONFIRMPASSWORD_NOT_MATCH: 'Confirmation of password does not match new password.',
    TEMPORARY_PASSWORD_INVALID: 'The temporary password is not valid.',
    TEMPORARY_PASSWORD_EXPIRED: 'Your temporary password has expired. Please click again on "Forgot your password".',
    SECURITY_ANSWER_INVALID: 'The security answer is not valid.',
    PASSWORD_INVALID: 'The password is not valid.',
    RECAPTCHA_INVALID: 'Please check the "I\'m not a robot" box to continue.',
    PASSWORD_INVALID_HISTORY: 'The password you entered was previously used.\n' +
        'Please choose a new password.',

    ERROR_SUMMARY_INTRO: 'Errors have been detected in the following fields:',

    ERROR_ESIGN: 'The online signature service is not available right now.',
    ERROR_ESIGN_INVALID_EMAIL: 'The Email address is invalid.',
    ERROR_ESIGN_DESCRIPTION: 'You may take note of your session ID to resume at a later time or print the documents, sign them, and return them by mail.',

    ERROR_FIELD_platformType_required: 'Choose an option',

    ERROR_FIELD_borrowingReceivedAndReadBorrowingRisksIndicator_required: 'Please make a selection.',
    ERROR_FIELD_borrowingReceivedAndReadBorrowingRisksIndicator: 'Notices - Leverage Strategy',

    ERROR_FIELD_newExistAct: 'Request type',
    ERROR_FIELD_newExistAct_required: 'Please make a selection.',
    ERROR_FIELD_cltNo: 'Username',
    ERROR_FIELD_cltNo_required: 'Please enter a valid username.',
    ERROR_FIELD_cltNo_username: 'Please enter a valid username.',
    ERROR_FIELD_actType: 'Account type',
    ERROR_FIELD_actType_required: 'Please select an account type.',
    ERROR_FIELD_currency: 'Account currency',
    ERROR_FIELD_currency_required: 'Please select the account currency.',
    ERROR_FIELD_rrspCurrency: 'Account currency - RRSP',
    ERROR_FIELD_rrspCurrency_required: 'Please select the account currency.',
    ERROR_FIELD_rrspCurrency_onlyOneAccountTypeInUSD: 'There can only be one RRSP in US dollars.',
    ERROR_FIELD_rrspAnnuitant: 'Personal/Spousal - RRSP',
    ERROR_FIELD_rrspAnnuitant_required: 'Please make a selection.',
    ERROR_FIELD_rrspCurrencySec: 'Account currency - RRSP',
    ERROR_FIELD_rrspCurrencySec_required: 'Please select the account currency.',
    ERROR_FIELD_rrspCurrencySec_onlyOneAccountTypeInUSD: 'There can only be one RRSP in US dollars.',
    ERROR_FIELD_rrspAnnuitantSec: 'Personal/Spousal - RRSP',
    ERROR_FIELD_rrspAnnuitantSec_required: 'Please make a selection.',
    ERROR_FIELD_rrspCurrencyTer: 'Account currency - RRSP',
    ERROR_FIELD_rrspCurrencyTer_required: 'Please select the account currency.',
    ERROR_FIELD_rrspCurrencyTer_onlyOneAccountTypeInUSD: 'There can only be one RRSP in US dollars.',
    ERROR_FIELD_rrspAnnuitantTer: 'Personal/Spousal - RRSP',
    ERROR_FIELD_rrspAnnuitantTer_required: 'Please make a selection.',
    ERROR_FIELD_tfsaCurrency: 'Account currency - TFSA',
    ERROR_FIELD_tfsaCurrency_required: 'Please select the account currency.',
    ERROR_FIELD_respType: 'RESP Type',
    ERROR_FIELD_respType_required: 'Please select an answer.',
    ERROR_FIELD_respNumberOfBeneficiaries: 'RESP Beneficiaries',
    ERROR_FIELD_respNumberOfBeneficiaries_required: 'Please enter a number.',
    ERROR_FIELD_respNumberOfBeneficiaries_digit: 'Please enter a number.',
    ERROR_FIELD_respNumberOfBeneficiaries_min: 'Please enter a number between 1 and 6.',
    ERROR_FIELD_respNumberOfBeneficiaries_max: 'Please enter a number between 1 and 6.',
    ERROR_FIELD_respIsSubscriberResponsible: 'RESP Subscriber',
    ERROR_FIELD_respIsSubscriberResponsible_required: 'Please select an answer.',
    ERROR_FIELD_liraCurrency: 'Account currency - LIRA, RLSP or LRSP',
    ERROR_FIELD_liraCurrency_required: 'Please select the account currency.',
    ERROR_FIELD_liraCurrency_onlyOneAccountTypeInUSD: 'There can only be one LIRA, RLSP or LRSP in US dollars.',
    ERROR_FIELD_liraJurisdiction: 'Jurisdiction of LIRA, RLSP or LRSP',
    ERROR_FIELD_liraJurisdiction_required: 'Please select a jurisdiction.',
    ERROR_FIELD_liraJurisdiction_notSameJuridiction: 'LIRA, RLSP or LRSP accounts juridiction cannot be the same.',
    ERROR_FIELD_liraCurrencySec: ' Account currency - LIRA, RLSP or LRSP',
    ERROR_FIELD_liraCurrencySec_required: 'Please select the account currency.',
    ERROR_FIELD_liraCurrencySec_onlyOneAccountTypeInUSD: 'There can only be one LIRA, RLSP or LRSP in US dollars.',
    ERROR_FIELD_liraJurisdictionSec: 'Jurisdiction of LIRA, RLSP or LRSP',
    ERROR_FIELD_liraJurisdictionSec_required: 'Please select a jurisdiction.',
    ERROR_FIELD_liraJurisdictionSec_notSameJuridiction: 'LIRA, RLSP or LRSP accounts juridiction cannot be the same.',
    ERROR_FIELD_rrifCurrency: 'Account currency - RRIF',
    ERROR_FIELD_rrifCurrency_required: 'Please select the account currency.',
    ERROR_FIELD_rrifCurrency_onlyOneAccountTypeInUSD: 'There can only be one RRIF in US dollars.',
    ERROR_FIELD_rrifAnnuitant: 'Personal/Spousal - RRIF',
    ERROR_FIELD_rrifAnnuitant_required: 'Please make a selection.',
    ERROR_FIELD_rrifCurrencySec: 'Account currency - RRIF',
    ERROR_FIELD_rrifCurrencySec_required: 'Please select the account currency.',
    ERROR_FIELD_rrifCurrencySec_onlyOneAccountTypeInUSD: 'There can only be one RRIF in US dollars.',
    ERROR_FIELD_rrifAnnuitantSec: 'Personal/Spousal - RRIF',
    ERROR_FIELD_rrifAnnuitantSec_required: 'Please make a selection.',
    ERROR_FIELD_lifCurrency: 'LIF or RLIF Account currency',
    ERROR_FIELD_lifCurrency_required: 'Please select the account currency.',
    ERROR_FIELD_lifJurisdiction: 'Jurisdiction of LIF or RLIF',
    ERROR_FIELD_lifJurisdiction_required: 'Please select a jurisdiction.',
    ERROR_FIELD_ftarrspAnnuitant: 'Personal/Spousal - FTA RRSP',
    ERROR_FIELD_ftarrspAnnuitant_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicator: 'Designation of beneficiary at death',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicator_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicator_maxTwoSameDesignationOfBeneficiary: 'Only two Fixed-Term Annuity RRSP can have the same designation of beneficiary.',
    ERROR_FIELD_ftarrspAnnuitantSec: 'Personal/Spousal - FTA RRSP',
    ERROR_FIELD_ftarrspAnnuitantSec_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorSec: 'Designation of beneficiary at death',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorSec_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorSec_maxTwoSameDesignationOfBeneficiary: 'Only two Fixed-Term Annuity RRSP can have the same designation of beneficiary.',
    ERROR_FIELD_ftarrspAnnuitantTer: 'Personal/Spousal - FTA RRSP',
    ERROR_FIELD_ftarrspAnnuitantTer_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorTer: 'Designation of beneficiary at death',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorTer_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorTer_maxTwoSameDesignationOfBeneficiary: 'Only two Fixed-Term Annuity RRSP can have the same designation of beneficiary.',
    ERROR_FIELD_addDiscretionary: 'Discretionary',
    ERROR_FIELD_addDiscretionary_requiredFalse: 'Discretionary account is not available for forms printing or automated opening.',
    ERROR_FIELD_cashAccountMgntType: 'Type of management - Cash Account',
    ERROR_FIELD_cashAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_tfsaAccountMgntType: 'Type of management - TFSA',
    ERROR_FIELD_tfsaAccountMgntType_required: 'Please select the type of management',
    ERROR_FIELD_tfsaAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_rrspAccountMgntType: 'Type of management - RRSP',
    ERROR_FIELD_rrspAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_rrspAccountMgntTypeSec: 'Type of management - RRSP',
    ERROR_FIELD_rrspAccountMgntTypeSec_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_rrspAccountMgntTypeTer: 'Type of management - RRSP',
    ERROR_FIELD_rrspAccountMgntTypeTer_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_liraAccountMgntType: 'Type of management - LIRA',
    ERROR_FIELD_liraAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_liraAccountMgntTypeSec: 'Type of management - LIRA',
    ERROR_FIELD_liraAccountMgntTypeSec_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_rrifAccountMgntType: 'Type of management -  RRIF',
    ERROR_FIELD_rrifAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_rrifAccountMgntTypeSec: 'Type of management - RRIF',
    ERROR_FIELD_rrifAccountMgntTypeSec_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_lifAccountMgntType: 'Type of management - LIF',
    ERROR_FIELD_lifAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_respAccountMgntType: 'Type of management - RESP',
    ERROR_FIELD_respAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_ftarrspAccountMgntType: 'Type of management - FTARRSP',
    ERROR_FIELD_ftarrspAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_ftarrspAccountMgntTypeSec: 'Type of management - FTARRSP',
    ERROR_FIELD_ftarrspAccountMgntTypeSec_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',
    ERROR_FIELD_ftarrspAccountMgntTypeTer: 'Type of management - FTARRSP',
    ERROR_FIELD_ftarrspAccountMgntTypeTer_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',

    // Personal Information
    ERROR_FIELD_formLang: 'Client language',
    ERROR_FIELD_formLang_required: 'Please make a selection.',
    ERROR_FIELD_gender: 'Title',
    ERROR_FIELD_gender_required: 'Please make a selection.',
    ERROR_FIELD_userFirstName: 'First name',
    ERROR_FIELD_userFirstName_required: 'Please enter your first name.',
    ERROR_FIELD_userLastName: 'Last name',
    ERROR_FIELD_userLastName_required: 'Please enter your last name.',
    ERROR_FIELD_userDOB: 'Date of birth',
    ERROR_FIELD_userDOB_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_userDOB_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_userDOB_dateofbirthmajor: 'Please enter the real date of birth.',
    ERROR_FIELD_userDOB_dateofbirthyoung: 'You cannot be below the age of majority to open a brokerage account.',
    ERROR_FIELD_userDOB_dateofbirthchild: 'This account can only be opened for a minor.',
    ERROR_FIELD_userDOB_dateofbirtchildmorethan150: 'Please enter a valid birth date in the following.',
    ERROR_FIELD_userDOB_dateofbirthrrsp: 'An RRSP account cannot be opened after December 31 of the year in which you turn 71.',
    ERROR_FIELD_userSIN: 'Social insurance number',
    ERROR_FIELD_userSIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_userSIN_required: 'Please enter a valid social insurance number.',
    ERROR_FIELD_userHomePhone: 'Telephone (home)',
    ERROR_FIELD_userHomePhone_phone: 'Please enter a valid telephone number.',
    ERROR_FIELD_userHomePhone_required: 'Home phone is required.',
    ERROR_FIELD_userHomePhone_phoneMatch: 'Home phone, business phone and mobile phone must be different.',
    ERROR_FIELD_userHomePhone_any_required: 'Please enter a phone number (home, office or mobile).',
    ERROR_FIELD_otherFirstName: 'Child first name',
    ERROR_FIELD_otherFirstName_required: 'Please enter child first name.',
    ERROR_FIELD_otherLastName: 'Child last name',
    ERROR_FIELD_otherLastName_required: 'Please enter child last name.',
    ERROR_FIELD_userOtherPhone: 'Telephone (business)',
    ERROR_FIELD_userOtherPhone_phone: 'Please enter a valid telephone number.',
    ERROR_FIELD_userOtherPhone_required: 'Business phone is required.',
    ERROR_FIELD_userOtherPhone_phoneMatch: 'Home phone, business phone and mobile phone must be different.',
    ERROR_FIELD_userOtherPhone_any_required: 'Please enter a phone number (home, office or mobile).',
    ERROR_FIELD_userMobilePhone: 'Telephone (mobile)',
    ERROR_FIELD_userMobilePhone_phone: 'Please enter a valid telephone number.',
    ERROR_FIELD_userMobilePhone_required: 'Mobile phone is required.',
    ERROR_FIELD_userMobilePhone_phoneMatch: 'Home phone, business phone and mobile phone must be different.',
    ERROR_FIELD_userMobilePhone_any_required: 'Please enter a phone number (home, office or mobile).',
    ERROR_FIELD_userEmail: 'E-Mail',
    ERROR_FIELD_userEmail_email: 'Please enter a valid email address.',
    ERROR_FIELD_userEmail_required: 'Please enter a valid email address.',
    ERROR_FIELD_companyName: 'Company Name',
    ERROR_FIELD_companyName_required: 'Please enter a valid company name',
    ERROR_FIELD_userAddressStreet: 'No. & street.',
    ERROR_FIELD_userAddressStreet_required: 'Please enter your address number and street name.',
    ERROR_FIELD_userAddressStreet_addressMatch: 'Personal address and mailing address must be different.',
    ERROR_FIELD_userAddressStreetSec: 'Adresse de correspondance no. & street',
    ERROR_FIELD_userAddressStreetSec_required: 'Please enter your address number and street name.',
    ERROR_FIELD_userAddressStreetSec_addressMatch: 'Personal address and mailing address must be different.',
    ERROR_FIELD_userAddressCity: 'City',
    ERROR_FIELD_userAddressCity_required: 'Please enter the name of your city.',
    ERROR_FIELD_userAddressCitySec: 'Mailing address City',
    ERROR_FIELD_userAddressCitySec_required: 'Please enter the name of your city.',
    ERROR_FIELD_userAddressProv: 'Province',
    ERROR_FIELD_userAddressProv_required: 'Please select a province.',
    ERROR_FIELD_userAddressProv_provincesnotequals: 'The province must be the same for all holders.',
    ERROR_FIELD_userAddressProvSec: 'Mailing address Province',
    ERROR_FIELD_userAddressProvSec_required: 'Please select a province.',
    ERROR_FIELD_userAddressPostalCode: 'Postal code',
    ERROR_FIELD_userAddressPostalCode_required: 'Please enter a valid postal code.',
    ERROR_FIELD_userAddressPostalCode_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_userAddressPostalCodeSec: 'Mailing address Postal Code',
    ERROR_FIELD_userAddressPostalCodeSec_required: 'Please enter a valid postal code.',
    ERROR_FIELD_userAddressPostalCodeSec_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_jointSecondAccountholderSpouse_required: 'Please make a selection.',
    ERROR_FIELD_jointSecondAccountholderSpouse: 'Second accountholder - Spouse',
    ERROR_FIELD_isSameAddressAsFirstIndicator: 'Different Address',
    ERROR_FIELD_isSameAddressAsFirstIndicator_required: 'Please make a selection.',
    ERROR_FIELD_federalBusinessNumber: 'Federal Business Number',
    ERROR_FIELD_federalBusinessNumberExtension: 'Federal Business Number - Extension',
    ERROR_FIELD_provincialBusinessNumber: 'Provincial Business Number',
    ERROR_FIELD_provinceOfBusinessNumber: 'Province or territory',
    ERROR_FIELD_federalBusinessNumber_required: 'Please enter a federal Business Number.',
    ERROR_FIELD_federalBusinessNumber_required_choice: 'Please enter a Federal or Provincial Business Number.',
    ERROR_FIELD_federalBusinessNumber_minlength: 'Federal Business Number (BN) must be 9 characters long.',
    ERROR_FIELD_provincialBusinessNumber_required: 'Please enter a provincial Business Number.',
    ERROR_FIELD_provincialBusinessNumber_required_choice: 'Please enter a Provincial or Federal Business Number.',
    ERROR_FIELD_provincialBusinessNumber_minlength: 'Provincial Business Number must be 10 characters long.',
    ERROR_FIELD_provincialBusinessNumber_digit: 'Provincial Business Number must be numeric.',
    ERROR_FIELD_provinceOfBusinessNumber_required: 'Please make a selection.',
    ERROR_FIELD_federalBusinessNumber_digit: 'Invalid Federal Business Number',
    ERROR_FIELD_fiscalPeriodEndDate: 'Fiscal period end date',
    ERROR_FIELD_fiscalPeriodEndDate_required: 'Please enter a Fiscal Period End Date.',
    ERROR_FIELD_fiscalPeriodEndDate_date: 'Please enter a valid fiscal period end date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_fiscalPeriodEndDate_dateinfuture: 'Please select a date after today.',
    ERROR_FIELD_borrowingLoanStartingDate_dateInf: 'The loan start date must come before the loan maturity date',
    ERROR_FIELD_userIsCurrentAddress: 'Current Address',
    ERROR_FIELD_userIsCurrentAddress_required: 'Please make a selection.',
    ERROR_FIELD_userAddressUnit: 'Unit / apt.',
    ERROR_FIELD_userAddressUnit_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_userAddressUnitSec: 'Unit / apt.',
    ERROR_FIELD_userAddressUnitSec_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_beneficiaryReerAdressUnit: 'Unit / apt.',
    ERROR_FIELD_beneficiaryReerAdressUnit_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_beneficiaryReerAdressUnitSec: 'Unit / apt.',
    ERROR_FIELD_beneficiaryReerAdressUnitSec_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_beneficiaryReerAdressUnitTer: 'Unit / apt.',
    ERROR_FIELD_beneficiaryReerAdressUnitTer_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_beneficiaryCeliAdressUnit: 'Unit / apt.',
    ERROR_FIELD_beneficiaryCeliAdressUnit_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_beneficiaryCeliAdressUnitSec: 'Unit / apt.',
    ERROR_FIELD_beneficiaryCeliAdressUnitSec_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_beneficiaryCeliAdressUnitTer: 'Unit / apt.',
    ERROR_FIELD_beneficiaryCeliAdressUnitTer_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_attorneyAddressUnit: 'Unit / apt.',
    ERROR_FIELD_attorneyAddressUnit_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_beneficiaryRespAddressUnit: 'Unit / apt.',
    ERROR_FIELD_beneficiaryRespAddressUnit_alphanumeric: 'Please enter a valid unit / apt.',
    ERROR_FIELD_addressUnit: 'Unit / apt.',
    ERROR_FIELD_addressUnit_alphanumeric: 'Please enter a valid unit / apt.',

    // tax
    ERROR_FIELD_userCitizenship: 'Citizenship',
    ERROR_FIELD_userCitizenship_required: 'Please make a selection.',
    ERROR_FIELD_userCitizenship_usaRequired: 'You must declare that you hold USA citizenship.',
    ERROR_FIELD_userCitizenshipUS: 'Citizenship',
    ERROR_FIELD_userCitizenshipUS_required: 'Please make a selection.',
    ERROR_FIELD_userOtherCitizenship: 'Citizenship',
    ERROR_FIELD_userOtherCitizenship_required: 'Please select a country.',
    ERROR_FIELD_userOtherCitizenship_usaRequired: 'You must declare that you hold USA citizenship.',
    ERROR_FIELD_fiscalUsCitizenshipIndicator: 'U.S. resident',
    ERROR_FIELD_fiscalUsCitizenshipIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userFiscalRelationCanada: 'Citizen or Resident',
    ERROR_FIELD_userFiscalRelationCanada_required: 'Please make a selection.',
    ERROR_FIELD_usaNif: 'TIN - United States',
    ERROR_FIELD_usaNif_required: 'Please either give us a valid TIN or select the reason.',
    ERROR_FIELD_usaNif_invalidRange: 'Please enter a valid Tax identification number.',
    ERROR_FIELD_userCountryNif2: 'First country',
    ERROR_FIELD_userCountryNif2_required: 'Country is required',
    ERROR_FIELD_codePremierAutreNif: 'TIN - First country',
    ERROR_FIELD_codePremierAutreNif_required: 'Please either give us a valid TIN or select the reason.',
    ERROR_FIELD_codePremierAutreNif_invalidRange: 'Please enter a valid Tax identification number.',
    ERROR_FIELD_userCountryNif3: 'Second country',
    ERROR_FIELD_userCountryNif3_required: 'Country is required',
    ERROR_FIELD_codeSecondAutreNif: 'TIN - Second country',
    ERROR_FIELD_codeSecondAutreNif_required: 'Please either give us a valid TIN or select the reason.',
    ERROR_FIELD_codeSecondAutreNif_invalidRange: 'Please enter a valid Tax identification number.',

    // SPOUSE
    ERROR_FIELD_userMaritalStatus: 'Marital status',
    ERROR_FIELD_userMaritalStatus_required: 'Please select a marital status.',
    ERROR_FIELD_spouseGender: 'Title',
    ERROR_FIELD_spouseGender_required: 'Please make a selection.',
    ERROR_FIELD_spouseFirstName: 'First name',
    ERROR_FIELD_spouseFirstName_required: 'Please enter your first name.',
    ERROR_FIELD_spouseLastName: 'Last name',
    ERROR_FIELD_spouseLastName_required: 'Please enter your last name.',
    ERROR_FIELD_spouseDOB: 'Date of birth',
    ERROR_FIELD_spouseDOB_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_spouseDOB_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_spouseDOB_dateofbirthmajor: 'You cannot be below the age of majority.',
    ERROR_FIELD_spouseSIN: 'Social insurance number',
    ERROR_FIELD_spouseSIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_spouseSIN_required: 'Please enter a valid social insurance number.',
    ERROR_FIELD_spouseJobStatus: 'Employment status',
    ERROR_FIELD_spouseJobStatus_required: 'Please make a selection.',
    ERROR_FIELD_spouseEmployerName: 'Employer\'s name',
    ERROR_FIELD_spouseEmployerName_required: 'Please enter employer name.',
    ERROR_FIELD_spouseJobTitle: 'Job title',
    ERROR_FIELD_spouseJobTitle_required: 'Please specify employment title.',
    ERROR_FIELD_spouseEmployerActivity: 'Sector of activity',
    ERROR_FIELD_spouseEmployerActivity_required: 'Please specify sector of activity.',

    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouse: 'Contributing spouse - RRSP',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouse_required: 'Please make a selection.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouse_contributingSpouseCannotBeCurrentSpouse: 'The contributing spouse cannot be the current spouse because of the marital status selected.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouse_multiple: 'Multiple contributing spouses is not supported.  Please create distinct requests for each contributing spouse.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseSec: 'Contributing spouse - RRSP',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseSec_required: 'Please make a selection.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseSec_contributingSpouseCannotBeCurrentSpouse: 'The contributing spouse cannot be the current spouse because of the marital status selected.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseSec_multiple: 'Multiple contributing spouses is not supported.  Please create distinct requests for each contributing spouse.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseTer: 'Contributing spouse - RRSP',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseTer_required: 'Please make a selection.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseTer_contributingSpouseCannotBeCurrentSpouse: 'The contributing spouse cannot be the current spouse because of the marital status selected.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseTer_multiple: 'Multiple contributing spouses is not supported.  Please create distinct requests for each contributing spouse.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouse: 'Contributing spouse - RRIF',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouse_required: 'Please make a selection.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouse_contributingSpouseCannotBeCurrentSpouse: 'The contributing spouse cannot be the current spouse because of the marital status selected.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouse_multiple: 'Multiple contributing spouses is not supported.  Please create distinct requests for each contributing spouse.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouseSec: 'Contributing spouse - RRIF',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouseSec_required: 'Please make a selection.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouseSec_contributingSpouseCannotBeCurrentSpouse: 'The contributing spouse cannot be the current spouse because of the marital status selected.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouseSec_multiple: 'Multiple contributing spouses is not supported.  Please create distinct requests for each contributing spouse.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouse: 'Contributing spouse - FTAR',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouse_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouse_contributingSpouseCannotBeCurrentSpouse: 'The contributing spouse cannot be the current spouse because of the marital status selected.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouse_multiple: 'Multiple contributing spouses is not supported.  Please create distinct requests for each contributing spouse.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseSec: 'Contributing spouse - FTAR',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseSec_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseSec_contributingSpouseCannotBeCurrentSpouse: 'The contributing spouse cannot be the current spouse because of the marital status selected.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseSec_multiple: 'Multiple contributing spouses is not supported.  Please create distinct requests for each contributing spouse.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseTer: 'Contributing spouse - FTAR',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseTer_required: 'Please make a selection.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseTer_contributingSpouseCannotBeCurrentSpouse: 'The contributing spouse cannot be the current spouse because of the marital status selected.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseTer_multiple: 'Multiple contributing spouses is not supported.  Please create distinct requests for each contributing spouse.',

    ERROR_FIELD_contributingSpouseGender: 'Title',
    ERROR_FIELD_contributingSpouseGender_required: 'Please make a selection.',
    ERROR_FIELD_contributingSpouseFirstName: 'First name',
    ERROR_FIELD_contributingSpouseFirstName_required: 'Please enter your first name.',
    ERROR_FIELD_contributingSpouseLastName: 'Last name',
    ERROR_FIELD_contributingSpouseLastName_required: 'Please enter your last name.',
    ERROR_FIELD_contributingSpouseDOB: 'Date of birth',
    ERROR_FIELD_contributingSpouseDOB_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_contributingSpouseDOB_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_contributingSpouseDOB_dateofbirthmajor: 'You cannot be below the age of majority.',
    ERROR_FIELD_contributingSpouseSIN: 'Social insurance number',
    ERROR_FIELD_contributingSpouseSIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_contributingSpouseSIN_required: 'Please enter a valid social insurance number.',

    // Occupation
    ERROR_FIELD_userJobStatus: 'Employment status',
    ERROR_FIELD_userJobStatus_required: 'Please make a selection.',
    ERROR_FIELD_userEmployerIsBroker: 'Employed by a securityGroup dealer',
    ERROR_FIELD_userEmployerIsBroker_required: 'Please make a selection.',
    ERROR_FIELD_userIsInvestmentAdvisorIndicator: 'Investment Advice',
    ERROR_FIELD_userIsInvestmentAdvisorIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userEmployerName: 'Employer\'s name',
    ERROR_FIELD_userEmployerName_required: 'Please enter employer name.',
    ERROR_FIELD_userJobTitle: 'Job title',
    ERROR_FIELD_userJobTitle_required: 'Please specify employment title.',
    ERROR_FIELD_userEmployerActivity: 'Sector of activity',
    ERROR_FIELD_userEmployerActivity_required: 'Please specify sector of activity.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeIndicator: 'Live under the same roof as someone who works for a securityGroup dealer',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeVmdIndicator: 'Live under the same roof as someone who works for a VMD securityGroup dealer',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeVmdIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeFirmName: 'Firm name',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeFirmName_required: 'Please enter name of firm.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeFirstName: 'Employee\'s first name (Firm)',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeFirstName_required: 'Please enter employee\'s first name.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLastName: 'Employee\'s last name (Firm)',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLastName_required: 'Please enter employee\'s family name.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLink: 'Link',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLink_required: 'Please enter employee\'s link.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLinkList: 'Link',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLinkList_required: 'Please enter employee\'s link.',
    ERROR_FIELD_percentageOwner: 'Percentage ownership of client',
    ERROR_FIELD_percentageOwner_required: 'Please indicate percentage ownership of client (between 1 and 100%).',
    ERROR_FIELD_percentageOwner_min: 'Please indicate the percentage ownership of client between 1 and 100.',
    ERROR_FIELD_percentageOwner_max: 'Please indicate the percentage ownership of client between 1 and 100.',

    // Attorney
    ERROR_FIELD_attorneyGender: 'Title',
    ERROR_FIELD_attorneyGender_required: 'Please make a selection.',
    ERROR_FIELD_attorneyFirstName: 'First name',
    ERROR_FIELD_attorneyFirstName_required: 'Please enter attorney\'s first name.',
    ERROR_FIELD_attorneyLastName: 'Last name',
    ERROR_FIELD_attorneyLastName_required: 'Please enter attorney\'s last name.',
    ERROR_FIELD_attorneyDOB: 'Date of birth',
    ERROR_FIELD_attorneyDOB_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_attorneyDOB_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_attorneyDOB_dateofbirthmajor: 'You cannot be below the age of majority.',
    ERROR_FIELD_attorneySIN: 'Social insurance number',
    ERROR_FIELD_attorneySIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_attorneySIN_required: 'Please enter a valid social insurance number.',
    ERROR_FIELD_attorneyHomePhone: 'Home telephone',
    ERROR_FIELD_attorneyHomePhone_phone: 'Please enter a valid telephone number.',
    ERROR_FIELD_attorneyHomePhone_required: 'Home phone is required.',
    ERROR_FIELD_attorneyHomePhone_phoneMatch: 'Home phone and other phone must be different.',
    ERROR_FIELD_attorneyOtherPhone: 'Telephone (other)',
    ERROR_FIELD_attorneyOtherPhone_phone: 'Please enter a valid telephone number.',
    ERROR_FIELD_attorneyOtherPhone_required: 'Other phone is required.',
    ERROR_FIELD_attorneyOtherPhone_phoneMatch: 'Home phone and other phone must be different.',
    ERROR_FIELD_attorneyClientNumber: 'Client Number',
    ERROR_FIELD_attorneyClientNumber_required: 'Please enter a valid client number for the attorney.',
    ERROR_FIELD_attorneyClientNumber_username: 'Please enter a valid client number for the attorney.',
    ERROR_FIELD_attorneyClientRelationship: 'Relationship',
    ERROR_FIELD_attorneyClientRelationship_required: 'Please select a relationship.',
    ERROR_FIELD_attorneyAddressStreet: 'No. & street.',
    ERROR_FIELD_attorneyAddressStreet_required: 'Please enter your address number and street name.',
    ERROR_FIELD_attorneyAddressCity: 'City',
    ERROR_FIELD_attorneyAddressCity_required: 'Please enter the name of your city.',
    ERROR_FIELD_attorneyAddressProv: 'Province',
    ERROR_FIELD_attorneyAddressProv_required: 'Please select a province.',
    ERROR_FIELD_attorneyAddressPostalCode: 'Postal code',
    ERROR_FIELD_attorneyAddressPostalCode_required: 'Please enter a valid postal code.',
    ERROR_FIELD_attorneyAddressPostalCode_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_attorneyJobStatus: 'Employment status',
    ERROR_FIELD_attorneyJobStatus_required: 'Please make a selection.',
    ERROR_FIELD_attorneyEmployerIsBroker: 'Employed by a securityGroup dealer',
    ERROR_FIELD_attorneyEmployerIsBroker_required: 'Please make a selection.',
    ERROR_FIELD_attorneyEmployerName: 'Employer\'s name',
    ERROR_FIELD_attorneyEmployerName_required: 'Please enter employer name.',
    ERROR_FIELD_attorneyJobTitle: 'Job title',
    ERROR_FIELD_attorneyJobTitle_required: 'Please specify employment title.',
    ERROR_FIELD_attorneyEmployerActivity: 'Sector of activity',
    ERROR_FIELD_attorneyEmployerActivity_required: 'Please specify sector of activity.',
    ERROR_FIELD_attorneyBankId: 'What is the name of the attorney\'s financial institution?',
    ERROR_FIELD_attorneyBankId_required: 'Please select a financial institution.',
    ERROR_FIELD_attorneyBankBranchNumber: 'Attorney\'s transit number',
    ERROR_FIELD_attorneyBankBranchNumber_required: 'Please enter the transit number of your financial institution.',
    ERROR_FIELD_attorneyBankBranchNumber_minlength: 'Please enter a valid transit number.',
    ERROR_FIELD_attorneyBankAccountNumber: 'Attorney\'s account number',
    ERROR_FIELD_attorneyBankAccountNumber_required: 'Please enter your account number.',
    ERROR_FIELD_attorneyBankPhone: 'Attorney\'s financial institution\'s Phone Number',
    ERROR_FIELD_attorneyBankPhone_required: 'Please enter a valid telephone number.',
    ERROR_FIELD_attorneyBankPhone_phone: 'Please enter a valid telephone number.',
    ERROR_FIELD_attorneyInitieIndicator: 'Is the Attorney a reporting insider?',
    ERROR_FIELD_attorneyInitieIndicator_required: 'Please make a selection.',
    ERROR_FIELD_attorneyInitieCompanyName: 'Reporting insider - Name of the company',
    ERROR_FIELD_attorneyInitieCompanyName_required: 'Please enter company name.',
    ERROR_FIELD_attorneyInitieCompanySymbol: 'Reporting insider - Stock symbol',
    ERROR_FIELD_attorneyInitieCompanySymbol_required: 'Please enter stock symbol.',
    ERROR_FIELD_attorneyInitieMarket: 'Reporting insider - Market',
    ERROR_FIELD_attorneyInitieMarket_required: 'Please select a market.',
    ERROR_FIELD_attorneyMainShareholderIndicator: 'Is the Attorney a significant shareholder?',
    ERROR_FIELD_attorneyMainShareholderIndicator_required: 'Please make a selection.',
    ERROR_FIELD_attorneyMainShareholderCompanyName: 'Significant shareholder - Name of the company',
    ERROR_FIELD_attorneyMainShareholderCompanyName_required: 'Please enter company name.',
    ERROR_FIELD_attorneyMainShareholderCompanySymbol: 'Significant shareholder - Stock symbol',
    ERROR_FIELD_attorneyMainShareholderCompanySymbol_required: 'Please enter stock symbol.',
    ERROR_FIELD_attorneyMainShareholderMarket: 'Significant shareholder - Market',
    ERROR_FIELD_attorneyMainShareholderMarket_required: 'Please select a market.',

    ERROR_FIELD_userAnnualIncome: 'Annual revenue',
    ERROR_FIELD_userAnnualIncome_required: 'Please enter your annual income.',
    ERROR_FIELD_userAnnualIncome_digit: 'Annual income must be numeric.',
    ERROR_FIELD_userNetCapitalAsset: 'Capital assets',
    ERROR_FIELD_userNetCapitalAsset_required: 'Please enter the value of your capital assets.',
    ERROR_FIELD_userNetLiquidityAsset: 'Liquid assets',
    ERROR_FIELD_userNetLiquidityAsset_required: 'Please enter the value of your liquid assets.',
    ERROR_FIELD_userBankId: 'Financial institution',
    ERROR_FIELD_userBankId_required: 'Please select a financial institution.',
    ERROR_FIELD_userBankBranchNumber: 'Transit number',
    ERROR_FIELD_userBankBranchNumber_required: 'Please enter the transit number of your financial institution.',
    ERROR_FIELD_userBankBranchNumber_transit: 'Please enter a valid transit number.',
    ERROR_FIELD_userBankAccountNumber: 'Account number',
    ERROR_FIELD_userBankAccountNumber_required: 'Please enter your account number.',
    ERROR_FIELD_userBankPhone: 'Financial institution\'s phone number',
    ERROR_FIELD_userBankPhone_phone: 'Please enter a valid phone number.',
    ERROR_FIELD_borrowedForInvestmentPurposeIndicator: 'Have you borrowed money to finance the purchase of your investment products through loans advanced by third parties',
    ERROR_FIELD_borrowedForInvestmentPurposeIndicator_required: 'Please make a selection.',
    ERROR_FIELD_borrowingAmountOfLoan: 'Amount of the loan',
    ERROR_FIELD_borrowingAmountOfLoan_required: 'Please make a selection.',
    ERROR_FIELD_borrowingInterestRate: 'Interest Rate',
    ERROR_FIELD_borrowingInterestRate_required: 'Please make a selection.',
    ERROR_FIELD_borrowingInterestRate_max: 'Interest Rate maximum is 999.99',
    ERROR_FIELD_borrowingLoanStartingDate: 'Loan starting date',
    ERROR_FIELD_borrowingLoanStartingDate_required: 'Please make a selection.',
    ERROR_FIELD_borrowingLoanStartingDate_date: 'Please enter a valid Loan starting date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_borrowingLoanStartingDate_dateRange: 'Please enter a Loan starting date between 1900-01-01 and 2999-12-31.',
    ERROR_FIELD_borrowingLoanMaturityDate: 'Maturity of the loan',
    ERROR_FIELD_borrowingLoanMaturityDate_required: 'Please make a selection.',
    ERROR_FIELD_borrowingLoanMaturityDate_date: 'Please enter a valid Loan maturity date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_borrowingLoanMaturityDate_dateRange: 'Please enter a Loan maturity date between 1900-01-01 and 2999-12-31.',
    ERROR_FIELD_borrowingAmountInvested: 'Amount invested as loans for investment purposes',
    ERROR_FIELD_borrowingAmountInvested_required: 'Please make a selection.',
    ERROR_FIELD_optionCode: 'Authorized options code for non-registered account',
    ERROR_FIELD_optionCode_required: 'Please make a selection.',
    ERROR_FIELD_registeredOptionCode: 'Autorized options code for registered account',
    ERROR_FIELD_registeredOptionCode_required: 'Please make a selection.',
    ERROR_FIELD_userInvestmentObjectivesIntendedUse: 'Intended use',
    ERROR_FIELD_userInvestmentObjectivesIntendedUse_required: 'Please make a selection.',
    ERROR_FIELD_userInvestmentObjectivesNonRegInvHorizon: 'Investment Horizon',
    ERROR_FIELD_userInvestmentObjectivesNonRegInvHorizon_required: 'Please make a selection.',
    ERROR_FIELD_userInvestmentObjectivesRegInvHorizon: 'Registered accounts',
    ERROR_FIELD_userInvestmentObjectivesRegInvHorizon_required: 'Please make a selection.',
    ERROR_FIELD_userInvestmentObjectivesRskTol: 'Risk Profile',
    ERROR_FIELD_userInvestmentObjectivesRskTol_required: 'Please make a selection.',

    // Legal
    ERROR_FIELD_userDisclaimerIndicator: 'Beneficial ownership',
    ERROR_FIELD_userDisclaimerIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userReceiptDocumentSecuritiesHolderIndicator: 'Receipt of documents',
    ERROR_FIELD_userReceiptDocumentSecuritiesHolderIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userElectronicDocumentSecuritiesHolderIndicator: 'Consent to deliver documents electronically',
    ERROR_FIELD_userElectronicDocumentSecuritiesHolderIndicator_required: 'Please make a selection.',
    ERROR_FIELD_authorizeClientInfoPresence: 'Presence service.',
    ERROR_FIELD_authorizeClientInfoPresence_required: 'Please make a selection.',
    ERROR_FIELD_personalInformationSpecificConsentIndicator: 'Specific consent',
    ERROR_FIELD_personalInformationSpecificConsentIndicator_required: 'Please make a selection.',

    ERROR_FIELD_optionActOtherBrokerIndicator: 'Other option account',
    ERROR_FIELD_optionActOtherBrokerIndicator_required: 'Please make a selection.',
    ERROR_FIELD_optionActOtherBrokerFirmName: 'Firm name (Option account)',
    ERROR_FIELD_optionActOtherBrokerFirmName_required: 'Please enter name of firm.',
    ERROR_FIELD_optionActExperienceSpecification: 'Specification (Option account)',
    ERROR_FIELD_optionActExperienceSpecification_required: 'Please specify.',
    ERROR_FIELD_optionActTradingExperienceIndicator: 'Experience',
    ERROR_FIELD_optionActTradingExperienceIndicator_required: 'Please make a selection.',
    ERROR_FIELD_optionActTradingExperienceYears1: 'Purchasing puts and calls - Number of years',
    ERROR_FIELD_optionActTradingExperienceYears2: 'Sale of covered options - Number of years',
    ERROR_FIELD_optionActTradingExperienceYears3: 'Spread trading - Number of years',
    ERROR_FIELD_optionActTradingExperienceYears4: 'Sale of uncovered options - Number of years',
    ERROR_FIELD_optionActTradingExperienceYears1_required: 'Purchasing puts and calls - Please enter number of years of experience.',
    ERROR_FIELD_optionActTradingExperienceYears2_required: 'Sale of covered options - Please enter number of years of experience.',
    ERROR_FIELD_optionActTradingExperienceYears3_required: 'Spread trading - Please enter number of years of experience.',
    ERROR_FIELD_optionActTradingExperienceYears4_required: 'Sale of uncovered options - Please enter number of years of experience.',
    ERROR_FIELD_optionActTradingExperienceYears1_digit: 'Purchasing puts and calls - Please enter a number.',
    ERROR_FIELD_optionActTradingExperienceYears1_min: 'Purchasing puts and calls - Please enter a number between 1 and 99.',
    ERROR_FIELD_optionActTradingExperienceYears1_max: 'Purchasing puts and calls - Please enter a number between 1 and 99.',
    ERROR_FIELD_optionActTradingExperienceYears2_digit: 'Sale of covered options - Please enter a number.',
    ERROR_FIELD_optionActTradingExperienceYears2_min: 'Sale of covered options - Please enter a number between 1 and 99.',
    ERROR_FIELD_optionActTradingExperienceYears2_max: 'Sale of covered options - Please enter a number between 1 and 99.',
    ERROR_FIELD_optionActTradingExperienceYears3_digit: 'Spread trading - Please enter a number.',
    ERROR_FIELD_optionActTradingExperienceYears3_min: 'Spread trading - Please enter a number between 1 and 99.',
    ERROR_FIELD_optionActTradingExperienceYears3_max: 'Spread trading - Please enter a number between 1 and 99.',
    ERROR_FIELD_optionActTradingExperienceYears4_digit: 'Sale of uncovered options - Please enter a number.',
    ERROR_FIELD_optionActTradingExperienceYears4_min: 'Sale of uncovered options - Please enter a number between 1 and 99.',
    ERROR_FIELD_optionActTradingExperienceYears4_max: 'Sale of uncovered options - Please enter a number between 1 and 99.',
    ERROR_FIELD_optionActTradingTheoriqueKnowledgeIndicator: 'Theorical knowledge',
    ERROR_FIELD_optionActTradingTheoriqueKnowledgeIndicator_required: 'Please make a selection.',
    ERROR_FIELD_optionActTradingExperienceOperationLevel1: 'Type of option trades - Experience',
    ERROR_FIELD_optionActTradingPlannedOperationLevel1: 'Type of options trades - Anticipated trades',
    ERROR_FIELD_optionActTradingExperienceOperationLevel1_required: 'Please indicate at least one type of trade for which you have experience.',
    ERROR_FIELD_optionActTradingPlannedOperationLevel1_required: 'Please indicate at least one type of option trades anticipated.',

    ERROR_FIELD_beneficiaryReerActiveIndicator: 'Beneficiary (RRSP)',
    ERROR_FIELD_beneficiaryReerActiveIndicator_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryReerGender: 'Title of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerGender_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryReerFirstName: 'First name of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerFirstName_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryReerLastName: 'Last name of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerLastName_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryReerDOB: 'Date of birth of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerDOB_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerDOB_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerDOB_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerDOB_dateofbirthmajor: 'You cannot be below the age of majority.',
    ERROR_FIELD_beneficiaryReerSIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryReerSIN: 'Social insurance number # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressStreet: 'No. & street of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressStreet_required: 'Please enter address number and street name.',
    ERROR_FIELD_beneficiaryReerAdressCity: 'City of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressCity_required: 'Please enter name of city.',
    ERROR_FIELD_beneficiaryReerAdressProv: 'Province of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressProv_required: 'Please select a province.',
    ERROR_FIELD_beneficiaryReerAdressPostalCode: 'Postal code of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressPostalCode_required: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryReerAdressPostalCode_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryReerParentLink: 'Relationship of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerParentLink_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryReerPart: 'Share of beneficiary # 1 (RRSP)',
    ERROR_FIELD_beneficiaryReerPart_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryReerPart_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryReerPart_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryReerGenderSec: 'Title of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerGenderSec_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryReerFirstNameSec: 'First name of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerFirstNameSec_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryReerLastNameSec: 'Last name of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerLastNameSec_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryReerDOBSec: 'Date of birth of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerDOBSec_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerDOBSec_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerDOBSec_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerSINSec_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryReerSINSec: 'Social insurance number # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressStreetSec: 'No. & street of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressStreetSec_required: 'Please enter address number and street name.',
    ERROR_FIELD_beneficiaryReerAdressCitySec: 'City of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressCitySec_required: 'Please enter name of city.',
    ERROR_FIELD_beneficiaryReerAdressProvSec: 'Province of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressProvSec_required: 'Please select a province.',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeSec: 'Postal code of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeSec_required: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeSec_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryReerParentLinkSec: 'Relationship of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerParentLinkSec_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryReerPartSec: 'Share of beneficiary # 2 (RRSP)',
    ERROR_FIELD_beneficiaryReerPartSec_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryReerPartSec_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryReerPartSec_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryReerGenderTer: 'Title of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerGenderTer_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryReerFirstNameTer: 'First name of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerFirstNameTer_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryReerLastNameTer: 'Last name of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerLastNameTer_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryReerDOBTer: 'Date of birth of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerDOBTer_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerDOBTer_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerDOBTer_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryReerSINTer_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryReerSINTer: 'Social insurance number # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressStreetTer: 'No. & street of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressStreetTer_required: 'Please enter address number and street name.',
    ERROR_FIELD_beneficiaryReerAdressCityTer: 'City of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressCityTer_required: 'Please enter name of city.',
    ERROR_FIELD_beneficiaryReerAdressProvTer: 'Province of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressProvTer_required: 'Please select a province.',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeTer: 'Postal code of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeTer_required: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeTer_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryReerParentLinkTer: 'Relationship of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerParentLinkTer_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryReerPartTer: 'Share of beneficiary # 3 (RRSP)',
    ERROR_FIELD_beneficiaryReerPartTer_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryReerPartTer_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryReerPartTer_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',

    ERROR_FIELD_successorHolderCeliAppActiveIndicator: 'Successor holder (FHSA)',
    ERROR_FIELD_successorHolderCeliAppActiveIndicator_required: 'Please make a selection.',
    ERROR_FIELD_successorHolderCeliAppGender: 'Title of successor holder (FHSA)',
    ERROR_FIELD_successorHolderCeliAppGender_required: 'Please make a selection.',
    ERROR_FIELD_successorHolderCeliAppFirstName: 'First name of successor holder (FHSA)',
    ERROR_FIELD_successorHolderCeliAppFirstName_required: 'Please enter first name.',
    ERROR_FIELD_successorHolderCeliAppLastName: 'Last name of successor holder (FHSA)',
    ERROR_FIELD_successorHolderCeliAppLastName_required: 'Please enter family name.',
    ERROR_FIELD_successorHolderCeliAppSIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_successorHolderCeliAppSIN: 'Social insurance number (FHSA)',
    ERROR_FIELD_successorHolderCeliAppSIN_required: 'Please enter a social insurance number.',

    ERROR_FIELD_beneficiaryCeliAppActiveIndicator: 'Beneficiary (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppActiveIndicator_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliAppGender: 'Title of beneficiary # 1 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppGender_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliAppFirstName: 'First name of beneficiary # 1 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppFirstName_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryCeliAppLastName: 'Last name of beneficiary # 1 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppLastName_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryCeliAppDOB: 'Date of birth of beneficiary # 1 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppDOB_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOB_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOB_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOB_dateofbirthmajor: 'Please enter the real date of birth.',
    ERROR_FIELD_beneficiaryCeliAppSIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryCeliAppSIN: 'Social insurance number # 1 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppParentLink: 'Relationship of beneficiary # 1 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppParentLink_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryCeliAppPart: 'Share of beneficiary # 1 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppPart_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryCeliAppPart_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliAppPart_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliAppGenderSec: 'Title of beneficiary # 2 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppGenderSec_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliAppFirstNameSec: 'First name of beneficiary # 2 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppFirstNameSec_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryCeliAppLastNameSec: 'Last name of beneficiary # 2 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppLastNameSec_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryCeliAppDOBSec: 'Date of birth of beneficiary # 2 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppDOBSec_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOBSec_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOBSec_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOBSec_dateofbirthmajor: 'Please enter the real date of birth.',
    ERROR_FIELD_beneficiaryCeliAppSINSec_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryCeliAppSINSec: 'Social insurance number # 2 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppParentLinkSec: 'Relationship of beneficiary # 2 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppParentLinkSec_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryCeliAppPartSec: 'Share of beneficiary # 2 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppPartSec_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryCeliAppPartSec_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliAppPartSec_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliAppGenderTer: 'Title of beneficiary # 3 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppGenderTer_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliAppFirstNameTer: 'First name of beneficiary # 3 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppFirstNameTer_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryCeliAppLastNameTer: 'Last name of beneficiary # 3 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppLastNameTer_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryCeliAppDOBTer: 'Date of birth of beneficiary # 3 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppDOBTer_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOBTer_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOBTer_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliAppDOBTer_dateofbirthmajor: 'Please enter the real date of birth.',
    ERROR_FIELD_beneficiaryCeliAppSINTer_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryCeliAppSINTer: 'Social insurance number # 3 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppParentLinkTer: 'Relationship of beneficiary # 3 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppParentLinkTer_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryCeliAppPartTer: 'Share of beneficiary # 3 (FHSA)',
    ERROR_FIELD_beneficiaryCeliAppPartTer_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryCeliAppPartTer_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliAppPartTer_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',


    ERROR_FIELD_beneficiaryCeliActiveIndicator: 'Beneficiary (TFSA)',
    ERROR_FIELD_beneficiaryCeliActiveIndicator_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliConjointTitulaire: 'Successor holder (TFSA)',
    ERROR_FIELD_beneficiaryCeliConjointTitulaire_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliGender: 'Title of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliGender_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliFirstName: 'First name of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliFirstName_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryCeliLastName: 'Last name of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliLastName_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryCeliDOB: 'Date of birth of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliDOB_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliDOB_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliDOB_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliDOB_dateofbirthmajor: 'You cannot be below the age of majority.',
    ERROR_FIELD_beneficiaryCeliSIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryCeliSIN: 'Social insurance number # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressStreet: 'No. & street of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressStreet_required: 'Please enter address number and street name.',
    ERROR_FIELD_beneficiaryCeliAdressCity: 'City of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressCity_required: 'Please enter name of city.',
    ERROR_FIELD_beneficiaryCeliAdressProv: 'Province of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressProv_required: 'Please select a province.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCode: 'Postal code of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressPostalCode_required: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCode_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryCeliParentLink: 'Relationship of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliParentLink_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryCeliPart: 'Share of beneficiary # 1 (TFSA)',
    ERROR_FIELD_beneficiaryCeliPart_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryCeliPart_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliPart_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliGenderSec: 'Title of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliGenderSec_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliFirstNameSec: 'First name of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliFirstNameSec_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryCeliLastNameSec: 'Last name of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliLastNameSec_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryCeliDOBSec: 'Date of birth of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliDOBSec_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliDOBSec_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliDOBSec_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliSINSec_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryCeliSINSec: 'Social insurance number # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressStreetSec: 'No. & street of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressStreetSec_required: 'Please enter address number and street name.',
    ERROR_FIELD_beneficiaryCeliAdressCitySec: 'City of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressCitySec_required: 'Please enter name of city.',
    ERROR_FIELD_beneficiaryCeliAdressProvSec: 'Province of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressProvSec_required: 'Please select a province.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeSec: 'Postal code of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeSec_required: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeSec_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryCeliParentLinkSec: 'Relationship of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliParentLinkSec_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryCeliPartSec: 'Share of beneficiary # 2 (TFSA)',
    ERROR_FIELD_beneficiaryCeliPartSec_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryCeliPartSec_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliPartSec_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliGenderTer: 'Title of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliGenderTer_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryCeliFirstNameTer: 'First name of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliFirstNameTer_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryCeliLastNameTer: 'Last name of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliLastNameTer_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryCeliDOBTer: 'Date of birth of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliDOBTer_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliDOBTer_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliDOBTer_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryCeliSINTer_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryCeliSINTer: 'Social insurance number # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressStreetTer: 'No. & street of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressStreetTer_required: 'Please enter address number and street name.',
    ERROR_FIELD_beneficiaryCeliAdressCityTer: 'City of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressCityTer_required: 'Please enter name of city.',
    ERROR_FIELD_beneficiaryCeliAdressProvTer: 'Province of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressProvTer_required: 'Please select a province.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeTer: 'Postal code of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeTer_required: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeTer_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryCeliParentLinkTer: 'Relationship of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliParentLinkTer_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryCeliPartTer: 'Share of beneficiary # 3 (TFSA)',
    ERROR_FIELD_beneficiaryCeliPartTer_lessthanmaxrequired: 'Percentage total is greater than 100 %.',
    ERROR_FIELD_beneficiaryCeliPartTer_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryCeliPartTer_morethanminrequired: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',

    ERROR_FIELD_beneficiaryRespReason: 'Reason',
    ERROR_FIELD_beneficiaryRespReason_reasonrequired: 'Please enter your reason.',
    ERROR_FIELD_beneficiaryRespGender: 'Title of beneficiary',
    ERROR_FIELD_beneficiaryRespGender_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryRespFirstName: 'First name of beneficiary',
    ERROR_FIELD_beneficiaryRespFirstName_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryRespLastName: 'Last name of beneficiary',
    ERROR_FIELD_beneficiaryRespLastName_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryRespDOB: 'Date of birth of beneficiary',
    ERROR_FIELD_beneficiaryRespDOB_required: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryRespDOB_date: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryRespDOB_dateofbirthall: 'Please enter a valid birth date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_beneficiaryRespDOB_dateofbirthmajor: 'You cannot be below the age of majority.',
    ERROR_FIELD_beneficiaryRespSIN: 'Social insurance number',
    ERROR_FIELD_beneficiaryRespSIN_required: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryRespSIN_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryRespAddressSameAsSubscriber: 'Beneficiary address different from subscriber',
    ERROR_FIELD_beneficiaryRespAddressSameAsSubscriber_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryRespAddressStreet: 'No. & street of beneficiary',
    ERROR_FIELD_beneficiaryRespAddressStreet_required: 'Please enter address number and street name.',
    ERROR_FIELD_beneficiaryRespAddressCity: 'City of beneficiary',
    ERROR_FIELD_beneficiaryRespAddressCity_required: 'Please enter name of city.',
    ERROR_FIELD_beneficiaryRespAddressProv: 'Province of beneficiary',
    ERROR_FIELD_beneficiaryRespAddressProv_required: 'Please select a province.',
    ERROR_FIELD_beneficiaryRespAddressPostalCode: 'Postal code of beneficiary',
    ERROR_FIELD_beneficiaryRespAddressPostalCode_required: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryRespAddressPostalCode_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_beneficiaryRespParentLink: 'Relationship of beneficiary',
    ERROR_FIELD_beneficiaryRespParentLink_required: 'Please indicate relationship.',
    ERROR_FIELD_beneficiaryRespDistributionRatio: 'Share of beneficiary',
    ERROR_FIELD_beneficiaryRespDistributionRatio_required: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryRespDistributionRatio_percentagecumulrequired: 'The sum of distribution ratios of all beneficiaries must be equal to 100%.',
    ERROR_FIELD_beneficiaryRespDistributionRatio_positivedigit: 'Please indicate in percentage the portion bequeathed to the beneficiary (between 1 and 100%).',
    ERROR_FIELD_beneficiaryRespBrotherAndSisterOption: 'Siblings',
    ERROR_FIELD_beneficiaryRespBrotherAndSisterOption_required: 'Please select an answer.',
    ERROR_FIELD_beneficiaryRespCaregiverParentFirstName: 'Primary caregiver\'s first name',
    ERROR_FIELD_beneficiaryRespCaregiverParentFirstName_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryRespCaregiverParentLastName: 'Primary caregiver\'s last name',
    ERROR_FIELD_beneficiaryRespCaregiverParentLastName_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryRespCaregiverParentSin: 'Primary caregiver\'s SIN',
    ERROR_FIELD_beneficiaryRespCaregiverParentSin_required: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryRespCaregiverParentSin_sin: 'Please enter a valid social insurance number.',
    ERROR_FIELD_beneficiaryRespCustodialParentGender: 'Custodial parent gender',
    ERROR_FIELD_beneficiaryRespCustodialParentGender_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryRespCustodialParentFirstName: 'Custodial parent first name',
    ERROR_FIELD_beneficiaryRespCustodialParentFirstName_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryRespCustodialParentLastName: 'Custodial parent last name',
    ERROR_FIELD_beneficiaryRespCustodialParentLastName_required: 'Please enter family name.',
    ERROR_FIELD_beneficiaryRespCustodialParentSecGender: 'Custodial parent #2 gender',
    ERROR_FIELD_beneficiaryRespCustodialParentSecGender_required: 'Please make a selection.',
    ERROR_FIELD_beneficiaryRespCustodialParentSecFirstName: 'Custodial parent #2 first name',
    ERROR_FIELD_beneficiaryRespCustodialParentSecFirstName_required: 'Please enter first name.',
    ERROR_FIELD_beneficiaryRespCustodialParentSecLastName: 'Custodial parent #2 last name',
    ERROR_FIELD_beneficiaryRespCustodialParentSecLastName_required: 'Please enter family name.',

    // Brokerage accounts
    ERROR_FIELD_brokerageOtherAccountsIndicator: 'Other brokerage accounts',
    ERROR_FIELD_brokerageOtherAccountsFirmName: 'Firm name (Other accounts)',
    ERROR_FIELD_brokerageOtherAccountsAccountType: 'Type of account (Other accounts)',
    ERROR_FIELD_userInitieIndicator: 'Reporting insider',
    ERROR_FIELD_userInitieCompanyName: 'Reporting insider - Name of the company',
    ERROR_FIELD_userInitieCompanySymbol: 'Reporting insider - Stock symbol',
    ERROR_FIELD_userInitieMarket: 'Reporting insider - Market',
    ERROR_FIELD_userMainShareholderIndicator: 'Significant shareholder',
    ERROR_FIELD_userMainShareholderCompanyName: 'Significant shareholder - Name of the company',
    ERROR_FIELD_userMainShareholderCompanySymbol: 'Significant shareholder - Stock symbol',
    ERROR_FIELD_userMainShareholderMarket: 'Significant shareholder - Market',
    ERROR_FIELD_userPoliticallyExposedForeignPersonIndicator: 'Politically exposed foreign person',
    ERROR_FIELD_userFinancialInterestIndicator: 'Financial interest',
    ERROR_FIELD_userTrustedPersonIndicator: 'Trusted contact person',
    ERROR_FIELD_brokeragePurpose: 'Intended use',
    ERROR_FIELD_brokeragePurposeOther: 'Specification (Intended use)',
    ERROR_FIELD_userBrokerageKnowledge: 'Investment knowledge',
    ERROR_FIELD_userBrokerageCreditBalanceList: 'Credit Balance',
    ERROR_FIELD_userBrokerageDirectDeposit: 'Direct deposit',

    ERROR_FIELD_brokerageOtherAccountsIndicator_required: 'Please make a selection.',
    ERROR_FIELD_brokerageOtherAccountsFirmName_required: 'Please enter name of firm.',
    ERROR_FIELD_brokerageOtherAccountsAccountType_required: 'Please select an account type.',
    ERROR_FIELD_userInitieIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userInitieCompanyName_required: 'Please enter company name.',
    ERROR_FIELD_userInitieCompanySymbol_required: 'Please enter stock symbol.',
    ERROR_FIELD_userInitieMarket_required: 'Please select a market.',
    ERROR_FIELD_userMainShareholderIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userMainShareholderCompanyName_required: 'Please enter company name.',
    ERROR_FIELD_userMainShareholderCompanySymbol_required: 'Please enter stock symbol.',
    ERROR_FIELD_userMainShareholderMarket_required: 'Please select a market.',
    ERROR_FIELD_userPoliticallyExposedForeignPersonIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userFinancialInterestIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userTrustedPersonIndicator_required: 'Please make a selection.',
    ERROR_FIELD_brokeragePurpose_required: 'Please make a selection.',
    ERROR_FIELD_brokeragePurposeOther_required: 'Please specify.',
    ERROR_FIELD_userBrokerageKnowledge_required: 'Please make a selection.',
    ERROR_FIELD_userOtherTradingPersonIndicator: 'Third party',
    ERROR_FIELD_userOtherTradingPersonIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userOtherGuarantingPersonIndicator: 'Other persons guarantee this account',
    ERROR_FIELD_userOtherGuarantingPersonIndicator_required: 'Please make a selection.',
    ERROR_FIELD_userBrokerageCreditBalanceList_required: 'Please make a selection.',
    ERROR_FIELD_userBrokerageDirectDeposit_required: 'Please make a selection.',

    // Entity
    ERROR_FIELD_userOtherEntityType: 'Entity Type',
    ERROR_FIELD_userOtherEntityType_required: 'Please specify an entity type.',
    ERROR_FIELD_userOtherEntityName: 'Entity Name',
    ERROR_FIELD_userOtherEntityName_required: 'Please specify an entity name.',
    ERROR_FIELD_userOtherAmericanIndividualsNumber: 'Americain individuals',
    ERROR_FIELD_userOtherAmericanIndividualsNumber_required: 'Please specify the number of american individuals',
    ERROR_FIELD_userOtherAmericanIndividualsNumber_digit: 'Please specify a number between 0 and 10.',
    ERROR_FIELD_userOtherAmericanIndividualsNumber_min: 'Please specify a number between 0 and 10.',
    ERROR_FIELD_userOtherAmericanIndividualsNumber_max: 'Please specify a number between 0 and 10.',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber: 'Other than American individuals',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber_required: 'Please specify the number of other than American individuals',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber_digit: 'Please specify a number between 0 and 10.',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber_min: 'Please specify a number between 0 and 10.',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber_max: 'Please specify a number between 0 and 10.',
    ERROR_FIELD_userOtherAmericanResidentIndicator: 'American Resident',
    ERROR_FIELD_userOtherAmericanResidentIndicator_required: 'Please make a selection.',

    // Transfer request choice
    ERROR_FIELD_hasTransfers: 'Transfer from another financial institution',
    ERROR_FIELD_hasTransfers_required: 'Please make a selection.',
    ERROR_FIELD_transfer: 'Accounts with transfer of assets',
    ERROR_FIELD_transfer_required: 'Please indicate at least one account with transfer of assets.',
    ERROR_FIELD_jointTransfer: 'Accounts with transfer of assets',
    ERROR_FIELD_jointTransfer_required: 'Please indicate at least one account with transfer of assets.',
    ERROR_FIELD_otherTransfer: 'Accounts with transfer of assets',
    ERROR_FIELD_otherTransfer_required: 'Please indicate at least one account with transfer of assets.',

    // Transfer request
    ERROR_FIELD_actTypeCurrency_required: 'Please enter a currency.',
    ERROR_FIELD_actTypeCurrency: 'Currency',
    ERROR_FIELD_bankName_required: 'Please enter name of delivering institution.',
    ERROR_FIELD_bankName: 'Name of delivering institution',
    ERROR_FIELD_bankPhone_phone: 'Please enter a valid telephone number.',
    ERROR_FIELD_bankPhone: 'Phone number',
    ERROR_FIELD_addressStreet_required: 'Please enter delivering institution\'s address number and street name.',
    ERROR_FIELD_addressStreet: 'Address',
    ERROR_FIELD_addressCity_required: 'Please enter the name of delivering institution\'s city.',
    ERROR_FIELD_addressCity: 'City',
    ERROR_FIELD_addressProv_required: 'Please select a province.',
    ERROR_FIELD_addressProv: 'Province',
    ERROR_FIELD_addressPostalCode_required: 'Please enter a valid postal code.',
    ERROR_FIELD_addressPostalCode_zipcode: 'Please enter a valid postal code.',
    ERROR_FIELD_addressPostalCode: 'Postal code',
    ERROR_FIELD_accountNumber_required: 'Please enter an account number.',
    ERROR_FIELD_accountNumberSec_required: 'Please enter an account number.',
    ERROR_FIELD_accountNumber: 'Account number at delivering institution',
    ERROR_FIELD_accountNumber_alphanumeric: 'Please enter a valid account number.',
    ERROR_FIELD_accountNumberSec: 'Account number at delivering institution',
    ERROR_FIELD_accountType_required: 'Please select a type of account',
    ERROR_FIELD_accountTypeSec_required: 'Please select a type of account',
    ERROR_FIELD_accountType: 'Type of account',
    ERROR_FIELD_accountTypeSec: 'Type of account',
    ERROR_FIELD_accountCurrency_required: 'Please enter a currency.',
    ERROR_FIELD_accountCurrencySec_required: 'Please enter a currency.',
    ERROR_FIELD_accountCurrency: 'Currency',
    ERROR_FIELD_accountCurrencySec: 'Currency',
    ERROR_FIELD_transferType_required: 'Please enter a type of transfer.',
    ERROR_FIELD_transferType: 'Type of transfer',
    ERROR_FIELD_transferInstructionType_required: 'Please enter transfer instructions.',
    ERROR_FIELD_transferInstructionType: 'Transfer Instructions',
    ERROR_FIELD_transferCash_required: 'Please enter an amount to be transferred.',
    ERROR_FIELD_transferCash_max: 'Please enter a valid amount to be transferred.',
    ERROR_FIELD_transferCash: 'Transfer amount',
    ERROR_FIELD_description_required: 'Please enter a description.',
    ERROR_FIELD_description_Required: 'Please enter a description.',
    ERROR_FIELD_description: 'Description',
    ERROR_FIELD_quantity_required: 'Please enter a quantity.',
    ERROR_FIELD_quantity_max: 'Please enter a valid quantity.',
    ERROR_FIELD_quantity: 'Quantity',
    ERROR_FIELD_type_required: 'Please select a type.',
    ERROR_FIELD_type: 'Type',
    ERROR_FIELD_accountStatementName: 'Account statement',
    ERROR_FIELD_accountStatementName_exceedFileSize: 'You have reached the limit of account statement attached to the opening request (1.5 MB).',
    ERROR_FIELD_accountStatementName_invalidFormat: 'Please select a valid PDF file.',
    ERROR_FIELD_accountStatementName_fileEncrypted: 'Please use an unencrypted and uncorrupted version of your document.',
    ERROR_FIELD_accountStatementName_fileInfected: 'Antivirus analysis on the selected file has failed.',
    ERROR_FIELD_accountStatementName_antivirusNotAvailable: 'We are currently experiencing technical problems. Please try later.',
    ERROR_FIELD_accountStatementName_invalidDocument: 'Please select a valid document.',
    ERROR_FIELD_newExistAct_pattern: 'Adding an account is not available for automated opening.',

    ERROR_FIELD_transmissionMethod: 'Transmission choice',
    ERROR_FIELD_transmissionMethod_required: 'Please make a selection.',

    ERROR_FIELD_attachedDocument_prefix: '{{index}} document type',
    ERROR_FIELD_attachedDocumentName: 'Type of attached document',
    ERROR_FIELD_attachedDocumentName_required: 'Please select a type.',
    ERROR_FIELD_attachedDocumentName_attachedDocumentUnique: 'Attached documents must all be of different type.',
    ERROR_FIELD_attachedDocument: 'Attached document',
    ERROR_FIELD_attachedDocument_required: 'Please select a file to upload.',
    ERROR_FIELD_attachedDocument_invalidFormat: 'Please select a valid JPEG, PNG or PDF file.',
    ERROR_FIELD_attachedDocument_fileEncrypted: 'Please use an unencrypted and uncorrupted version of your document or attach it in JPEG format.',
    ERROR_FIELD_attachedDocument_fileInfected: 'Antivirus analysis on the selected file has failed.',
    ERROR_FIELD_attachedDocument_antivirusNotAvailable: 'We are currently experiencing technical problems. Please try later.',
    ERROR_FIELD_attachedDocument_invalidDocument: 'Please select a valid document.',
    ERROR_FIELD_attachedDocument_weightInvalid: 'The selected file exceeds the limit allowed. Please reduce its weight.',
    ERROR_FIELD_attachedDocumentNameBack: 'Type of attached document',
    ERROR_FIELD_attachedDocumentNameBack_required: 'Please select a type.',
    ERROR_FIELD_attachedDocumentNameBack_attachedDocumentUnique: 'Attached documents must all be of different type.',
    ERROR_FIELD_attachedDocumentBack: 'Attached document back',
    ERROR_FIELD_attachedDocumentBack_required: 'Please select a file to upload.',
    ERROR_FIELD_attachedDocumentBack_invalidFormat: 'Please select a valid JPEG, PNG or PDF file.',
    ERROR_FIELD_attachedDocumentBack_fileEncrypted: 'Please use an unencrypted and uncorrupted version of your document or attach it in JPEG format.',
    ERROR_FIELD_attachedDocumentBack_fileInfected: 'Antivirus analysis on the selected file has failed.',
    ERROR_FIELD_attachedDocumentBack_antivirusNotAvailable: 'We are currently experiencing technical problems. Please try later.',
    ERROR_FIELD_attachedDocumentBack_weightInvalid: 'The selected file exceeds the limit allowed. Please reduce its weight.',
    ERROR_FIELD_attachedDocumentBack_invalidDocument: 'Please select a valid document.',

    ERROR_FIELD_userMetAtOffice: 'Client met in person',
    ERROR_FIELD_userMetAtOffice_required: 'Please make a selection.',
    ERROR_FIELD_userIdentityCodeCaisse: 'Identification document',
    ERROR_FIELD_userIdentityCode: 'Identification document',
    ERROR_FIELD_firstIdentityCode: '1st document type - Identification document',
    ERROR_FIELD_secondIdentityCode: '2nd document type - Identification document',
    ERROR_FIELD_userIdentityCode_required: 'Please select an identification document.',
    ERROR_FIELD_firstIdentityCode_required: 'Please select an identification document.',
    ERROR_FIELD_secondIdentityCode_required: 'Please select an identification document.',

    ERROR_FIELD_userIdentityAdvisorFamilyName: 'Advisor Family Name',
    ERROR_FIELD_userIdentityAdvisorFamilyName_required: 'Please enter the advisor family name.',
    ERROR_FIELD_userIdentityAdvisorFirstName: 'Advisor First Name',
    ERROR_FIELD_userIdentityAdvisorFirstName_required: 'Please enter the advisor first name.',
    ERROR_FIELD_userIdentityAdvisorBranchName: 'Advisor Branch Name',
    ERROR_FIELD_userIdentityAdvisorBranchName_required: 'Please enter the advisor branch name.',
    ERROR_FIELD_userIdentityAdvisorInstitution: 'Advisor Institution',
    ERROR_FIELD_userIdentityAdvisorInstitution_required: 'Please enter the advisor institution.',
    ERROR_FIELD_userIdentityAdvisorTransit: 'Advisor Transit',
    ERROR_FIELD_userIdentityAdvisorTransit_required: 'Please enter the advisor first name.',

    ERROR_FIELD_userIdentityIDNumber: 'Unique ID number (document number)',
    ERROR_FIELD_firstIdentityIDNumber: '1st document type - Unique ID number (document number)',
    ERROR_FIELD_secondIdentityIDNumber: '2nd document type - Unique ID number (document number)',
    ERROR_FIELD_userIdentityIDNumber_required: 'Please enter the unique ID number (document number).',
    ERROR_FIELD_firstIdentityIDNumber_required: 'Please enter the unique ID number (document number).',
    ERROR_FIELD_secondIdentityIDNumber_required: 'Please enter the unique ID number (document number).',
    ERROR_FIELD_userIdentityExpirationDate: 'Expiration date',
    ERROR_FIELD_firstIdentityExpirationDate: '1st document type - Expiration date',
    ERROR_FIELD_secondIdentityExpirationDate: '2nd document type - Expiration date',
    ERROR_FIELD_firstIdentityDate: '1st document type - Issue or expiry Date',
    ERROR_FIELD_secondIdentityDate: '2nd document type - Issue or expiry Date',
    ERROR_FIELD_userIdentityExpirationDate_required: 'Please enter a valid expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_userIdentityExpirationDate_date: 'Please enter a valid expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_userIdentityExpirationDate_dateinfuture: 'Expiration date must be in the future.',
    ERROR_FIELD_firstIdentityExpirationDate_required: 'Please enter a valid expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_firstIdentityExpirationDate_date: 'Please enter a valid expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_firstIdentityExpirationDate_dateinfuture: 'Expiration date must be in the future.',
    ERROR_FIELD_secondIdentityExpirationDate_required: 'Please enter a valid expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_secondIdentityExpirationDate_date: 'Please enter a valid expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_secondIdentityExpirationDate_dateinfuture: 'Expiration date must be in the future.',
    ERROR_FIELD_firstIdentityDate_required: 'Please enter a valid issue or expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_secondIdentityDate_required: 'Please enter a valid issue or expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_firstIdentityDate_date: 'Please enter a valid expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_secondIdentityDate_date: 'Please enter a valid expiration date in the following format: YYYY-MM-DD',
    ERROR_FIELD_userIdentityProvince: 'Issuing province or territory in Canada',
    ERROR_FIELD_firstIdentityProvince: '1st document type - Issuing province or territory in Canada',
    ERROR_FIELD_secondIdentityProvince: '2nd document type - Issuing province or territory in Canada',
    ERROR_FIELD_userIdentityProvince_required: 'Please select a province.',
    ERROR_FIELD_firstIdentityProvince_required: 'Please select a province.',
    ERROR_FIELD_secondIdentityProvince_required: 'Please select a province.',
    ERROR_FIELD_userIdentityCountry: 'Issuing country',
    ERROR_FIELD_firstIdentityCountry: '1st document type - Issuing country',
    ERROR_FIELD_secondIdentityCountry: '2nd document type - Issuing country',
    ERROR_FIELD_userIdentityCountry_required: 'Please select a country.',
    ERROR_FIELD_firstIdentityCountry_required: 'Please select a country.',
    ERROR_FIELD_secondIdentityCountry_required: 'Please select a country.',
    ERROR_FIELD_userIdentityVerificationDate: 'Verification Date',
    ERROR_FIELD_userIdentityVerificationDate_required: 'Please enter a valid verification date in the following format: yyyy-mm-dd.',
    ERROR_FIELD_userIdentityVerificationDate_date: 'Please enter a valid verification date in the following format: yyyy-mm-dd.',

    ERROR_FIELD_esignUserPhoneChoice: 'Please make a selection',
    ERROR_FIELD_esignUserPhoneChoice_required: 'Please select a telephone number.',
    ERROR_FIELD_esignUserPhoneOther: 'Telephone number',
    ERROR_FIELD_esignUserPhoneOther_required: 'Please enter a telephone number.',
    ERROR_FIELD_esignUserPhoneOther_phone: 'Please enter a valid telephone number.',

    ERROR_FIELD_jointActPeople: 'Number of people',
    ERROR_FIELD_jointActPeople_required: 'Please make a selection.',
    ERROR_FIELD_resQc: 'Resident of the province of Quebec',
    ERROR_FIELD_resQc_required: 'Please make a selection.',
    ERROR_FIELD_survivorShip: 'Right of Survivorship',
    ERROR_FIELD_survivorShip_required: 'Please make a selection.',

    ERROR_FIELD_associationActPeople: 'Other than American individuals',
    ERROR_FIELD_associationActPeople_required: 'Please enter a number.',
    ERROR_FIELD_associationActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_associationActPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_associationActPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_associationActAmericanPeople: 'Americain individual (U.S. citizen or resident)',
    ERROR_FIELD_associationActAmericanPeople_required: 'Please enter a number.',
    ERROR_FIELD_associationActAmericanPeople_digit: 'Please enter a number.',
    ERROR_FIELD_associationActAmericanPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_associationActAmericanPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_associationHoldingAccount: 'Hold an account',
    ERROR_FIELD_associationHoldingAccount_required: 'Please make a selection',
    ERROR_FIELD_associationLinkedAccount: 'Link an account',
    ERROR_FIELD_associationLinkedAccount_required: 'Please make a selection',

    ERROR_FIELD_clubActPeople: 'Other than American individuals',
    ERROR_FIELD_clubActPeople_required: 'Please enter a number.',
    ERROR_FIELD_clubActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_clubActPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_clubActPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_clubActAmericanPeople: 'Americain individual (U.S. citizen or resident)',
    ERROR_FIELD_clubActAmericanPeople_required: 'Please enter a number.',
    ERROR_FIELD_clubActAmericanPeople_digit: 'Please enter a number.',
    ERROR_FIELD_clubActAmericanPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_clubActAmericanPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_clubHoldingAccount: 'Hold an account',
    ERROR_FIELD_clubHoldingAccount_required: 'Please make a selection',
    ERROR_FIELD_clubLinkedAccount: 'Link an account',
    ERROR_FIELD_clubLinkedAccount_required: 'Please make a selection',

    ERROR_FIELD_cooperativeHoldingAccount: 'Hold an account',
    ERROR_FIELD_cooperativeHoldingAccount_required: 'Please make a selection',
    ERROR_FIELD_cooperativeLinkedAccount: 'Link an account',
    ERROR_FIELD_cooperativeLinkedAccount_required: 'Please make a selection',

    ERROR_FIELD_corporationOneShareholder: 'Shareholders',
    ERROR_FIELD_corporationOneShareholder_required: 'Please make a selection',
    ERROR_FIELD_corporationHoldingAccount: 'Hold an account',
    ERROR_FIELD_corporationHoldingAccount_required: 'Please make a selection',
    ERROR_FIELD_corporationLinkedAccount: 'Link an account',
    ERROR_FIELD_corporationLinkedAccount_required: 'Please make a selection',

    ERROR_FIELD_couniqueActPeople: 'Administrators',
    ERROR_FIELD_couniqueActPeople_required: 'Please enter a number.',
    ERROR_FIELD_couniqueActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_couniqueActPeople_min: 'Please enter a number between 1 and 10.',
    ERROR_FIELD_couniqueActPeople_max: 'Please enter a number between 1 and 10.',
    ERROR_FIELD_couniqueHoldingAccount: 'Hold an account',
    ERROR_FIELD_couniqueHoldingAccount_required: 'Please make a selection',
    ERROR_FIELD_couniqueLinkedAccount: 'Link an account',
    ERROR_FIELD_couniqueLinkedAccount_required: 'Please make a selection',

    ERROR_FIELD_enfesqualitesAddSpouse: 'Add your spouse to the account',
    ERROR_FIELD_enfesqualitesAddSpouse_required: 'Please make a selection',
    ERROR_FIELD_enfesqualitesResQc: 'Resident of the province of Quebec',
    ERROR_FIELD_enfesqualitesResQc_required: 'Please make a selection.',
    ERROR_FIELD_enfesqualitesSurvivorShip: 'Right of Survivorship',
    ERROR_FIELD_enfesqualitesSurvivorShip_required: 'Please make a selection.',

    ERROR_FIELD_enfrefAddSpouse: 'Add your spouse to the account',
    ERROR_FIELD_enfrefAddSpouse_required: 'Please make a selection',
    ERROR_FIELD_enfrefResQc: 'Resident of the province of Quebec',
    ERROR_FIELD_enfrefResQc_required: 'Please make a selection.',
    ERROR_FIELD_enfrefSurvivorShip: 'Right of Survivorship',
    ERROR_FIELD_enfrefSurvivorShip_required: 'Please make a selection.',

    ERROR_FIELD_fiducieType: 'Type of trust',
    ERROR_FIELD_fiducieType_required: 'Please make a selection',

    ERROR_FIELD_fiducieActPeople: 'Other than American individuals',
    ERROR_FIELD_fiducieActPeople_required: 'Please enter a number.',
    ERROR_FIELD_fiducieActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_fiducieActPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_fiducieActPeople_max: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_fiducieHasAmericanResident: 'American resident',
    ERROR_FIELD_fiducieHasAmericanResident_required: 'Please make a selection',

    ERROR_FIELD_fiducieActAmericanPeople: 'Americain individual (U.S. citizen or resident)',
    ERROR_FIELD_fiducieActAmericanPeople_required: 'Please enter a number.',
    ERROR_FIELD_fiducieActAmericanPeople_digit: 'Please enter a number.',
    ERROR_FIELD_fiducieActAmericanPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_fiducieActAmericanPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_osblHoldingAccount: 'Hold an account',
    ERROR_FIELD_osblHoldingAccount_required: 'Please make a selection',
    ERROR_FIELD_osblLinkedAccount: 'Link an account',
    ERROR_FIELD_osblLinkedAccount_required: 'Please make a selection',

    ERROR_FIELD_sencActPeople: 'Other than American individuals',
    ERROR_FIELD_sencActPeople_required: 'Please enter a number.',
    ERROR_FIELD_sencActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_sencActPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_sencActPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_sencActAmericanPeople: 'Americain individual (U.S. citizen or resident)',
    ERROR_FIELD_sencActAmericanPeople_required: 'Please enter a number.',
    ERROR_FIELD_sencActAmericanPeople_digit: 'Please enter a number.',
    ERROR_FIELD_sencActAmericanPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_sencActAmericanPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_sencLegActPeople: 'Associates - Number of legal persons',
    ERROR_FIELD_sencLegActPeople_required: 'Please enter a number.',
    ERROR_FIELD_sencLegActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_sencLegActPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_sencLegActPeople_max: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_sencLegActPeople_actPeopleSumMin: 'The sum of the number of natural persons and the number of legal persons must be between 1 and 10.',
    ERROR_FIELD_sencLegActPeople_actPeopleSumMax: 'The sum of the number of natural persons and the number of legal persons must be between 1 and 10.',
    ERROR_FIELD_sencHoldingAccount: 'Hold an account',
    ERROR_FIELD_sencHoldingAccount_required: 'Please make a selection',
    ERROR_FIELD_sencLinkedAccount: 'Link an account',
    ERROR_FIELD_sencLinkedAccount_required: 'Please make a selection',

    ERROR_FIELD_soccommActPeople: 'Other than American individuals',
    ERROR_FIELD_soccommActPeople_required: 'Please enter a number.',
    ERROR_FIELD_soccommActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_soccommActPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_soccommActPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_soccommActAmericanPeople: 'Americain individual (U.S. citizen or resident)',
    ERROR_FIELD_soccommActAmericanPeople_required: 'Please enter a number.',
    ERROR_FIELD_soccommActAmericanPeople_digit: 'Please enter a number.',
    ERROR_FIELD_soccommActAmericanPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_soccommActAmericanPeople_max: 'Please enter a number between 0 and 10.',

    ERROR_FIELD_soccommLegActPeople: 'Associates - Number of legal persons',
    ERROR_FIELD_soccommLegActPeople_required: 'Please enter a number.',
    ERROR_FIELD_soccommLegActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_soccommLegActPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_soccommLegActPeople_max: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_soccommLegActPeople_actPeopleSumMin: 'The sum of the number of natural persons and the number of legal persons must be between 1 and 10.',
    ERROR_FIELD_soccommLegActPeople_actPeopleSumMax: 'The sum of the number of natural persons and the number of legal persons must be between 1 and 10.',
    ERROR_FIELD_soccommHoldingAccount: 'Hold an account',
    ERROR_FIELD_soccommHoldingAccount_required: 'Please make a selection',
    ERROR_FIELD_soccommLinkedAccount: 'Link an account',
    ERROR_FIELD_soccommLinkedAccount_required: 'Please make a selection',

    ERROR_FIELD_succDeceasedProvince: 'Province of residence of the deceased',
    ERROR_FIELD_succDeceasedProvince_required: 'Please make a selection',
    ERROR_FIELD_succAccountValueGreaterLimit: 'Value of the account',
    ERROR_FIELD_succAccountValueGreaterLimit_required: 'Please make a selection',
    ERROR_FIELD_succWill: 'Will or a marriage contract',
    ERROR_FIELD_succWill_required: 'Please make a selection',
    ERROR_FIELD_succActPeople: 'Executors',
    ERROR_FIELD_succActPeople_required: 'Please enter a number.',
    ERROR_FIELD_succActPeople_digit: 'Please enter a number.',
    ERROR_FIELD_succActPeople_min: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_succActPeople_max: 'Please enter a number between 0 and 10.',
    ERROR_FIELD_succAccountHasAmericanResident: 'American resident',
    ERROR_FIELD_succAccountHasAmericanResident_required: 'Please make a selection',

    ERROR_FIELD_password_password: 'Your password must be six to eight characters long and contain at least one letter and one number.',
    ERROR_FIELD_newProfileSecurityQuestion: 'Security question',
    ERROR_FIELD_newProfileSecurityQuestion_required: 'Please select a security question.',
    ERROR_FIELD_newProfileSecurityAnswer: 'Answer',
    ERROR_FIELD_newProfileSecurityAnswer_required: 'Please enter a security answer.',
    ERROR_FIELD_newProfileSecurityAnswer_minlength: 'Please enter a valid security answer.',

    ERROR_FIELD_newPassword: 'New password',
    ERROR_FIELD_newPassword_required: 'Please enter a new password.',
    ERROR_FIELD_newPassword_minlength: 'Please enter a new password of at least 8 characters.',
    ERROR_FIELD_confirmNewPassword: 'Confirm password',
    ERROR_FIELD_confirmNewPassword_required: 'Please enter a confirmation password.',
    ERROR_FIELD_confirmNewPassword_passwordMatch: 'The password and confirmation password do not match.',
    ERROR_FIELD_oldPassword: 'Temporary  password.',
    ERROR_FIELD_oldPassword_required: 'Please enter temporary  password.',
    ERROR_FIELD_password_required: 'Please enter a password.',
    ERROR_FIELD_resetPasswordSecurityQuestion_required: 'Please select a security question.',
    ERROR_FIELD_resetPasswordSecurityAnswer_required: 'Please enter a security answer.',
    ERROR_FIELD_resetPasswordSecurityAnswer_minlength: 'Your security answer must be at least five characters long.',

    ERROR_FIELD_ippRegistrationNumber: 'IPP Registration Number',
    ERROR_FIELD_ippRegistrationNumber_required: 'Please enter an IPP Registration Number',
    ERROR_FIELD_ippAuthorizedTradePersonNumber: 'Authorized Trade Person Number',
    ERROR_FIELD_ippAuthorizedTradePersonNumber_required: 'Please enter an authorized trade person number',
    ERROR_FIELD_ippAuthorizedTradePersonNumber_min: 'Please enter a value between 1 and 10',
    ERROR_FIELD_ippAuthorizedTradePersonNumber_max: 'Please enter a value between 1 and 10',
    ERROR_FIELD_ippAuthorizedTradePersonNumber_digit: 'Please enter a number.',

    ERROR_FIELD_gouvOrgCity: 'Is it a city ?',
    ERROR_FIELD_gouvOrgCity_required: 'Please make a selection.',

    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg: 'Investment Objectives - Low-risk income securities',
    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg_required: 'Please enter a number.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg_min: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg_max: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg_investmentObjectives: 'Total investment objectives must equal 100%.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg: 'Investment Objectives - Moderate-risk income securities',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg_required: 'Please enter a number.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg_min: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg_max: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg_investmentObjectives: 'Total investment objectives must equal 100%.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg: 'Investment Objectives - Moderate-to-higher-risk income securities and growth securities',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg_required: 'Please enter a number.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg_min: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg_max: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg_investmentObjectives: 'Total investment objectives must equal 100%.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg: 'Investment Objectives - High to very high risk securities',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg_required: 'Please enter a number.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg_min: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg_max: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg_investmentObjectives: 'Total investment objectives must equal 100%.',

    ERROR_FIELD_userInvestmentObjectivesLowRiskReg: 'Registered accounts - Low-risk income securities',
    ERROR_FIELD_userInvestmentObjectivesLowRiskReg_required: 'Please enter a number.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskReg_min: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskReg_max: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskReg_investmentObjectives: 'Total investment objectives must equal 100%.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg: 'Registered accounts - Moderate-risk income securities',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg_required: 'Please enter a number.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg_min: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg_max: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg_investmentObjectives: 'Total investment objectives must equal 100%.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg: 'Registered accounts - Moderate-to-higher-risk income securities and growth securities',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg_required: 'Please enter a number.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg_min: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg_max: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg_investmentObjectives: 'Total investment objectives must equal 100%.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg: 'Registered accounts - Speculative securities and market strategies',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg_required: 'Please enter a number.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg_min: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg_max: 'Please enter a value between 0 and 100.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg_investmentObjectives: 'Total investment objectives must equal 100%.',

    ERROR_FIELD_fhsaEligibility: 'FHSA eligibility',
    ERROR_FIELD_fhsaEligibility_required: 'Please make a selection.',
    ERROR_FIELD_fhsaAccountMgntType: 'Type of management - FHSA',
    ERROR_FIELD_fhsaCurrency: 'Account currency - FHSA',
    ERROR_FIELD_fhsaCurrency_required: 'Please select the account currency.',
    ERROR_FIELD_fhsaAccountMgntType_invalidMgntType: 'All accounts of the request must be either Commission-based Advisory service or' +
        ' Discretionary Management service.  Please create another request for the accounts that do not have the same type of management.',

    ERROR_FIELD_passwordStrength: 'Weak password',
    ERROR_FIELD_passwordStrength_min: 'Weak password detected. Please create a password that complies with our complexity suggestions.',

    NOT_STANDARD_DATA_ERROR: 'Due to non-standard format retrieved from our core business systems, the following data will not be considered in the update process.'
};
