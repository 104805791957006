import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {NavigationService} from '../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {WebException} from '../../components/models';
import {ModalService} from '../../components/services/modal.service';

@Component({
    selector: 'app-transmission-additional-forms',
    templateUrl: './transmission-additional-forms.component.html'
})
export class TransmissionAdditionalFormsComponent extends BaseNavComponent implements OnInit {

    loadingDownload = false;
    loadingEmail = false;
    error: WebException = null;
    disableNext = false;
    isConjointDCL = false;
    isOtherAccountDCL = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);

    }

    ngOnInit() {
        this.navigationService.emitIsBackDisabled(true);
        this.isConjointDCL = this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT && !this.isPleinEx() && !this.isSourceCaisse();
        this.isOtherAccountDCL = this.formService.getForm().cltType !== this.constants.CLT_TYPE_INDIVIDU
            && this.formService.getForm().cltType !== this.constants.CLT_TYPE_CONJOINT && !this.isPleinEx() && !this.isSourceCaisse();
    }

    downloadPDF() {
        if (!this.formService.saveInProgress(true)) {
            this.modalService.openModal(null, null, '#modal-pdf');
            this.modalService.emitPdfLoading(true);

            this.formService.setTransmitted(false);
            /*update status, transmission method then save if the request is type CONJOINT*/
            if (this.formService.getForm().cltType !== this.constants.CLT_TYPE_INDIVIDU) {

                this.setLoadingDownLoad(true);
                this.error = null;

                this.formService.setFormProperty('requestStatus', this.constants.REQUEST_STATUS_TRANSMIT);
                this.formService.setFormProperty('transmissionMethod', this.constants.TRANSMISSION_MAIL);

                this.formService.transmitForm().subscribe(() => {
                        this.setLoadingDownLoad(false);
                        this.disableNext = false;
                        this.error = null;

                        this.formService.setTransmitted(true);

                        this.modalService.emitPdfLink();
                    },
                    (error: WebException) => {
                        this.setLoadingDownLoad(false);
                        this.error = error;
                        this.modalService.emitError('GLOBAL_ERROR_MSG');
                    });
            } else {
                this.disableNext = false;
                this.modalService.emitPdfLink(true);
            }
        }
    }

    emailPDF() {
        if (!this.formService.saveInProgress(true)) {
            this.setLoadingEmailPdf(true);
            this.error = null;
            if (this.formService.getForm().cltType !== this.constants.CLT_TYPE_INDIVIDU) {
                this.formService.setFormProperty('requestStatus', this.constants.REQUEST_STATUS_TRANSMIT);
                this.formService.setFormProperty('transmissionMethod', this.constants.TRANSMISSION_MAIL);
                this.formService.transmitForm().subscribe(() => {
                    this.email('api/pdf/type-email');
                }, (error: WebException) => {
                    this.setLoadingEmailPdf(false);
                    this.error = error;
                });
            } else {
                this.email('api/pdf/esign-mail');
            }
        }

    }

    email(path: string) {
        this.formService.emailAdditionForm(path).subscribe(() => {
            this.disableNext = false;
            this.error = null;
            this.setLoadingEmailPdf(false);
        }, (errors: WebException) => {
            this.setLoadingEmailPdf(false);
            this.error = errors;
        });
    }

    isSourceCaisse(): boolean {
        return this.formService.isCaisseContext();
    }

    setLoadingDownLoad(loading: boolean): void {
        this.loadingDownload = loading;
        this.formService.emitIsSaving(loading);
    }

    setLoadingEmailPdf(loading: boolean): void {
        this.loadingEmail = loading;
        this.formService.emitIsSaving(loading);
    }
}
