import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {AppComponent} from './app.component';
import {DateFormatDirective} from './components/directives/date-format.directive';
import {PercentFormatDirective} from './components/directives/percent-format.directive';
import {PhoneFormatDirective} from './components/directives/phone-format.directive';
import {SinFormatDirective} from './components/directives/sin-format.directive';
import {ZipCodeFormatDirective} from './components/directives/zip-code-format.directive';
import {IntegerFormatDirective} from './components/directives/integer-format.directive';
import {CurrencyFormatDirective} from './components/directives/currency-format.directive';
import {DecimalCurrencyFormatDirective} from './components/directives/decimal-currency-format.directive';
import {SecurityQuantityFormatDirective} from './components/directives/security-quantity-format.directive';
import {FormService} from './components/services/form.service';
import {ODCFormBindingDirective} from './components/directives/odc-form-binding.directive';
import {MomentPipe} from './components/pipes/moment.pipe';
import {RequestOrderByPipe} from './components/pipes/requestOrderBy.pipe';
import {AsideComponent} from './modules/layout/aside/aside.component';
import {HeaderComponent} from './modules/layout/header/header.component';
import {FooterComponent} from './modules/layout/footer/footer.component';
import {TrashComponent} from './modules/trash/trash.component';
import {LoginComponent} from './modules/login/login.component';
import {PageNotFoundComponent} from './modules/page-not-found/page-not-found.component';
import {LayoutComponent} from './modules/layout/layout.component';
import {QuestionnaireComponent} from './modules/layout/questionnaire/questionnaire.component';
import {AddressCompleterService} from './components/services/address-completer.service';
import {ODC_CONFIG, OdcConfig} from './odc-config';
import {AccountsChoiceComponent} from './modules/accounts-choice/accounts-choice.component';
import {PersonalInformationComponent} from './modules/personal-information/personal-information.component';
import {ResidentialAddressComponent} from './modules/personal-information/residential-address.component';
import {OccupationComponent} from './modules/occupation/occupation.component';
import {BeneficiaryRrspComponent} from './modules/civil-status/beneficiary-rrsp.component';
import {FinancialInformationComponent} from './modules/financial-information/financial-information.component';
import {BrokerageAccountsComponent} from './modules/legal/brokerage-accounts.component';
import {ReviewPersonalAccountComponent} from './modules/review/review-personal-account.component';
import {ReviewOtherAccountComponent} from './modules/review/review-other-account.component';
import {ReviewFieldComponent} from './modules/review/tools/review-field.component';
import {ReviewSubFieldComponent} from './modules/review/tools/review-sub-field.component';
import {ReviewHeaderComponent} from './modules/review/tools/review-header.component';
import {ReviewSubSectionComponent} from './modules/review/tools/review-sub-section.component';
import {ReviewSectionComponent} from './modules/review/tools/review-section.component';
import {ReviewBlockComponent} from './modules/review/tools/review-block.component';
import {ReviewValueComponent} from './modules/review/tools/review-value.component';
import {ReviewFieldListComponent} from './modules/review/tools/review-field-list.component';
import {ReviewFieldQuestionComponent} from './modules/review/tools/review-field-question.component';
import {ReviewBeneficiaryComponent} from './modules/review/tools/review-beneficiary.component';
import {ReviewTransferComponent} from './modules/review/tools/review-transfer.component';
import {ConsentsComponent} from './modules/consents/consents.component';
import {SpecificConsentsComponent} from './modules/consents/specific-consents.component';
import {SpouseInformationComponent} from './modules/civil-status/spouse-information.component';
import {ReferredAccountComponent} from './modules/civil-status/referred-account.component';
import {AttorneyComponent} from './modules/civil-status/attorney.component';
import {ClientTypeComponent} from './modules/prepare/client-type.component';
import {BeginComponent} from './modules/prepare/begin.component';
import {PlatformChoiceComponent} from './modules/prepare/platform-choice.component';
import {TrashService} from './components/services/trash.service';
import {NavigationService} from './components/services/navigation.service';
import {TransmissionAdditionalFormsComponent} from './modules/transmission/transmission-additional-forms.component';
import {TransmissionAttachmentsComponent} from './modules/transmission/transmission-attachments.component';
import {TransmissionChoiceComponent} from './modules/transmission/transmission-choice.component';
import {TransmissionEsignComponent} from './modules/transmission/transmission-esign.component';
import {TransmissionKitDownloadComponent} from './modules/transmission/transmission-kit-download.component';
import {ConfirmationComponent} from './modules/confirmation/confirmation.component';
import {BaseNavComponent} from './modules/layout/questionnaire/base-nav.component';
import {BeneficiaryTfsaComponent} from './modules/civil-status/beneficiary-tfsa.component';
import {BeneficiaryFhsaComponent} from './modules/civil-status/beneficiary-fhsa.component';
import {OptionsAccountComponent} from './modules/legal/options-account.component';
import {ShareholderComponent} from './modules/legal/shareholder.component';
import {AsidePreparationComponent} from './modules/layout/aside/aside-preparation.component';
import {AsideConsultAllComponent} from './modules/layout/aside/aside-consult-all.component';
import {ModalService} from './components/services/modal.service';
import {SessionService} from './components/services/session.service';
import {TokenService} from './components/services/token.service';
import {RecoveryService} from './components/services/recovery.service';
import {RecoveryRedirectService} from './components/services/recovery-redirect.service';
import {ErrorComponent} from './modules/layout/questionnaire/error.component';
import {ErrorListComponent} from './modules/layout/questionnaire/error-list.component';
import {AddressFieldDirective} from './components/directives/address-field.directive';
import {AddressFormComponent} from './modules/address-form/address-form.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InterceptedHttp} from './components/interceptors/http.interceptor';
import {ListModelService} from './components/services/list-model.service';
import {NumericFormatDirective} from './components/directives/numeric-format.directive';
import {HelpComponent} from './modules/layout/help/help.component';
import {HelpWarningComponent} from './modules/layout/help/help-warning.component';
import {BeneficiaryComponent} from './modules/civil-status/beneficiary/beneficiary.component';
import {BeneficiaryCeliAppComponent} from './modules/civil-status/beneficiary/beneficiary-celiapp.component';
import {SuccessorComponent} from './modules/civil-status/beneficiary/successor.component';
import {BeneficiaryPleinExComponent} from './modules/civil-status/beneficiary/beneficiary-pleinex.component';
import {TransferRequestChoiceComponent} from './modules/transfer-request/transfer-request-choice.component';
import {TransferRequestLifComponent} from './modules/transfer-request/transfer-request-lif.component';
import {TransferRequestLiraComponent} from './modules/transfer-request/transfer-request-lira.component';
import {TransferRequestNonRegisteredComponent} from './modules/transfer-request/transfer-request-non-registered.component';
import {TransferRequestRespComponent} from './modules/transfer-request/transfer-request-resp.component';
import {TransferRequestRrifComponent} from './modules/transfer-request/transfer-request-rrif.component';
import {TransferRequestRrspComponent} from './modules/transfer-request/transfer-request-rrsp.component';
import {TransferRequestTfsaComponent} from './modules/transfer-request/transfer-request-tfsa.component';
import {TransferFormComponent} from './modules/transfer-request/transfer-form.component';
import {AccountFormComponent} from './modules/transfer-request/account-form/account-form.component';
import {TranslateSortService} from './components/services/translate-sort.service';
import {TransferRequestGenericTypeComponent} from './modules/transfer-request/transfer-request-generic-type.component';
import {ReviewClientIdentityComponent} from './modules/review/review-client-identity.component';
import {ReviewUpdateAccountComponent} from './modules/review/update-account/review-update-account.component';
import {CountryCompleterService} from './components/services/country-completer.service';
import {CountryCompleterDirective} from './components/directives/country-completer.directive';
import {FileValidatorService} from './components/services/file-validator.service';
import {JointRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/joint-related-questions.component';
import {AssociationRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/association-related-questions.component';
import {ClubRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/club-related-questions.component';
import {CooperativeRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/cooperative-related-questions.component';
import {CorporationRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/corporation-related-questions.component';
import {CoUniqueRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/co-unique-related-questions.component';
import {OsblRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/osbl-related-questions.component';
import {SencRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/senc-related-questions.component';
import {EnfantRefRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/enfant-ref-related-questions.component';
import {
    EnfantEsQualitesRelatedQuestionsComponent
} from './modules/accounts-choice/account-type-related-questions/enfant-es-qualites-related-questions.component';
import {SocCommRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/soc-comm-related-questions.component';
import {FiducieRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/fiducie-related-questions.component';
import {SuccessionRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/succession-related-questions.component';
import {ProfileService} from './components/services/profile.service';
import {ModalPasswordComponent} from './modules/layout/modal/password/modal-password.component';
import {BaseTransferComponent} from './modules/transfer-request/base-transfer.component';
import {ResetPasswordComponent} from './modules/login/reset-password.component';
import {ProfileAccountOpeningRequestListComponent} from './modules/login/profile-account-opening-request-list.component';
import {RecaptchaComponent} from './modules/login/recaptcha.component';
import {TypeOfManagementComponent} from './modules/accounts-choice/type-of-management.component';
import {RemoveErrorDirective} from './components/directives/remove-error-directive';
import {AccountRulesService} from './components/services/account-rules.service';
import {
    BaseAccountTypeRelatedQuestionsComponent
} from './modules/accounts-choice/account-type-related-questions/base-account-type-related-questions.component';
import {PersonalInformationSecondHolderComponent} from './modules/personal-information/personal-information-second-holder.component';
import {PersonalInformationThirdHolderComponent} from './modules/personal-information/personal-information-third-holder.component';
import {
    ModalPersonalInformationSecondAccountholderSpouseComponent
} from './modules/layout/modal/modal-personal-information-second-accountholder-spouse.component';
import {ModalAddEntityComponent} from './modules/layout/modal/modal-add-entity.component';
import {IppRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/ipp-related-questions.component';
import {AccountHolderInformationComponent} from './modules/personal-information/account-holder-information.component';
import {WindowRefService} from './components/services/window-ref.service';
import {ModalPdfComponent} from './modules/layout/modal/modal-pdf.component';
import {AccountHolderInformationDeceasedComponent} from './modules/personal-information/account-holder-information-deceased.component';
import {AccountHolderInformationChildComponent} from './modules/personal-information/account-holder-information-child.component';
import {AccountHolderInformationSecondChildComponent} from './modules/personal-information/account-holder-information-second-child.component';
import {AccountHolderInformationGuardianshipComponent} from './modules/personal-information/account-holder-information-guardianship.component';
import {AccountHolderInformationCuratorshipComponent} from './modules/personal-information/account-holder-information-curatorship.component';
import {AccountHolderInformationAttorneyComponent} from './modules/personal-information/account-holder-information-attorney.component';
import {AccountHolderInformationIppComponent} from './modules/personal-information/account-holder-information-ipp.component';
import {OdcFieldComponent} from './modules/layout/questionnaire/fields/odc-field.component';
import {QuestionnaireBtnComponent} from './modules/layout/questionnaire/questionnaire-btn.component';
import {HelpInsiderComponent} from './modules/layout/help/help-insider.component';
import {EntitiesQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/entities-questions.component';
import {CommonModule} from '@angular/common';
import {RouterTestingModule} from '@angular/router/testing';
import {BaseInformationComponent} from './modules/personal-information/base-information.component';
import {
    BaseHoldingAccountRelatedQuestionsComponent
} from './modules/accounts-choice/account-type-related-questions/base-holding-account-related-questions.component';
import {
    BaseAddSpouseRelatedQuestionsComponent
} from './modules/accounts-choice/account-type-related-questions/base-add-spouse-related-questions.component';
import {AttachedDocumentService} from './components/services/attached-document.service';
import {BeneficiaryRespComponent} from './modules/civil-status/beneficiary-resp.component';
import {InterestRateFormatDirective} from './components/directives/interest-rate-format.directive';
import {InvestmentObjectivesAndRiskFactorsComponent} from './modules/financial-information/investment-objectives-and-risk-factors.component';
import {OptionAccountComponent} from './modules/financial-information/option-account.component';
import {SubmissionComponent} from './modules/submission/submission.component';
import {OccupationComponentSecondHolderComponent} from './modules/occupation/occupation-second-holder.component';
import {OccupationComponentThirdHolderComponent} from './modules/occupation/occupation-third-holder.component';
import {ResidentialAddressSecondHolderComponent} from './modules/personal-information/residential-address-second-holder.component';
import {ResidentialAddressThirdHolderComponent} from './modules/personal-information/residential-address-third-holder.component';
import {BrokerageAccountsComponentSecondHolderComponent} from './modules/legal/brokerage-accounts-second-holder.component';
import {BrokerageAccountsComponentThirdHolderComponent} from './modules/legal/brokerage-accounts-third-holder.component';
import {ReviewClientIdentitySecondHolderComponent} from './modules/review/review-client-identity-second-holder.component';
import {GouvOrgRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/gouv-org-related-questions.component';
import {AccountHolderInformationChildRefComponent} from './modules/personal-information/account-holder-information-child-ref.component';
import {
    AccountHolderInformationChildEsQualitesComponent
} from './modules/personal-information/account-holder-information-child-es-qualites.component';
import {BranchErrorComponent} from './modules/branch/branch-error.component';
import {RequestFollowUpService} from './components/services/request-follow-up.service';
import {BranchFieldComponent} from './modules/layout/branch/branch-field.component';
import {DefaultBeginComponent} from './modules/prepare/default-begin.component';
import {BranchBeginComponent} from './modules/prepare/branch-begin.component';
import {AsideCaisseComponent} from './modules/layout/aside/aside-caisse.component';
import {ConfirmationCaisseComponent} from './modules/confirmation/confirmation-caisse.component';
import {StreetCompleterDirective} from './components/directives/street-completer.directive';
import {AddAccountSubmissionComponent} from './modules/submission/add-account-submission.component';
import {OssQueryService} from './components/services/oss-query.service';
import {IframeRefreshDirective} from './components/directives/iframe-refresh.directive';
import {AccountsChoiceUpdateComponent} from './modules/accounts-choice/update-account/accounts-choice-update.component';
import {UpdatePersonalInformationComponent} from './modules/personal-information/update-account/update-personal-information.component';
import {FormsComparisonService} from './components/services/forms-comparison.service';
import {ReviewUpdateAccountTableComponent} from './modules/review/update-account/review-update-account-table.component';
import {SummarySubtitleValuesPipe} from './components/pipes/update-acount-delta-review-page/summary-sub-title.pipe';
import {ReviewUpdateAccountHeaderComponent} from './modules/review/update-account/review-update-account-header.component';
import {SummaryStepLabelPipe} from './components/pipes/update-acount-delta-review-page/summary-step-label.pipe';
import {ReviewUpdateAccountTableCellComponent} from './modules/review/update-account/review-update-account-table-cell.component';
import {FormatToSummaryQuestionPipe} from './components/pipes/update-acount-delta-review-page/summary-question.pipe';
import {TruncatePipe} from './components/pipes/truncate.pipe';
import {ReviewUpdateComponent} from './modules/review/tools/review-update.component';
import {ReviewUpdatePageComponent} from './modules/consultation/update-account/review-update-page.component';
import {
    ProvinceResidentQuestionComponent
} from './modules/accounts-choice/account-type-related-questions/province-resident-question/province-resident-question.component';
import {ModalPdfMultiLangComponent} from './modules/layout/modal/modal-pdf-multi-lang/modal-pdf-multi-lang.component';
import {TransferRequestFhsaComponent} from './modules/transfer-request/transfer-request-fhsa.component';
import {ReviewSuccessorComponent} from './modules/review/tools/review-successor.component';
import {PasswordStrengthComponent} from './modules/layout/modal/password/password-strength/password-strength.component';
import {NameFormatDirective} from './components/directives/name-format.directive';
import {TransmissionAttachmentsLbaComponent} from './modules/transmission/transmission-attachments-lba.component';
import {InputFileFieldComponent} from './modules/layout/questionnaire/fields/input-file-field/input-file-field.component';
import {PrefixErrorPipe} from './components/pipes/prefix-error.pipe';
import {OrdinalPipe} from './components/pipes/ordinal.pipe';
import {
    TransmissionAttachmentsLbaCaisseComponent
} from "./modules/transmission/transmission-attachments-lba-caisse.component";

@NgModule({
    entryComponents: [
        LayoutComponent,
        QuestionnaireComponent,
        LoginComponent,
        PageNotFoundComponent,
        AccountsChoiceComponent,
        AccountsChoiceUpdateComponent,
        AccountHolderInformationComponent,
        AccountHolderInformationAttorneyComponent,
        AccountHolderInformationSecondChildComponent,
        AccountHolderInformationChildRefComponent,
        AccountHolderInformationChildEsQualitesComponent,
        AccountHolderInformationCuratorshipComponent,
        AccountHolderInformationDeceasedComponent,
        AccountHolderInformationGuardianshipComponent,
        AccountHolderInformationIppComponent,
        AssociationRelatedQuestionsComponent,
        AttorneyComponent,
        BeginComponent,
        DefaultBeginComponent,
        BranchBeginComponent,
        EntitiesQuestionsComponent,
        BeneficiaryRrspComponent,
        BeneficiaryRespComponent,
        BeneficiaryTfsaComponent,
        BeneficiaryFhsaComponent,
        BranchErrorComponent,
        BrokerageAccountsComponent,
        ClientTypeComponent,
        ClubRelatedQuestionsComponent,
        ConfirmationComponent,
        ConfirmationCaisseComponent,
        ConsentsComponent,
        SpecificConsentsComponent,
        CooperativeRelatedQuestionsComponent,
        CorporationRelatedQuestionsComponent,
        CoUniqueRelatedQuestionsComponent,
        EnfantEsQualitesRelatedQuestionsComponent,
        EnfantRefRelatedQuestionsComponent,
        FiducieRelatedQuestionsComponent,
        FinancialInformationComponent,
        InvestmentObjectivesAndRiskFactorsComponent,
        IppRelatedQuestionsComponent,
        JointRelatedQuestionsComponent,
        OccupationComponent,
        OccupationComponentSecondHolderComponent,
        OccupationComponentThirdHolderComponent,
        BrokerageAccountsComponentSecondHolderComponent,
        BrokerageAccountsComponentThirdHolderComponent,
        OptionAccountComponent,
        OptionsAccountComponent,
        OsblRelatedQuestionsComponent,
        PersonalInformationComponent,
        UpdatePersonalInformationComponent,
        PersonalInformationSecondHolderComponent,
        PersonalInformationThirdHolderComponent,
        PlatformChoiceComponent,
        ResetPasswordComponent,
        ResidentialAddressComponent,
        ResidentialAddressSecondHolderComponent,
        ResidentialAddressThirdHolderComponent,
        ReviewPersonalAccountComponent,
        ReviewOtherAccountComponent,
        ReviewClientIdentityComponent,
        ReviewUpdateAccountComponent,
        ReviewUpdatePageComponent,
        ReviewClientIdentitySecondHolderComponent,
        ReviewUpdateComponent,
        SencRelatedQuestionsComponent,
        ShareholderComponent,
        SocCommRelatedQuestionsComponent,
        SpouseInformationComponent,
        ReferredAccountComponent,
        SubmissionComponent,
        AddAccountSubmissionComponent,
        SuccessionRelatedQuestionsComponent,
        TransferRequestChoiceComponent,
        TransferRequestNonRegisteredComponent,
        TransferRequestRrspComponent,
        TransferRequestTfsaComponent,
        TransferRequestFhsaComponent,
        TransferRequestRespComponent,
        TransferRequestLiraComponent,
        TransferRequestRrifComponent,
        TransferRequestLifComponent,
        TransmissionAdditionalFormsComponent,
        TransmissionAttachmentsComponent,
        TransmissionAttachmentsLbaComponent,
        TransmissionAttachmentsLbaCaisseComponent,
        TransmissionChoiceComponent,
        TransmissionEsignComponent,
        TransmissionKitDownloadComponent,
        TypeOfManagementComponent,
        GouvOrgRelatedQuestionsComponent,
        AccountHolderInformationChildComponent,
    ],
    declarations: [
        AppComponent,
        DateFormatDirective,
        PercentFormatDirective,
        RemoveErrorDirective,
        MomentPipe,
        RequestOrderByPipe,
        FormatToSummaryQuestionPipe,
        SummarySubtitleValuesPipe,
        SummaryStepLabelPipe,
        TruncatePipe,
        PrefixErrorPipe,
        OrdinalPipe,
        AsideComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        TrashComponent,
        PageNotFoundComponent,
        LayoutComponent,
        QuestionnaireComponent,
        QuestionnaireBtnComponent,
        IframeRefreshDirective,
        PhoneFormatDirective,
        SinFormatDirective,
        ZipCodeFormatDirective,
        IntegerFormatDirective,
        CurrencyFormatDirective,
        InterestRateFormatDirective,
        DecimalCurrencyFormatDirective,
        SecurityQuantityFormatDirective,
        AccountsChoiceComponent,
        AccountsChoiceUpdateComponent,
        EntitiesQuestionsComponent,
        BaseInformationComponent,
        BaseHoldingAccountRelatedQuestionsComponent,
        BaseAddSpouseRelatedQuestionsComponent,
        PersonalInformationComponent,
        UpdatePersonalInformationComponent,
        PersonalInformationSecondHolderComponent,
        PersonalInformationThirdHolderComponent,
        ResidentialAddressComponent,
        ResidentialAddressSecondHolderComponent,
        ResidentialAddressThirdHolderComponent,
        OccupationComponent,
        OccupationComponentSecondHolderComponent,
        OccupationComponentThirdHolderComponent,
        BrokerageAccountsComponentSecondHolderComponent,
        BrokerageAccountsComponentThirdHolderComponent,
        BeneficiaryRrspComponent,
        FinancialInformationComponent,
        InvestmentObjectivesAndRiskFactorsComponent,
        BrokerageAccountsComponent,
        ReviewPersonalAccountComponent,
        ReviewOtherAccountComponent,
        ReviewFieldComponent,
        ReviewSubFieldComponent,
        ReviewHeaderComponent,
        ReviewSubSectionComponent,
        ReviewSectionComponent,
        ReviewBlockComponent,
        ReviewValueComponent,
        ReviewFieldListComponent,
        ReviewFieldQuestionComponent,
        ReviewBeneficiaryComponent,
        ReviewSuccessorComponent,
        ReviewTransferComponent,
        ReviewUpdateComponent,
        ConsentsComponent,
        SpecificConsentsComponent,
        SpouseInformationComponent,
        ReferredAccountComponent,
        AttorneyComponent,
        ClientTypeComponent,
        BeginComponent,
        DefaultBeginComponent,
        BranchBeginComponent,
        PlatformChoiceComponent,
        TransmissionAdditionalFormsComponent,
        TransmissionAttachmentsComponent,
        TransmissionChoiceComponent,
        TransmissionEsignComponent,
        TransmissionKitDownloadComponent,
        ConfirmationComponent,
        ConfirmationCaisseComponent,
        BaseNavComponent,
        BeneficiaryTfsaComponent,
        BeneficiaryFhsaComponent,
        OptionsAccountComponent,
        ShareholderComponent,
        AsidePreparationComponent,
        AsideConsultAllComponent,
        AsideCaisseComponent,
        ErrorComponent,
        ErrorListComponent,
        AddressFieldDirective,
        StreetCompleterDirective,
        AddressFormComponent,
        NumericFormatDirective,
        NameFormatDirective,
        HelpComponent,
        HelpWarningComponent,
        BeneficiaryComponent,
        BeneficiaryCeliAppComponent,
        SuccessorComponent,
        BeneficiaryPleinExComponent,
        BaseTransferComponent,
        TransferRequestGenericTypeComponent,
        TransferRequestChoiceComponent,
        TransferRequestLifComponent,
        TransferRequestLiraComponent,
        TransferRequestNonRegisteredComponent,
        TransferRequestRespComponent,
        TransferRequestRrifComponent,
        TransferRequestRrspComponent,
        TransferRequestTfsaComponent,
        TransferRequestFhsaComponent,
        TransferFormComponent,
        AccountFormComponent,
        ReviewClientIdentityComponent,
        ReviewUpdateAccountComponent,
        ReviewUpdatePageComponent,
        ReviewClientIdentitySecondHolderComponent,
        CountryCompleterDirective,
        BaseAccountTypeRelatedQuestionsComponent,
        JointRelatedQuestionsComponent,
        AssociationRelatedQuestionsComponent,
        ClubRelatedQuestionsComponent,
        CooperativeRelatedQuestionsComponent,
        CorporationRelatedQuestionsComponent,
        CoUniqueRelatedQuestionsComponent,
        OsblRelatedQuestionsComponent,
        SencRelatedQuestionsComponent,
        EnfantRefRelatedQuestionsComponent,
        EnfantEsQualitesRelatedQuestionsComponent,
        SocCommRelatedQuestionsComponent,
        GouvOrgRelatedQuestionsComponent,
        FiducieRelatedQuestionsComponent,
        SuccessionRelatedQuestionsComponent,
        ModalPasswordComponent,
        ResetPasswordComponent,
        ProfileAccountOpeningRequestListComponent,
        TypeOfManagementComponent,
        ModalPersonalInformationSecondAccountholderSpouseComponent,
        ModalAddEntityComponent,
        IppRelatedQuestionsComponent,
        AccountHolderInformationComponent,
        ModalPdfComponent,
        AccountHolderInformationDeceasedComponent,
        AccountHolderInformationSecondChildComponent,
        AccountHolderInformationChildRefComponent,
        AccountHolderInformationChildEsQualitesComponent,
        AccountHolderInformationGuardianshipComponent,
        AccountHolderInformationCuratorshipComponent,
        AccountHolderInformationAttorneyComponent,
        AccountHolderInformationIppComponent,
        OdcFieldComponent,
        HelpInsiderComponent,
        BeneficiaryRespComponent,
        OptionAccountComponent,
        SubmissionComponent,
        AddAccountSubmissionComponent,
        AccountHolderInformationChildComponent,
        BranchErrorComponent,
        BranchFieldComponent,
        RecaptchaComponent,
        ReviewUpdateAccountTableComponent,
        ReviewUpdateAccountTableCellComponent,
        ReviewUpdateAccountHeaderComponent,
        ODCFormBindingDirective,
        ProvinceResidentQuestionComponent,
        ModalPdfMultiLangComponent,
        PasswordStrengthComponent,
        TransmissionAttachmentsLbaComponent,
        TransmissionAttachmentsLbaCaisseComponent,
        InputFileFieldComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot([], {useHash: true, onSameUrlNavigation: 'reload'}),
        TranslateModule.forRoot()

    ],
    providers: [
        Title,
        TrashService,
        AddressCompleterService,
        AttachedDocumentService,
        NavigationService,
        FormService,
        FormsComparisonService,
        FormBuilder,
        ModalService,
        SessionService,
        TokenService,
        RecoveryService,
        RecoveryRedirectService,
        ProfileService,
        RequestFollowUpService,
        ListModelService,
        FileValidatorService,
        TranslateSortService,
        TranslatePipe,
        FormatToSummaryQuestionPipe,
        SummarySubtitleValuesPipe,
        SummaryStepLabelPipe,
        CountryCompleterService,
        AccountRulesService,
        WindowRefService,
        OssQueryService,
        {provide: ODC_CONFIG, useValue: OdcConfig},
        {provide: HTTP_INTERCEPTORS, useClass: InterceptedHttp, multi: true}
    ],
    exports: [
        CommonModule,
        AddressFieldDirective,
        StreetCompleterDirective,
        AddressFormComponent,
        ErrorComponent,
        ErrorListComponent,
        HelpComponent,
        HelpWarningComponent,
        HelpInsiderComponent,
        MomentPipe,
        RequestOrderByPipe,
        OdcFieldComponent,
        QuestionnaireBtnComponent,
        ReactiveFormsModule,
        RouterTestingModule,
        TranslatePipe,
        FormatToSummaryQuestionPipe,
        SummarySubtitleValuesPipe,
        SummaryStepLabelPipe,
        ODCFormBindingDirective,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

