export class RecoveryBean {

    email: string;
    password: string;
    reCaptchaResponse: string;

    constructor(obj?: any) {

        this.email = obj && obj.email || null;
        this.password = obj && obj.password || null;
        this.reCaptchaResponse = obj && obj.reCaptchaResponse || null;
    }
}
