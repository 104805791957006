import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {WebException} from '../models/index';
import {TokenService} from '../services/token.service';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class InterceptedHttp implements HttpInterceptor {

    constructor(private tokenService: TokenService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.url.includes('checkFileContent') ?
            request.clone({
                setParams: this.setSessionToken()
            }) :
            request.clone({
                setHeaders: this.addHeaders(),
                setParams: this.setSessionToken()
            });

        return next.handle(request).pipe(
            catchError((exception) => {

                let webException = null;

                if (exception && exception.error) {

                    webException = new WebException(exception.error, exception.status);
                } else {

                    const error = {
                        listError: []
                    };

                    if (exception.status === 401) {

                        error.listError.push('ERROR_UNAUTHORIZED');
                    } else if (exception.status === 403) {

                        error.listError.push('ERROR_FROBIDDEN');
                    } else {

                        error.listError.push('ERROR_TECHNICAL_PROBLEMS');
                    }

                    webException = new WebException(error, exception.status);
                }

                return observableThrowError(webException);
            })
        );
    }


    private addHeaders() {

        const isIE11Max = /msie\s|trident\//i.test(window.navigator.userAgent);

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Cache-Control': 'private, no-cache, no-store',
            Pragma: 'private, no-cache, no-store'
        };

        if (isIE11Max) {

            Object.assign(headers, {'If-Modified-Since': '0'});
        }

        return headers;
    }

    private setSessionToken() {

        const httpParams = {};
        const token = this.tokenService.getToken();

        if (token) {
            document.cookie = this.tokenService.TOKEN_NAME + '=' + token;
        }

        return httpParams;
    }
}
