import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {TokenService} from './token.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';

@Injectable()
export class SessionService {

    constructor(private http: HttpClient,
                private tokenService: TokenService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

    }

    public getNewToken(): Observable<any> {

        return this.http.get('api/session/begin', {
            params: {
                username: this.config.AGENT_DISNAT_USERNAME,
                // Origine pour la création
                origin: this.getOrigin(),
                // Source pour la reprise
                src: this.config.SOURCE
            }
        });
    }

    public getOrigin(): string {
        // Source pour la reprise
        if (this.config.SOURCE) {
            return this.config.SOURCE;
        }

        // Origine pour la création
        if (this.fromInternalOrigin() || this.fromInternalUpdateOrigin()) {
            return 'INTERNAL';
        } else if (this.config.CODE_USAGER !== '' && this.config.INST_ORIG !== '' && this.config.TRANSIT_ORIG !== '') {
            return 'CAISSE';
        } else {
            return 'WEB';
        }
    }

    private fromInternalOrigin(): boolean {
        return this.config.AGENT_ID !== '' && (this.config.AGENT_DISNAT_USERNAME !== '' || this.config.AGENT_DISNAT_USERNAME === undefined);
    }

    private fromInternalUpdateOrigin(): boolean {
        return this.config.IS_UPDATE_ACCOUNT && (this.config.AGENT_DISNAT_USERNAME !== '' || this.config.AGENT_DISNAT_USERNAME === undefined);
    }

    public beginSession(token: string) {

        if (token) {

            this.tokenService.setToken(token);
        }
    }

    public checkSession(): Observable<any> {

        return this.http.get('api/session/heartbeat');
    }

    public refreshSession(): Observable<any> {

        return this.http.get('api/session/refresh', {withCredentials: true});
    }

    public endSession(): void {

        this.tokenService.setToken(null);
        this.resetParameters();
    }

    public resetParameters(): void {

        this.config.FROM_EXISTING_REQUEST = '';
    }

    public hasSessionInProgress(): boolean {

        return !!this.tokenService.getToken();
    }
}
