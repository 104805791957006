export const I18N_NAV_MESSAGES_EN: any = {
    ODC_NAV_STEP: 'Step {{step}}',
    ODC_NAV_OF: 'of {{size}}',

    ODC_NAV_LOGIN_PATH: 'login',
    ODC_NAV_RESET_PASSWORD_PATH: 'reset-password',
    ODC_NAV_BRANCH_PATH: 'branch',
    ODC_NAV_ERROR_BRANCH_PATH: 'branch/branch-error',

    ODC_NAV_STEP_PREPARATION: 'Preparation',
    ODC_NAV_STEP_PREPARATION_BEGIN: 'Begin',
    ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH: 'begin',
    ODC_NAV_STEP_PREPARATION_CLIENT_TYPE: 'Client type',
    ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH: 'client-type',
    ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE: 'Transactional platform',
    ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE_PERSONAL_ACCOUNT_PATH: 'personal-account-transactional-platform',

    ODC_NAV_STEP_ACCOUNTS_CHOICE: 'Account Type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS: 'Account Type Related Questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_PATH: 'joint-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PATH: 'association-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PATH: 'club-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PATH: 'cooperative-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PATH: 'corporation-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PATH: 'co-unique-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PATH: 'in-trust-minor-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PATH: 'held-in-name-minor-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PATH: 'trust-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PATH: 'osbl-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PATH: 'senc-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PATH: 'limited-partnership-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PATH: 'estate-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT: 'Type of management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-type-of-management',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE: 'Account Type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_PATH: 'personal-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PATH: 'joint-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_PATH: 'other-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS: 'Entities',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PATH: 'entities-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PLEIN_EX_PATH: 'plein-ex-entities-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_GPD_PATH: 'gpd-entities-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_SSD_PATH: 'ssd-entities-questions',

    ODC_NAV_STEP_ACCOUNTS_CHOICE_ADD_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-add-account-account-type',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ADD_ACCOUNT_SSD_PATH: 'ssd-add-account-account-type',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_ADD_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-add-account-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_ADD_ACCOUNT_SSD_PATH: 'ssd-add-account-information-client',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_ADD_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-add-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_ADD_ACCOUNT_SSD_PATH: 'ssd-add-account-transmission-kit-download',
    ODC_NAV_STEP_SUBMIT_ADD_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-add-account-submission',
    ODC_NAV_STEP_SUBMIT_ADD_ACCOUNT_SSD_PATH: 'ssd-add-account-submission',

    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-association-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_GPD_PATH: 'gpd-association-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_SSD_PATH: 'ssd-association-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-club-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_GPD_PATH: 'gpd-club-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_SSD_PATH: 'ssd-club-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-cooperative-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_GPD_PATH: 'gpd-cooperative-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_SSD_PATH: 'ssd-cooperative-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-corporation-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_GPD_PATH: 'gpd-corporation-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_SSD_PATH: 'ssd-corporation-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-co-unique-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_GPD_PATH: 'gpd-co-unique-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_SSD_PATH: 'ssd-co-unique-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-in-trust-minor-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_GPD_PATH: 'gpd-in-trust-minor-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_SSD_PATH: 'ssd-in-trust-minor-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-held-in-name-minor-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_GPD_PATH: 'gpd-held-in-name-minor-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_SSD_PATH: 'ssd-held-in-name-minor-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-trust-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_GPD_PATH: 'gpd-trust-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_SSD_PATH: 'ssd-trust-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-ipp-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_GPD_PATH: 'gpd-ipp-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_SSD_PATH: 'ssd-ipp-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-osbl-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_GPD_PATH: 'gpd-osbl-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_SSD_PATH: 'ssd-osbl-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-senc-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_GPD_PATH: 'gpd-senc-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_SSD_PATH: 'ssd-senc-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-limited-partnership-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_GPD_PATH: 'gpd-limited-partnership-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_SSD_PATH: 'ssd-limited-partnership-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-gouv-organisation-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_GPD_PATH: 'gpd-gouv-organisation-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_SSD_PATH: 'ssd-gouv-organisation-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-estate-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_GPD_PATH: 'gpd-estate-account-type-related-questions',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_SSD_PATH: 'ssd-estate-account-type-related-questions',

    ODC_NAV_STEP_PERSONAL_INFORMATION: 'Personal Information',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT: 'Personal information of client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1: '1st accountholder - Information',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2: '2nd accountholder - Information',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3: '3rd accountholder - Information',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_PATH: 'personal-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_JOINT_ACCOUNT_PATH: 'joint-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'joint-account-personal-information-first-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-personal-information-first-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-personal-information-first-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'joint-account-personal-information-second-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-personal-information-second-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-personal-information-second-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH: 'joint-account-personal-information-third-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-personal-information-third-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-personal-information-third-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PATH: 'other-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS: 'Place of tax residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1: '1st accountholder - Residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2: '2nd accountholder - Residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3: '3rd accountholder - Residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_PATH: 'personal-account-personal-information-residential-address',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-personal-information-residential-address-first-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-personal-information-residential-address-first-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-personal-information-residential-address-first-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-personal-information-residential-address-second-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-personal-information-residential-address-second-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-personal-information-residential-address-second-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-personal-information-residential-address-third-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-personal-information-residential-address-third-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-personal-information-residential-address-third-holder',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-personal-information-residential-address',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-personal-information-residential-address',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-personal-information-residential-address',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-personal-information-residential-address',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-personal-information-residential-address',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-personal-information-residential-address',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-personal-information-residential-address',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_PLEIN_EX_PATH: 'plein-ex-other-account-attorney-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_GPD_PATH: 'gpd-other-account-attorney-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_SSD_PATH: 'ssd-other-account-attorney-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_PLEIN_EX_PATH: 'plein-ex-other-account-estate-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_GPD_PATH: 'gpd-other-account-estate-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_SSD_PATH: 'ssd-other-account-estate-personal-information-client',

    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH: 'plein-ex-other-account-child-personal-information-first-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_GPD_PATH: 'gpd-other-account-child-personal-information-first-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_SSD_PATH: 'ssd-other-account-child-personal-information-first-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH: 'plein-ex-other-account-child-personal-information-second-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_GPD_PATH: 'gpd-other-account-child-personal-information-second-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_SSD_PATH: 'ssd-other-account-child-personal-information-second-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_PLEIN_EX_PATH_1: 'plein-ex-other-account-child-residential-address-first-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_GPD_PATH_1: 'gpd-other-account-child-residential-address-first-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_SSD_PATH_1: 'ssd-other-account-child-residential-address-first-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_PLEIN_EX_PATH_2: 'plein-ex-other-account-child-residential-address-second-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_GPD_PATH_2: 'gpd-other-account-child-residential-address-second-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_SSD_PATH_2: 'ssd-other-account-child-residential-address-second-client',

    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_PLEIN_EX_PATH: 'plein-ex-other-account-child-ref-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_GPD_PATH: 'gpd-other-account-child-ref-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_SSD_PATH: 'ssd-other-account-child-ref-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_PLEIN_EX_PATH: 'plein-ex-other-account-child-es-qualites-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_GPD_PATH: 'gpd-other-account-child-es-qualites-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_SSD_PATH: 'ssd-other-account-child-es-qualites-personal-information-client',

    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_PLEIN_EX_PATH: 'plein-ex-other-account-guardianship-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_GPD_PATH: 'gpd-other-account-guardianship-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_SSD_PATH: 'ssd-other-account-guardianship-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_PLEIN_EX_PATH: 'plein-ex-other-account-curatorship-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_GPD_PATH: 'gpd-other-account-curatorship-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_SSD_PATH: 'ssd-other-account-curatorship-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_PLEIN_EX_PATH: 'plein-ex-other-account-ipp-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_GPD_PATH: 'gpd-other-account-ipp-personal-information-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_SSD_PATH: 'ssd-other-account-ipp-personal-information-client',

    ODC_NAV_STEP_OCCUPATION: 'Occupation',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION: 'Occupation and employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_PATH: 'personal-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-occupation-and-employer',
    ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_1: '1st accountholder',
    ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_2: '2nd accountholder',
    ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_3: '3rd accountholder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-occupation-and-employer-first-holder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-occupation-and-employer-first-holder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-occupation-and-employer-first-holder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-occupation-and-employer-second-holder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-occupation-and-employer-second-holder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-occupation-and-employer-second-holder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-occupation-and-employer-third-holder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-occupation-and-employer-third-holder',
    ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-occupation-and-employer-third-holder',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_OTHER_ACCOUNT_PATH: 'plein-ex-other-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_OTHER_ACCOUNT_PATH_GPD: 'gpd-other-account-occupation-and-employer',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_OTHER_ACCOUNT_PATH_SSD: 'ssd-other-account-occupation-and-employer',

    ODC_NAV_STEP_CIVIL_STATUS: 'Marital Status and Designation',
    ODC_NAV_STEP_CIVIL_STATUS_OTHER_ACCOUNT_PLEINEX: 'Marital Status and Designation',
    ODC_NAV_STEP_REFERENCE: 'Reference',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION: 'Spouse Information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_PATH: 'personal-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH: 'plein-ex-other-account-child-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_GPD_PATH: 'gpd-other-account-child-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SSD_PATH: 'ssd-other-account-child-civil-status-spouse-information',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RRSP: 'Beneficiary (RRSP)',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RRSP_PERSONAL_ACCOUNT_PATH: 'personal-account-civil-status-beneficiary-rrsp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_TFSA: 'Beneficiary (TFSA)',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_TFSA_PERSONAL_ACCOUNT_PATH: 'personal-account-civil-status-beneficiary-tfsa',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA: 'Designation of beneficiary(ies) (FHSA)',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PATH: 'personal-account-civil-status-beneficiary-fhsa',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-civil-status-beneficiary-fhsa',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-civil-status-beneficiary-fhsa',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-civil-status-beneficiary-fhsa',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP: 'Beneficiary (RESP)',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PATH: 'personal-account-civil-status-beneficiary-resp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-civil-status-beneficiary-resp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-civil-status-beneficiary-resp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-civil-status-beneficiary-resp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-civil-status-beneficiary-resp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-civil-status-beneficiary-resp',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY: 'Attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_PATH: 'personal-account-civil-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-civil-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-civil-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-civil-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-other-account-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_GPD_PATH: 'gpd-personal-other-account-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_SSD_PATH: 'ssd-personal-other-account-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-civil-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-civil-status-attorney',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-civil-status-attorney',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PLEIN_EX_PATH: 'Referred Account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_GPD_PATH: 'Referred Account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-civil-status-referred-account',
    ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-civil-status-referred-account',

    ODC_NAV_STEP_LEGAL: 'Regulatory Questions',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS: 'Brokerage accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_PATH: 'personal-account-legal-brokerage-accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-legal-brokerage-accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-legal-brokerage-accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-legal-brokerage-accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-legal-brokerage-accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-legal-brokerage-accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-legal-brokerage-accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-legal-brokerage-accounts',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-legal-brokerage-accounts-first-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-legal-brokerage-accounts-first-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-legal-brokerage-accounts-first-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-legal-brokerage-accounts-second-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-legal-brokerage-accounts-second-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-legal-brokerage-accounts-second-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1: '1st accountholder - Brokerage Accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2: '2nd accountholder - Brokerage Accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3: '3rd accountholder - Brokerage Accounts',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-legal-brokerage-accounts-first-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-legal-brokerage-accounts-first-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-legal-brokerage-accounts-first-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-legal-brokerage-accounts-second-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-legal-brokerage-accounts-second-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-legal-brokerage-accounts-second-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-legal-brokerage-accounts-third-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-legal-brokerage-accounts-third-holder',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-legal-brokerage-accounts-third-holder',
    ODC_NAV_STEP_LEGAL_OPTIONS_ACCOUNT: 'Options account',
    ODC_NAV_STEP_LEGAL_OPTIONS_ACCOUNT_PERSONAL_ACCOUNT_PATH: 'personal-account-legal-options-account',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER: 'Communication with beneficial owners of securities',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_GPD: 'Communication with Beneficial Owners',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_PATH: 'personal-account-legal-shareholder',

    ODC_NAV_STEP_FINANCIAL_INFORMATION: 'Financial information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_PLEIN_EX: 'Investment Objectives and Risk factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_GPD: 'Investment Objectives and Risk factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_SSD: 'Investment Objectives and Risk factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION: 'Financial information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS: 'Investment Objectives and Risk Factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_PATH: 'personal-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-financial-information',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-investment-objectives-and-risk-factors',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT: 'Option Account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-option-account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-option-account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-option-account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-option-account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-option-account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-option-account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-option-account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-option-account',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-option-account',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-legal-shareholder',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-legal-shareholder',

    ODC_NAV_STEP_TRANSFER_REQUEST: 'Transfer Request',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE: 'Transfer request',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-choice',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-transfer-request',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-transfer-request',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-transfer-request',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CASH: 'Cash',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_MARGIN: 'Margin',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED: 'Non-Registered Account',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-non-registered',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-non-registered',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-non-registered',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP: 'RRSP',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-rrsp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-rrsp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-rrsp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA: 'TFSA',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-tfsa',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-tfsa',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-tfsa',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA: 'FHSA',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-fhsa',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-fhsa',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-fhsa',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RESP: 'RESP',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RESP_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-resp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RESP_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-resp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RESP_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-resp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA: 'LIRA',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-lira',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-lira',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-lira',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF: 'RRIF',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-rrif',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-rrif',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-rrif',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF: 'LIF',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF_PERSONAL_ACCOUNT_PATH: 'personal-account-transfer-request-lif',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF_JOINT_ACCOUNT_PATH: 'joint-account-transfer-request-lif',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF_OTHER_ACCOUNT_PATH: 'other-account-transfer-request-lif',

    ODC_NAV_STEP_REVIEW: 'Revision',
    ODC_NAV_STEP_REVIEW_REVIEW: 'Revision',
    ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT: 'Summary of changes',
    ODC_NAV_STEP_REVIEW_REVIEW_PERSONAL_ACCOUNT_PATH: 'personal-account-revision',
    ODC_NAV_STEP_REVIEW_REVIEW_JOINT_ACCOUNT_PATH: 'joint-account-revision',
    ODC_NAV_STEP_REVIEW_REVIEW_OTHER_ACCOUNT_PATH: 'other-account-revision',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY: 'Identity Verification',
    ODC_NAV_STEP_REVIEW_REVIEW_IDENTITY_CLIENT_1: '1st accountholder - Identity Verification',
    ODC_NAV_STEP_REVIEW_REVIEW_IDENTITY_CLIENT_2: '2nd accountholder - Identity Verification',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH: 'personal-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH_PLEIN_EX_PATH: 'plein-ex-personal-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH_GPD_PATH: 'gpd-personal-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH_SSD_PATH: 'ssd-personal-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-revision-client-identity-first-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-revision-client-identity-first-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-revision-client-identity-first-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-revision-client-identity-second-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-revision-client-identity-second-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-revision-client-identity-second-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_JOINT_ACCOUNT_PATH: 'joint-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_PATH: 'other-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-revision-client-identity',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-revision-client-identity-first-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-revision-client-identity-first-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-revision-client-identity-first-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-revision-client-identity-second-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-revision-client-identity-second-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-revision-client-identity-second-accountholder',
    ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_PATH: 'update-personal-account-summary',
    ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-update-personal-account-summary',
    ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_GPD_PATH: 'gpd-update-personal-account-summary',
    ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_SSD_PATH: 'ssd-ex-update-personal-account-summary',
    ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT_PATH: 'update-summary',

    ODC_NAV_STEP_CONSENT: 'Agreements',
    ODC_NAV_STEP_CONSENT_CONSENT: 'Agreements',
    ODC_NAV_STEP_CONSENT_CONSENT_PERSONAL_ACCOUNT_PATH: 'personal-account-agreements',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT: 'Specific consent',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PATH: 'personal-account-specific-consent',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-specific-consent',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-specific-consent',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_PATH: 'plein-ex-update-personal-account-specific-consent',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-specific-consent',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-specific-consent',

    ODC_NAV_STEP_TRANSMISSION: 'Transmission',
    ODC_NAV_STEP_PRINT: 'Print',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE: 'Delivery Method',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH: 'personal-account-transmission-choice',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_JOINT_ACCOUNT_PATH: 'joint-account-transmission-choice',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_OTHER_ACCOUNT_PATH: 'other-account-transmission-choice',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD: 'Kit Download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH: 'personal-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PATH: 'joint-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PATH: 'other-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS: 'Attachments',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_PATH: 'personal-account-transmission-attachments',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_LBA_PATH: 'personal-account-transmission-attachments-lba',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_LBA_CAISSE_PATH: 'personal-account-transmission-attachments-lba-caisse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_JOINT_ACCOUNT_PATH: 'joint-account-transmission-attachments',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_OTHER_ACCOUNT_PATH: 'other-account-transmission-attachments',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS: 'Additional Forms',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_PERSONAL_ACCOUNT_PATH: 'personal-account-transmission-additional-forms',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_JOINT_ACCOUNT_PATH: 'joint-account-transmission-additional-forms',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_OTHER_ACCOUNT_PATH: 'other-account-transmission-additional-forms',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN: 'E-sign',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH: 'personal-account-transmission-esign',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_JOINT_ACCOUNT_PATH: 'joint-account-transmission-esign',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_OTHER_ACCOUNT_PATH: 'other-account-transmission-esign',

    ODC_NAV_STEP_SUBMIT: 'Submission',
    ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-personal-account-submission',
    ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-personal-account-submission',
    ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-personal-account-submission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-joint-account-submission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_GPD_PATH: 'gpd-joint-account-submission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_SSD_PATH: 'ssd-joint-account-submission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-submission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-submission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-submission',

    ODC_NAV_STEP_CONFIRMATION: 'Confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION: 'Confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_PERSONAL_ACCOUNT_PATH: 'personal-account-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_JOINT_ACCOUNT_PATH: 'joint-account-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_PATH: 'other-account-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_PERSONAL_ACCOUNT_PATH: 'personal-account-confirmation-caisse',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_JOINT_ACCOUNT_PATH: 'joint-account-confirmation-caisse',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_OTHER_ACCOUNT_PATH: 'other-account-confirmation-caisse',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-other-account-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_GPD_PATH: 'gpd-other-account-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_SSD_PATH: 'ssd-other-account-confirmation',

    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION: 'Account Holder',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1: '1st accountholder - Identification',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_2: '2nd accountholder - Identification',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_1: '1st-accountholder-residentia',

    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PATH: 'update-personal-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-personal-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-update-personal-account-transmission-kit-download',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-update-personal-account-transmission-kit-download',

    ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PATH: 'update-account-submission',
    ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-update-account-submission',
    ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_GPD_PATH: 'gpd-update-account-submission',
    ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_SSD_PATH: 'ssd-update-account-submission',
};
