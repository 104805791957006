<h1>
    <span *ngIf="!displayList">{{ 'ODC_RESET_PASSWORD_TITRE_PAGE' | translate }}</span>
    <span *ngIf="displayList">{{ 'ODC_LOGIN_TITRE_PAGE' | translate }}</span>
</h1>

<div class="row">

    <div class="col-md-16">
        <div class="panel panel-simple">
            <div id="questionnaire-content" class="panel-body" tabindex="0">

                <form [formGroup]="form" ngForm novalidate (submit)="onSubmit()" [class.hidden]="displayList">
                    <div *ngIf="!displayList">

                        <p *ngIf="form.get('passwords').enabled" class="m-b-4"><b>{{'PROFIL_PASSWORD_EMAIL_SENDED' |
                            translate}}</b></p>

                        <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>
                        <div *ngIf="errMsg" class="bloc-erreur-generique m-b-4" tabindex="0">
                            {{ errMsg | translate }}
                        </div>

                        <app-odc-field
                            *ngIf="form.get('email').enabled"
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_RESET_PASSWORD_EMAIL' | translate"
                            [id]="'email'"
                            [name]="'email'"
                            [path]="'email'"
                            [binding]="null"
                            [attr]="{maxlength: 64}"
                        ></app-odc-field>

                        <div *ngIf="form.get('question').enabled" formGroupName="question">

                            <app-odc-field
                                [autoComplete]="'off'"
                                [type]="constants.FIELD_TYPES.TEXT"
                                [form]="form.get('question')"
                                [submitted]="isFormSubmitted()"
                                [label]="getQuestionSecuriteLabel()"
                                [id]="'reply'"
                                [name]="'reply'"
                                [path]="'reply'"
                                [binding]="null"
                                [attr]="{maxlength: 64}"
                            ></app-odc-field>
                        </div>

                        <div *ngIf="form.get('passwords').enabled" formGroupName="passwords">

                            <app-odc-field
                                [type]="constants.FIELD_TYPES.PASSWORD"
                                [form]="form.get('passwords')"
                                [submitted]="isFormSubmitted()"
                                [label]="'PROFIL_OLD_PASSWORD' | translate"
                                [id]="'oldPassword'"
                                [name]="'oldPassword'"
                                [path]="'oldPassword'"
                                [binding]="null"
                                [attr]="{maxlength: 32}"
                            ></app-odc-field>

                            <app-odc-field
                                [type]="constants.FIELD_TYPES.PASSWORD"
                                [form]="form.get('passwords')"
                                [submitted]="isFormSubmitted()"
                                [label]="'PROFIL_NEW_PASSWORD' | translate"
                                [id]="'newPassword'"
                                [name]="'newPassword'"
                                [path]="'newPassword'"
                                [pathPasswordStrengthIndicator]="'passwordStrength'"
                                [binding]="null"
                                [attr]="{maxlength: 32}"
                            ></app-odc-field>

                            <app-odc-field
                                [type]="constants.FIELD_TYPES.PASSWORD"
                                [form]="form.get('passwords')"
                                [submitted]="isFormSubmitted()"
                                [label]="'PROFIL_CONFIRM_PASSWORD' | translate"
                                [id]="'confirmNewPassword'"
                                [name]="'confirmNewPassword'"
                                [path]="'confirmNewPassword'"
                                [binding]="null"
                                [attr]="{maxlength: 32}"
                            ></app-odc-field>

                        </div>

                        <div class="btn-resp btn-resp-1colonne-inline m-t-4">
                            <div class="btn-resp-col btn-resp-demi">
                                <button *ngIf="displayBtnContinue"
                                        type="submit"
                                        class="btn btn-primary loadable"
                                        [class.loading]="loading"
                                        [attr.disabled]="getBtnContinueDisabledValue()">
                                    {{ 'BTN_CONTINUE' | translate }}
                                </button>
                                <button *ngIf="errMsg == 'TEMPORARY_PASSWORD_EXPIRED'"
                                        type="button"
                                        class="btn btn-link"
                                        (click)="modalService.emitResetPassword()"
                                        [innerHTML]="'PROFIL_PASSWORD_FORGOT_LINK' | translate"></button>
                            </div>
                        </div>

                    </div>

                </form>
                <br>
                <div *ngIf="showRecaptcha()">
                    <app-recatpcha [langueChange]="translate.onLangChange"
                                   [langue]="translate.currentLang"></app-recatpcha>
                </div>

                <app-profile-account-opening-request-list></app-profile-account-opening-request-list>

            </div>
        </div>
    </div>

    <div class="col-md-8">
        <app-aside></app-aside>
    </div>

</div>
