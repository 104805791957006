import {VmdConstants} from '../constants/vmd-constants';

export class Functions {
    static isSpecificClientTypes(aClientType: string, ...cltTypes: string[]): boolean {

        return cltTypes.indexOf(aClientType) !== -1;
    }

    static listHasValue(list: { itemValue: string, itemLabel: string }[], value: string): boolean {

        return list.find(item => item.itemValue === value) != null;
    }

    static stringListHasValue(list: string[], value: string): boolean {

        return list.find(item => item === value) != null;
    }

    static filterList(list: { itemValue: string, itemLabel: string }[], ...filteredValues: string[]): { itemValue: string, itemLabel: string }[] {

        return list.filter(item => !this.stringListHasValue(filteredValues, item.itemValue));
    }

    static getUrlParameter(name): string {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(location.search);
        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    static isNullOrEmpty(name): boolean {
        return !name || !name.trim();
    }

    static initCountryAddress(value: any) {
        if (value === null || typeof value === 'undefined') {
            return VmdConstants.COUNTRIES.CANADA;
        }

        return value;
    }

    static getLanguage(language): string {

        if (this.isNullOrEmpty(language)) {

            return VmdConstants.LANG_FR;

        } else if (VmdConstants.LANG_FR === language.toLowerCase() || VmdConstants.LANG_EN === language.toLowerCase()) {

            return language.toLowerCase();
        }

        return VmdConstants.LANG_FR;
    }

    static initByLength(value: any, length: number) {
        if (value === null || typeof value === 'undefined') {
            return null;
        }

        return value.substr(0, length);
    }

    static isStatusInjection(value: string): boolean {

        return value === VmdConstants.REQUEST_STATUS_APPROVAL ||
            value === VmdConstants.REQUEST_STATUS_AUTHORIZE ||
            value === VmdConstants.REQUEST_STATUS_FORCED ||
            value === VmdConstants.REQUEST_STATUS_AUTO ||
            value === VmdConstants.REQUEST_STATUS_INJECTED ||
            value === VmdConstants.REQUEST_STATUS_PROCESS ||
            value === VmdConstants.REQUEST_STATUS_READY ||
            value === VmdConstants.REQUEST_STATUS_REJECTED ||
            value === VmdConstants.REQUEST_STATUS_TOCOMPLETE ||
            value === VmdConstants.REQUEST_STATUS_VALIDATE ||
            value === VmdConstants.REQUEST_STATUS_FINALIZED ||
            value === VmdConstants.REQUEST_STATUS_MANUAL ||
            value === VmdConstants.REQUEST_STATUS_DECLINED ||
            value === VmdConstants.REQUEST_STATUS_CANCELLED;
    }

    static initMgntType(mgntType: any, requestStatus: any): string {
        if (mgntType === null || typeof mgntType === 'undefined'
            || requestStatus === null || typeof requestStatus === 'undefined') {
            return null;
        }

        return (mgntType === VmdConstants.MGNT_TYPE_ASSET) && !this.isStatusInjection(requestStatus) ? null : mgntType;
    }
}
