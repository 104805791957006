import {Routes} from '@angular/router';
import {LayoutComponent} from './modules/layout/layout.component';
import {QuestionnaireComponent} from './modules/layout/questionnaire/questionnaire.component';
import {LoginComponent} from './modules/login/login.component';
import {PageNotFoundComponent} from './modules/page-not-found/page-not-found.component';
import {ODC_ROUTES_CONFIG} from './app.routes';
import {I18N_NAV_MESSAGES_FR} from './components/i18n/nav-messages_fr';
import {I18N_NAV_MESSAGES_EN} from './components/i18n/nav-messages_en';
import {Component} from '@angular/core';
import {BeginComponent} from './modules/prepare/begin.component';
import {ClientTypeComponent} from './modules/prepare/client-type.component';
import {PlatformChoiceComponent} from './modules/prepare/platform-choice.component';
import {AccountsChoiceComponent} from './modules/accounts-choice/accounts-choice.component';
import {PersonalInformationComponent} from './modules/personal-information/personal-information.component';
import {ResidentialAddressComponent} from './modules/personal-information/residential-address.component';
import {OccupationComponent} from './modules/occupation/occupation.component';
import {SpouseInformationComponent} from './modules/civil-status/spouse-information.component';
import {ReferredAccountComponent} from './modules/civil-status/referred-account.component';
import {BeneficiaryRrspComponent} from './modules/civil-status/beneficiary-rrsp.component';
import {BeneficiaryTfsaComponent} from './modules/civil-status/beneficiary-tfsa.component';
import {BeneficiaryFhsaComponent} from './modules/civil-status/beneficiary-fhsa.component';
import {BeneficiaryRespComponent} from './modules/civil-status/beneficiary-resp.component';
import {AttorneyComponent} from './modules/civil-status/attorney.component';
import {BrokerageAccountsComponent} from './modules/legal/brokerage-accounts.component';
import {OptionsAccountComponent} from './modules/legal/options-account.component';
import {ShareholderComponent} from './modules/legal/shareholder.component';
import {FinancialInformationComponent} from './modules/financial-information/financial-information.component';
import {ReviewPersonalAccountComponent} from './modules/review/review-personal-account.component';
import {ReviewOtherAccountComponent} from './modules/review/review-other-account.component';
import {ConsentsComponent} from './modules/consents/consents.component';
import {SpecificConsentsComponent} from './modules/consents/specific-consents.component';
import {TransmissionChoiceComponent} from './modules/transmission/transmission-choice.component';
import {ConfirmationComponent} from './modules/confirmation/confirmation.component';
import {ConfirmationCaisseComponent} from './modules/confirmation/confirmation-caisse.component';
import {TransferRequestChoiceComponent} from './modules/transfer-request/transfer-request-choice.component';
import {TransferRequestNonRegisteredComponent} from './modules/transfer-request/transfer-request-non-registered.component';
import {TransferRequestRrspComponent} from './modules/transfer-request/transfer-request-rrsp.component';
import {TransferRequestTfsaComponent} from './modules/transfer-request/transfer-request-tfsa.component';
import {TransferRequestRespComponent} from './modules/transfer-request/transfer-request-resp.component';
import {TransferRequestLiraComponent} from './modules/transfer-request/transfer-request-lira.component';
import {TransferRequestRrifComponent} from './modules/transfer-request/transfer-request-rrif.component';
import {TransferRequestLifComponent} from './modules/transfer-request/transfer-request-lif.component';
import {TransmissionAdditionalFormsComponent} from './modules/transmission/transmission-additional-forms.component';
import {TransmissionAttachmentsComponent} from './modules/transmission/transmission-attachments.component';
import {TransmissionEsignComponent} from './modules/transmission/transmission-esign.component';
import {TransmissionKitDownloadComponent} from './modules/transmission/transmission-kit-download.component';
import {ReviewClientIdentityComponent} from './modules/review/review-client-identity.component';
import {ReviewUpdateAccountComponent} from './modules/review/update-account/review-update-account.component';
import {JointRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/joint-related-questions.component';
import {AssociationRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/association-related-questions.component';
import {ClubRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/club-related-questions.component';
import {CooperativeRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/cooperative-related-questions.component';
import {CorporationRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/corporation-related-questions.component';
import {CoUniqueRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/co-unique-related-questions.component';
import {OsblRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/osbl-related-questions.component';
import {SencRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/senc-related-questions.component';
import {
    EnfantEsQualitesRelatedQuestionsComponent
} from './modules/accounts-choice/account-type-related-questions/enfant-es-qualites-related-questions.component';
import {EnfantRefRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/enfant-ref-related-questions.component';
import {SocCommRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/soc-comm-related-questions.component';
import {FiducieRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/fiducie-related-questions.component';
import {SuccessionRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/succession-related-questions.component';
import {ResetPasswordComponent} from './modules/login/reset-password.component';
import {TypeOfManagementComponent} from './modules/accounts-choice/type-of-management.component';
import {PersonalInformationThirdHolderComponent} from './modules/personal-information/personal-information-third-holder.component';
import {PersonalInformationSecondHolderComponent} from './modules/personal-information/personal-information-second-holder.component';
import {IppRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/ipp-related-questions.component';
import {AccountHolderInformationComponent} from './modules/personal-information/account-holder-information.component';
import {AccountHolderInformationDeceasedComponent} from './modules/personal-information/account-holder-information-deceased.component';
import {AccountHolderInformationGuardianshipComponent} from './modules/personal-information/account-holder-information-guardianship.component';
import {AccountHolderInformationCuratorshipComponent} from './modules/personal-information/account-holder-information-curatorship.component';
import {AccountHolderInformationAttorneyComponent} from './modules/personal-information/account-holder-information-attorney.component';
import {AccountHolderInformationIppComponent} from './modules/personal-information/account-holder-information-ipp.component';
import {NavigationService} from './components/services/navigation.service';
import {EntitiesQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/entities-questions.component';
import {InvestmentObjectivesAndRiskFactorsComponent} from './modules/financial-information/investment-objectives-and-risk-factors.component';
import {OptionAccountComponent} from './modules/financial-information/option-account.component';
import {SubmissionComponent} from './modules/submission/submission.component';
import {ResidentialAddressThirdHolderComponent} from './modules/personal-information/residential-address-third-holder.component';
import {ResidentialAddressSecondHolderComponent} from './modules/personal-information/residential-address-second-holder.component';
import {OccupationComponentSecondHolderComponent} from './modules/occupation/occupation-second-holder.component';
import {OccupationComponentThirdHolderComponent} from './modules/occupation/occupation-third-holder.component';
import {BrokerageAccountsComponentSecondHolderComponent} from './modules/legal/brokerage-accounts-second-holder.component';
import {BrokerageAccountsComponentThirdHolderComponent} from './modules/legal/brokerage-accounts-third-holder.component';
import {ReviewClientIdentitySecondHolderComponent} from './modules/review/review-client-identity-second-holder.component';
import {GouvOrgRelatedQuestionsComponent} from './modules/accounts-choice/account-type-related-questions/gouv-org-related-questions.component';
import {AccountHolderInformationSecondChildComponent} from './modules/personal-information/account-holder-information-second-child.component';
import {AccountHolderInformationChildRefComponent} from './modules/personal-information/account-holder-information-child-ref.component';
import {
    AccountHolderInformationChildEsQualitesComponent
} from './modules/personal-information/account-holder-information-child-es-qualites.component';
import {BranchErrorComponent} from './modules/branch/branch-error.component';
import {Branch} from './components/models/Branch';
import {OdcConfig} from './odc-config';
import {Functions} from './components/utils/functions';
import {VmdConstants} from './components/constants/vmd-constants';
import {OssQuery} from './components/models/OssQuery';
import {AddAccountSubmissionComponent} from './modules/submission/add-account-submission.component';
import {AccountsChoiceUpdateComponent} from './modules/accounts-choice/update-account/accounts-choice-update.component';
import {UpdatePersonalInformationComponent} from './modules/personal-information/update-account/update-personal-information.component';
import {ReviewUpdatePageComponent} from './modules/consultation/update-account/review-update-page.component';
import {TransferRequestFhsaComponent} from './modules/transfer-request/transfer-request-fhsa.component';
import {TransmissionAttachmentsLbaComponent} from './modules/transmission/transmission-attachments-lba.component';
import {
    TransmissionAttachmentsLbaCaisseComponent
} from "./modules/transmission/transmission-attachments-lba-caisse.component";

declare let LANGUE: any;


/**
 * Use componentRouteMapping because of circular dependencies
 */
const componentRouteMapping = {
    AccountsChoiceComponent,
    AccountsChoiceUpdateComponent,
    AccountHolderInformationComponent,
    AccountHolderInformationAttorneyComponent,
    AccountHolderInformationSecondChildComponent,
    AccountHolderInformationChildRefComponent,
    AccountHolderInformationChildEsQualitesComponent,
    AccountHolderInformationCuratorshipComponent,
    AccountHolderInformationDeceasedComponent,
    AccountHolderInformationGuardianshipComponent,
    AccountHolderInformationIppComponent,
    AssociationRelatedQuestionsComponent,
    AttorneyComponent,
    BeginComponent,
    BeneficiaryRrspComponent,
    BeneficiaryTfsaComponent,
    BeneficiaryFhsaComponent,
    BeneficiaryRespComponent,
    BrokerageAccountsComponent,
    BrokerageAccountsComponentSecondHolderComponent,
    BrokerageAccountsComponentThirdHolderComponent,
    ClientTypeComponent,
    ClubRelatedQuestionsComponent,
    ConfirmationComponent,
    ConfirmationCaisseComponent,
    ConsentsComponent,
    SpecificConsentsComponent,
    CooperativeRelatedQuestionsComponent,
    CorporationRelatedQuestionsComponent,
    CoUniqueRelatedQuestionsComponent,
    EnfantEsQualitesRelatedQuestionsComponent,
    EnfantRefRelatedQuestionsComponent,
    EntitiesQuestionsComponent,
    FiducieRelatedQuestionsComponent,
    FinancialInformationComponent,
    GouvOrgRelatedQuestionsComponent,
    InvestmentObjectivesAndRiskFactorsComponent,
    IppRelatedQuestionsComponent,
    JointRelatedQuestionsComponent,
    OccupationComponent,
    OccupationComponentSecondHolderComponent,
    OccupationComponentThirdHolderComponent,
    OptionAccountComponent,
    OptionsAccountComponent,
    OsblRelatedQuestionsComponent,
    PersonalInformationComponent,
    UpdatePersonalInformationComponent,
    PersonalInformationSecondHolderComponent,
    PersonalInformationThirdHolderComponent,
    PlatformChoiceComponent,
    ReferredAccountComponent,
    ResidentialAddressComponent,
    ResidentialAddressSecondHolderComponent,
    ResidentialAddressThirdHolderComponent,
    ReviewPersonalAccountComponent,
    ReviewOtherAccountComponent,
    ReviewClientIdentityComponent,
    ReviewUpdateAccountComponent,
    ReviewClientIdentitySecondHolderComponent,
    SencRelatedQuestionsComponent,
    ShareholderComponent,
    SocCommRelatedQuestionsComponent,
    SpouseInformationComponent,
    SubmissionComponent,
    AddAccountSubmissionComponent,
    SuccessionRelatedQuestionsComponent,
    TransferRequestChoiceComponent,
    TransferRequestNonRegisteredComponent,
    TransferRequestRrspComponent,
    TransferRequestTfsaComponent,
    TransferRequestFhsaComponent,
    TransferRequestRespComponent,
    TransferRequestLiraComponent,
    TransferRequestRrifComponent,
    TransferRequestLifComponent,
    TransmissionAdditionalFormsComponent,
    TransmissionAttachmentsComponent,
    TransmissionAttachmentsLbaComponent,
    TransmissionAttachmentsLbaCaisseComponent,
    TransmissionChoiceComponent,
    TransmissionEsignComponent,
    TransmissionKitDownloadComponent,
    TypeOfManagementComponent
};

export class AppRoutesConfig {

    static getRoutesConfig(): Array<any> {

        const questionnaireChildrenRoutes = [];

        questionnaireChildrenRoutes.push({
            path: '',
            redirectTo: this.getDefaultRoute(),
            pathMatch: 'full'
        });

        for (const routesConfig of ODC_ROUTES_CONFIG) {
            if (routesConfig.key !== VmdConstants.ADD_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY) {

                for (const config of routesConfig.config) {
                    if (config.items && config.items.length) {
                        for (const item of config.items) {
                            const path: string = item.path;
                            const component: Component = componentRouteMapping[item.component];

                            questionnaireChildrenRoutes.push({
                                path: I18N_NAV_MESSAGES_FR[path],
                                component
                            });
                            questionnaireChildrenRoutes.push({
                                path: I18N_NAV_MESSAGES_EN[path],
                                component
                            });
                        }
                    }
                }
            }
        }

        return questionnaireChildrenRoutes;
    }

    private static getDefaultRoute(): string {
        const params = window.location.search;
        let isLangEn = OdcConfig.LANGUE === 'en';
        const isPleinex = OdcConfig.IS_PLEIN_EX;
        const isUpdateAccount = OdcConfig.IS_UPDATE_ACCOUNT;
        const isRecovery = OdcConfig.FROM_EXISTING_REQUEST === 'true';

        // Set lang from Caisse
        if (params.indexOf('contexteCaisse=') !== -1 || params.indexOf('contexteCaisseV2=') !== -1) {
            isLangEn = 'en' === LANGUE;
        }

        if (isUpdateAccount) {
            const isGpd = OdcConfig.IS_GPD;
            const isSfd = OdcConfig.IS_SFD;
            const isSsd = OdcConfig.IS_SSD;

            return NavigationService.getDefaultUpdatingRoute(isPleinex, isLangEn, isGpd, isSsd);

        }
        return NavigationService.getAppDefaultRoute(isPleinex, isLangEn, isRecovery);
    }

    private static getBranchRoute(branch: Branch, isLangEn): string {
        const act = branch.act;
        if (!this.isNullOrEmpty(act) && (act.toUpperCase() === 'NEW')) {
            const branchNewRoute = this.getBranchNewRoute(branch, isLangEn);
            if (branchNewRoute !== null) {
                return branchNewRoute;
            }
        } else {
            return this.getErrorBranchPath(isLangEn);
        }
    }

    private static getBranchNewRoute(branch: Branch, isLangEn: boolean): string {

        if (this.isValidNewAction(branch)) {
            return '';
        } else {
            return this.getErrorBranchPath(isLangEn);
        }
    }

    private static getErrorBranchPath(isLangEn: boolean): string {
        if (isLangEn) {
            return I18N_NAV_MESSAGES_EN.ODC_NAV_ERROR_BRANCH_PATH;
        } else {
            return I18N_NAV_MESSAGES_FR.ODC_NAV_ERROR_BRANCH_PATH;
        }
    }

    private static isNullOrEmpty(value): boolean {
        return !value || !value.trim();
    }

    private static isValidAction(value: string): boolean {
        return !this.isNullOrEmpty(value) && (value.toUpperCase() === 'NEW' || value.toUpperCase() === 'LIST');
    }

    // OCE-6927, OCE-6931
    private static isValidNewAction(branch: Branch) {
        return !this.isNullOrEmpty(branch.act) &&
            branch.act.toUpperCase() === 'NEW' &&
            branch.typeMembre &&
            !this.isNullOrEmpty(branch.noPdo) &&
            !this.isNullOrEmpty(branch.noInst) &&
            !this.isNullOrEmpty(branch.noTransit);
    }

    private static isBranchUrl(branch: Branch): boolean {
        return this.isNotNull(branch.act) || this.isNotNull(branch.codeUsager) ||
            this.isNotNull(branch.instOrig) || this.isNotNull(branch.transitOrig);
    }

    private static isNotNull(str: string): boolean {
        return str !== 'undefined' && str !== null;
    }

    // OCE-6927, OCE-6934
    private static isValidBranchURL(branch: Branch) {
        return this.isValidAction(branch.act) &&
            !this.isNullOrEmpty(branch.codeUsager) &&
            !this.isNullOrEmpty(branch.instOrig) &&
            !this.isNullOrEmpty(branch.transitOrig);
    }

    public static getFormBranchParams(): Branch {

        if (!this.isNullOrEmpty(OdcConfig.ACT)) {
            const act = OdcConfig.ACT;
            const noPdo = OdcConfig.NO_PDO;
            const typeMembre = OdcConfig.TYPE_MEMBRE;
            const noInst = OdcConfig.NO_INST;
            const noTransit = OdcConfig.NO_TRANSIT;
            const advLstNam = OdcConfig.ADV_LST_NAME;
            const advFstNam = OdcConfig.ADV_FST_NAME;
            const transitOrig = OdcConfig.TRANSIT_ORIG;
            const instOrig = OdcConfig.INST_ORIG;
            const langue = OdcConfig.LANGUE;
            const codeUsager = OdcConfig.CODE_USAGER;

            Branch.create(act, noPdo, typeMembre, noInst, noTransit,
                advLstNam, advFstNam, transitOrig, instOrig, langue, codeUsager);
        } else {
            Branch.create(
                Functions.getUrlParameter('act'),
                Functions.getUrlParameter('noPdo'),
                this.isNullOrEmpty(Functions.getUrlParameter('typeMembre')) ? 'I' : Functions.getUrlParameter('typeMembre'),
                Functions.getUrlParameter('noInst'),
                Functions.getUrlParameter('noTransit'),
                Functions.getUrlParameter('advLstNam'),
                Functions.getUrlParameter('advFstNam'),
                Functions.getUrlParameter('transitOrig'),
                Functions.getUrlParameter('instOrig'),
                Functions.getUrlParameter('langue'),
                Functions.getUrlParameter('codeUsager'));
        }

        return Branch.getInstance();
    }

    public static getOssQueryParams(): OssQuery {

        if (!this.isNullOrEmpty(OdcConfig.CLIENT_CODE)) {
            const clientCode = OdcConfig.CLIENT_CODE;
            const queryLanguage = Functions.getLanguage(OdcConfig.QUERY_LANGUAGE);

            OssQuery.create(clientCode, queryLanguage);

        } else {
            const language = Functions.getUrlParameter('lng');

            OssQuery.create(Functions.getUrlParameter('clientid'), Functions.getLanguage(language));
        }

        return OssQuery.getInstance();
    }

    public static getRootRoutes(): string {
        const params = window.location.search;
        const isLangEn = OdcConfig.LANGUE === 'en';
        const branch: Branch = this.getFormBranchParams();
        const ossQuery: OssQuery = this.getOssQueryParams();

        if (params && OdcConfig.CONSULTATION) {
            return this.getConsultationPage();
        }

        if (VmdConstants.CAISSE_CONTEXT_DECRYPTION_ERROR === OdcConfig.CAISSE_CONTEXT_DECRYPTION_ERROR) {
            return this.getErrorBranchPath(isLangEn);
        } else if (this.isBranchUrl(branch)) {
            if (this.isValidBranchURL(branch)) {
                return this.getBranchRoute(branch, isLangEn);
            } else {
                return this.getErrorBranchPath(isLangEn);
            }
        }

        return '';
    }

    private static getConsultationPage() {

        const url = 'consultation';

        const params = new URLSearchParams(window.location.search);

        if (params.get('updatingAccount')) {
            if (params.get('lng') == VmdConstants.LANG_EN) {
                return url + '/' + I18N_NAV_MESSAGES_EN.ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT_PATH;
            } else {
                return url + '/' + I18N_NAV_MESSAGES_FR.ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT_PATH;
            }
        }
    }
}

export const APP_ROUTES: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {path: '', redirectTo: AppRoutesConfig.getRootRoutes(), pathMatch: 'full'},
            {
                path: '',
                component: QuestionnaireComponent,
                children: AppRoutesConfig.getRoutesConfig()
            },
            {path: I18N_NAV_MESSAGES_FR.ODC_NAV_ERROR_BRANCH_PATH, component: BranchErrorComponent},
            {path: I18N_NAV_MESSAGES_EN.ODC_NAV_ERROR_BRANCH_PATH, component: BranchErrorComponent},
            {path: I18N_NAV_MESSAGES_FR.ODC_NAV_LOGIN_PATH, component: LoginComponent},
            {path: I18N_NAV_MESSAGES_EN.ODC_NAV_LOGIN_PATH, component: LoginComponent},
            {path: I18N_NAV_MESSAGES_FR.ODC_NAV_RESET_PASSWORD_PATH, component: ResetPasswordComponent},
            {path: I18N_NAV_MESSAGES_EN.ODC_NAV_RESET_PASSWORD_PATH, component: ResetPasswordComponent},
            {
                path: 'consultation', children: [
                    {path: I18N_NAV_MESSAGES_FR.ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT_PATH, component: ReviewUpdatePageComponent},
                    {path: I18N_NAV_MESSAGES_EN.ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT_PATH, component: ReviewUpdatePageComponent},
                ]
            },
            {path: '**', component: PageNotFoundComponent}
        ],
        runGuardsAndResolvers: 'always'
    }
];
