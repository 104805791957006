import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormService} from './form.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {ProfileBean} from '../models/ProfileBean';

@Injectable()
export class ProfileService {

    constructor(private translate: TranslateService, private formService: FormService,
                private router: Router, private http: HttpClient,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

    }

    private emitSaveSource = new Subject<any>();

    public initiateSave(): Observable<any> {
        return this.emitSaveSource.asObservable();
    }

    public emitSave() {
        this.emitSaveSource.next();
    }

    public authenticatePassword(password: string): Observable<any> {
        const profile: ProfileBean = new ProfileBean();
        profile.password = password;
        return this.http.post('api/authenticatePassword', profile, {withCredentials: true});
    }

    public getProfile(email: string): Observable<any> {
        const params = new HttpParams().set('email', email);
        return this.http.get('api/getProfile', {params});
    }

    public createProfile(profile: ProfileBean): Observable<any> {
        return this.http.post('api/createProfile', profile, {withCredentials: true});
    }

    public createSecurityQuestion(profile: ProfileBean): Observable<any> {
        return this.http.post('api/createSecurityQuestion', profile, {withCredentials: true});
    }

    public authenticateTempPasswordAndCreateSecurityQuestion(profile: ProfileBean): Observable<any> {
        return this.http.post('api/authenticateTempPasswordAndCreateSecurityQuestion', profile, {withCredentials: true});
    }

    public authenticateTempPassword(temporaryPassword: string, newPassword: string, confirmNewPassword: string): Observable<any> {
        const profile: ProfileBean = new ProfileBean();
        profile.temporaryPassword = temporaryPassword;
        profile.password = newPassword;
        profile.confirmPassword = confirmNewPassword;
        return this.http.post('api/authenticateTemporaryPassword', profile, {withCredentials: true});
    }

    public getSecurityQuestion(): Observable<any> {
        return this.http.get('api/getSecurityQuestion', {withCredentials: true});
    }

    public resetPassword(lang: string, answer: string): Observable<any> {
        const profile: ProfileBean = new ProfileBean();
        profile.securityAnswer = answer;
        profile.lang = lang;
        return this.http.post('api/resetPassword', profile, {withCredentials: true});
    }

    public resetPasswordRecaptcha(lang: string, answer: string, reCaptchaResponse: string): Observable<any> {
        const profile: ProfileBean = new ProfileBean();
        profile.securityAnswer = answer;
        profile.lang = lang;
        profile.reCaptchaResponse = reCaptchaResponse;
        return this.http.post('api/password-recaptcha', profile, {withCredentials: true});
    }

    public getAccountOpeningRequestList(): Observable<any> {
        return this.http.get('api/getProfileAccountOpeningRequestList', {withCredentials: true});
    }
}
