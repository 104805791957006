<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <h2>{{ 'ODC_STEP_PLATFORM_TYPE_DISNAT_CHOICE' | translate }}</h2>

    <div class="table-responsive">
        <table class="table tableau-donnees">
            <caption class="sr-only sr-only-focusable">{{ 'ODC_STEP_PLATFORM_TYPE_DISNAT_CHOICE' | translate }}
            </caption>
            <thead>
            <tr>
                <th scope="col" width="25%"></th>
                <th scope="col" width="37.5%" class="text-center">
                    <img class="img-responsive" src="assets/img/disnat-classique.png" alt="">
                    {{'ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC' | translate}}
                </th>
                <th scope="col" width="37.5%" class="text-center">
                    <img class="img-responsive" src="assets/img/disnat-direct.png" alt="">
                    {{'ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT' | translate}}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let key of [
            'IDEAL',
            'FEATURE',
            'MARKET_DATA',
            'PLATFORM_FEE',
            'INACTIVITY_FEE',
            'ADMINISTRATION_FEE',
            'STOCK_COMMISSION']">
                <th scope="row" [innerHTML]="'ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_' + key | translate"></th>
                <td [innerHTML]="'ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_' + key | translate"></td>
                <td [innerHTML]="'ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_' + key | translate"></td>
            </tr>
            </tbody>
        </table>
    </div>


    <fieldset class="form-group row" [class.has-error]="isFieldInError('platformType')">
        <legend class="col-md-6"><b>{{'ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_CHOICE' | translate}}</b></legend>

        <div class="col-md-18">
            <div class="row">
                <div class="col-md-24">
                    <app-error [form]="form" [submitted]="isFormSubmitted()" [path]="'platformType'"></app-error>
                </div>
                <div class="col-md-12">
                    <div class="panel panel-simple panel-radio panel-horizontal panel-alignleft">
                        <div class="panel-body">
                            <div class="panel-content" id="panel-radio-platformTypeClassic">
                                <label class="panel-label" for="platformTypeClassic">{{'ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC'
                                    | translate}}</label>
                            </div>
                            <input class="panel-input"
                                   type="radio"
                                   name="platformType"
                                   formControlName="platformType"
                                   [value]="constants.DISNAT_CLASSIC"
                                   [appFormBinding]="'plateformDisnat'"
                                   id="platformTypeClassic"
                                   aria-describedby="panel-radio-platformTypeClassic">
                            <div class="panel-check">
                                <div class="c-input c-radio">
                                    <span class="c-indicator"></span>
                                </div>
                            </div>
                            <div class="panel-active"></div>
                            <div class="panel-focus"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="panel panel-simple panel-radio panel-horizontal panel-alignleft">
                        <div class="panel-body">
                            <div class="panel-content" id="panel-radio-platformTypeDirect">
                                <label class="panel-label" for="platformTypeDirect">{{'ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT'
                                    | translate}}</label>
                            </div>
                            <input class="panel-input"
                                   type="radio"
                                   name="platformType"
                                   formControlName="platformType"
                                   [value]="constants.DISNAT_DIRECT"
                                   [appFormBinding]="'plateformDisnat'"
                                   id="platformTypeDirect"
                                   aria-describedby="panel-radio-platformTypeDirect">
                            <div class="panel-check">
                                <div class="c-input c-radio">
                                    <span class="c-indicator"></span>
                                </div>
                            </div>
                            <div class="panel-active"></div>
                            <div class="panel-focus"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

    <div class="note-bas-page m-t-5">
        <div id="platform-note-1" tabindex="0">
            <span [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_1' | translate"></span>
            <ul>
                <li [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_1_1' | translate"></li>
                <li [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_1_2' | translate"></li>
                <li [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_1_3' | translate"></li>
            </ul>
            <a href="javascript:void(0)" class="retour-note" (click)="noteGoBack('platform-note-1')">{{'NOTE_BACK'|translate}}</a>
        </div>
        <div id="platform-note-2" tabindex="0">
            <span [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_2' | translate"></span>
            <ul>
                <li [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_2_1' | translate"></li>
                <li [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_2_2' | translate"></li>
                <li [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_2_3' | translate"></li>
                <li [innerHTML]="'ODC_STEP_PLATFORM_TYPE_NOTE_2_4' | translate"></li>
            </ul>
            <a href="javascript:void(0)" class="retour-note" (click)="noteGoBack('platform-note-2')">{{'NOTE_BACK'|translate}}</a>
        </div>
    </div>
</form>


