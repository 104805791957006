import {AfterViewInit, Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[appRemoveError]'
})
export class RemoveErrorDirective implements AfterViewInit {

    @Input() errMsg: string;
    @Output() clearMsg: EventEmitter<any> = new EventEmitter();

    ngAfterViewInit() {

    }

    @HostListener('focus', ['$event'])
    onFocus() {
        if (this.errMsg != null) {
            this.clearMsg.emit(this.errMsg);
        }
    }
}
