<h2>
    {{'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN' | translate}}
</h2>

<form [formGroup]="form" ngForm novalidate (submit)="onEsignSubmit()" class="clearfix">

    <div *ngIf="isCaisseContext()" class="m-t-3" tabindex="0">
        <p class="p-b-3">{{'ESIGN_CONTACT_INFORMATION_TITLE1_CAISSE' | translate}} <br><br> {{this.formService.getForm().requesters[0].userEmail}}</p>
        <p class="p-b-3">{{'ESIGN_CONTACT_INFORMATION_TITLE2_CAISSE' | translate}}</p>
    </div>

    <div *ngIf="!isCaisseContext()" class="h5" tabindex="0">{{'ESIGN_CONTACT_INFORMATION_TITLE' | translate}}</div>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <div class="m-b-3">
        <!--esignUserPhoneChoice--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="getEsignContactInformationUserPhoneLabel() | translate"
            [id]="'esignUserPhoneChoice'"
            [name]="'esignUserPhoneChoice'"
            [controlName]="'esignUserPhoneChoice'"
            [path]="'esignUserPhoneChoice'"
            [binding]="'esignUserPhoneChoice'"
            [list]="userPhonesList"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        ></app-odc-field>

        <div *ngIf="form.get('esignUserPhoneChoice').value === otherValue" formGroupName="otherPhone">
            <!--esignUserPhoneOther------------------------------------------------>
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [phoneFormat]="true"
                [form]="form.get('otherPhone')"
                [submitted]="isFormSubmitted()"
                [label]="'ESIGN_CONTACT_INFORMATION_USER_PHONE_SPECIFY_OTHER' | translate"
                [id]="'esignUserPhoneOther'"
                [name]="'esignUserPhoneOther'"
                [controlName]="'esignUserPhoneOther'"
                [path]="'esignUserPhoneOther'"
                [binding]="'esignUserPhoneOther'"
                [attr]="{maxlength: 12}"
            ></app-odc-field>
        </div>
    </div>
    <hr/>

    <div *ngIf="isCaisseContext()" class="m-t-3" tabindex="0">
        <p class="p-b-3">{{ 'ESIGN_GUIDE_CAISSE' | translate }}</p>
    </div>

    <div class="row m-t-5">
        <div class="col-md-6">
            <img width="138px" height="144px" class="left"
             src="assets/img/digital_signature.png">
        </div>
        <div class="col-md-18 p-t-5">
            <button type="submit"
                    class="btn btn-primary btn-block loadable"
                    [class.loading]="loading"
                    [attr.disabled]="(loading || isSaving) ? true : null"
            >
                {{ 'BTN_SIGN' | translate }}
            </button>

            <div *ngIf="isErrorDetected()" class="has-error">
                <div class="bloc-erreur-generique">
                    <p>{{(isEmailError() ? 'ERROR_ESIGN_INVALID_EMAIL' : 'ERROR_ESIGN') | translate}}</p>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="isCaisseContext()" class="m-t-3" tabindex="0">
        <p class="p-b-3">{{ 'ESIGN_GUIDE3' | translate }}</p>
    </div>
    <div *ngIf="!isCaisseContext()" class="m-t-3" tabindex="0">
        <p class="p-b-3">{{ 'ESIGN_GUIDE' | translate }}</p>
        <p class="p-b-3">{{ 'ESIGN_GUIDE2' | translate }}</p>
        <p class="p-b-3">{{ 'ESIGN_GUIDE3' | translate }}</p>
    </div>


    <div class="btn-resp btn-resp-1colonne-inline m-t-4">
        <div class="btn-resp-col btn-resp-demi">
            <button *ngIf="showBack()" type="button" class="btn btn-default"
                    [attr.disabled]="(loading || isSaving) ? true : null"
                    (click)="navBack()">
                {{'BTN_BACK' | translate}}
            </button>
        </div>
    </div>

</form>
