/**
 * Use component as string because of circular dependencies
 */
export const PERSONAL_ACCOUNT_ROUTES_CONFIG: any = [
    {
        showTab: false,
        label: 'ODC_NAV_STEP_PREPARATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PREPARATION_BEGIN',
                path: 'ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'BeginComponent'
            }, {
                label: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE',
                path: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'ClientTypeComponent'
            }
        ],
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE',
        items: [
            {
                label: 'ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE',
                path: 'ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_CONSULT_ALL',
                component: 'PlatformChoiceComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_CONSULT_ALL',
                component: 'AccountsChoiceComponent'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_PERSONAL_INFORMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_PATH',
                component: 'PersonalInformationComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_PATH',
                component: 'ResidentialAddressComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_OCCUPATION',
        items: [
            {
                label: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION',
                path: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_PATH',
                component: 'OccupationComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_CIVIL_STATUS',
        items: [
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_PATH',
                component: 'SpouseInformationComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                disable: true,
                label: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RRSP',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RRSP_PERSONAL_ACCOUNT_PATH',
                component: 'BeneficiaryRrspComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                disable: true,
                label: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_TFSA',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_TFSA_PERSONAL_ACCOUNT_PATH',
                component: 'BeneficiaryTfsaComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                disable: true,
                label: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PATH',
                component: 'BeneficiaryFhsaComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_PATH',
                component: 'AttorneyComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_LEGAL',
        items: [
            {
                label: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS',
                path: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_PATH',
                component: 'BrokerageAccountsComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER',
                path: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_PATH',
                component: 'ShareholderComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_LEGAL_OPTIONS_ACCOUNT',
                path: 'ODC_NAV_STEP_LEGAL_OPTIONS_ACCOUNT_PERSONAL_ACCOUNT_PATH',
                component: 'OptionsAccountComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT',
                path: 'ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PATH',
                component: 'SpecificConsentsComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_PATH',
                component: 'FinancialInformationComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_TRANSFER_REQUEST',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_PATH',
                component: 'TransferRequestChoiceComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED_PERSONAL_ACCOUNT_PATH',
                component: 'TransferRequestNonRegisteredComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA_PERSONAL_ACCOUNT_PATH',
                component: 'TransferRequestTfsaComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA_PERSONAL_ACCOUNT_PATH',
                component: 'TransferRequestFhsaComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP_PERSONAL_ACCOUNT_PATH',
                component: 'TransferRequestRrspComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA_PERSONAL_ACCOUNT_PATH',
                component: 'TransferRequestLiraComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF_PERSONAL_ACCOUNT_PATH',
                component: 'TransferRequestRrifComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF_PERSONAL_ACCOUNT_PATH',
                component: 'TransferRequestLifComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_REVIEW',
        items: [
            {
                label: 'ODC_NAV_STEP_REVIEW_REVIEW',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_PERSONAL_ACCOUNT_PATH',
                component: 'ReviewPersonalAccountComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH',
                component: 'ReviewClientIdentityComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_CONSENT',
        items: [
            {
                label: 'ODC_NAV_STEP_CONSENT_CONSENT',
                path: 'ODC_NAV_STEP_CONSENT_CONSENT_PERSONAL_ACCOUNT_PATH',
                component: 'ConsentsComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: true,
        disableSwitchLang: true,
        label: 'ODC_NAV_STEP_TRANSMISSION',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH',
                component: 'TransmissionChoiceComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH',
                component: 'TransmissionKitDownloadComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_PATH',
                component: 'TransmissionAttachmentsComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_LBA_PATH',
                component: 'TransmissionAttachmentsLbaComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_LBA_CAISSE_PATH',
                component: 'TransmissionAttachmentsLbaCaisseComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_PERSONAL_ACCOUNT_PATH',
                component: 'TransmissionAdditionalFormsComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH',
                component: 'TransmissionEsignComponent',
                disable: true,
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: false
            }
        ]
    }, {
        showTab: false,
        disableSwitchLang: true,
        label: 'ODC_NAV_STEP_CONFIRMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION',
                path: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_CONFIRMATION',
                component: 'ConfirmationComponent'
            }, {
                label: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION',
                path: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_CONFIRMATION',
                component: 'ConfirmationCaisseComponent',
                disable: true,
                hideAsideConsult: true
            }
        ]
    }
];
