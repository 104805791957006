import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {WebException} from '../../components/models';
import {ProfileService} from '../../components/services/profile.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {ListModelService} from '../../components/services/list-model.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileAccountOpeningRequestListComponent} from './profile-account-opening-request-list.component';
import {ModalService} from '../../components/services/modal.service';
import {VmdForm} from '../../components/utils/vmd-form';
import {I18N_NAV_MESSAGES_FR} from '../../components/i18n/nav-messages_fr';
import {I18N_NAV_MESSAGES_EN} from '../../components/i18n/nav-messages_en';
import {SessionService} from '../../components/services/session.service';
import {RecaptchaComponent} from './recaptcha.component';

declare let $: any;
declare const grecaptcha: any;

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

    @ViewChild(ProfileAccountOpeningRequestListComponent)
    profileAccountOpeningRequestList: ProfileAccountOpeningRequestListComponent;
    @ViewChild(RecaptchaComponent)
    recaptchaComponent: RecaptchaComponent;
    @ViewChild(NgForm) ngForm: NgForm;

    form: FormGroup;
    errMsg: string = null;
    loading = false;
    question: string = null;
    constants = VmdConstants;
    questionSecuriteList: HtmlSelectKV[];
    replyAttempts = 0;
    displayList = false;
    displayBtnContinue = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private translate: TranslateService,
        private listModelService: ListModelService,
        private profileService: ProfileService,
        private modalService: ModalService,
        private sessionService: SessionService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.listModelService.getListModel('questionSecuriteList', data => this.questionSecuriteList = data);

        this.modalService.resetPassword.subscribe(() => {
            this.errMsg = null;
            this.enableEmailSection();
            this.form.get('question.reply').setValue(null);
            this.form.get('passwords.oldPassword').setValue(null);
            this.form.get('passwords.newPassword').setValue(null);
            this.form.get('passwords.confirmNewPassword').setValue(null);
            $('#modal-reset-password').modal('hide');
        });

        const routerState = '_routerState';
        const url = this.route[routerState].snapshot.url;
        if (url === '/' + I18N_NAV_MESSAGES_FR.ODC_NAV_RESET_PASSWORD_PATH && this.translate.currentLang !== VmdConstants.LANG_FR) {
            this.translate.use(VmdConstants.LANG_FR);
        } else if (url === '/' + I18N_NAV_MESSAGES_EN.ODC_NAV_RESET_PASSWORD_PATH && this.translate.currentLang !== VmdConstants.LANG_EN) {
            this.translate.use(VmdConstants.LANG_EN);
        }

        this.enableEmailSection();
    }

    createForm() {
        this.form = this.fb.group({
            email: [null, [Validators.required, VmdValidators.email]],
            question: this.fb.group({
                reply: [null, [Validators.required]]
            }),
            passwords: this.fb.group({
                oldPassword: [null, [Validators.required]],
                newPassword: [null, [Validators.required, Validators.minLength(VmdConstants.PASSWORD_LENGTH_MIN)]],
                passwordStrength: [null, [Validators.min(VmdConstants.PASSWORD_STRENGTH_MIN)]],
                confirmNewPassword: [null, [Validators.required]]
            }, {validator: VmdValidators.passwordMatchValidator()}),
            recaptcha: []
        });
    }

    private enableEmailSection(): void {
        this.replyAttempts = 0;

        this.form.get('email').enable();
        this.form.get('question').disable();
        this.form.get('passwords').disable();
        $('#content').focus();
    }

    private enableQuestionSection(): void {
        this.replyAttempts = 0;

        this.form.get('email').disable();
        this.form.get('question').enable();
        this.form.get('question').markAsUntouched();
        this.form.get('passwords').disable();
        $('#content').focus();
    }

    private enablePasswordsSection(): void {
        this.replyAttempts = 0;

        this.form.get('email').disable();
        this.form.get('question').disable();
        this.form.get('passwords').enable();
        this.form.get('passwords').markAsUntouched();
        $('#content').focus();
    }

    private enableListSection(): void {
        this.replyAttempts = 0;
        this.displayList = true;

        this.form.get('email').disable();
        this.form.get('question').disable();
        this.form.get('passwords').disable();
        $('#content').focus();
    }

    getBtnContinueDisabledValue(): boolean {
        return (this.loading || (this.isReplyAttempsExceeded() && !this.form.get('passwords').enabled))
            ? true
            : null;
    }

    undisplayBtnContinue(): void {
        this.displayBtnContinue = false;
    }

    getQuestionSecuriteLabel(): string {
        for (const item of this.questionSecuriteList) {
            if (item.itemValue === this.question) {
                return item.itemLabel;
            }
        }

        return null;
    }

    focusOnErrorsList() {
        setTimeout(() => {
            const firstError = ($('#boite-erreurs').first().length > 0) ? $('#boite-erreurs').first() : $('.has-error input, .has-error select').first();
            if (firstError.length > 0) {
                $(firstError).focus();
            }
        }, 0);
    }

    isFieldInError(path: string): boolean {
        return VmdForm.isFieldInError(this.form.get(path), this.isFormSubmitted());
    }

    isFormSubmitted(): boolean {
        if (this.ngForm) {
            return this.ngForm.submitted;
        }

        return false;
    }

    isReplyAttempsExceeded(): boolean {
        return this.replyAttempts >= 3;
    }

    onSubmit() {
        this.errMsg = null;
        VmdForm.markAsTouched(this.form);

        if (this.form.valid) {
            if (this.form.get('question').enabled) {
                this.onSubmitReply();
            } else if (this.form.get('passwords').enabled) {
                this.onSubmitPasswords();
            } else {
                this.onSubmitEmail();
            }
        } else {
            this.focusOnErrorsList();
        }
    }

    onSubmitEmail() {
        this.loading = true;
        this.question = null;

        const email = this.form.get('email').value;

        this.profileService.getProfile(email).subscribe(() => {
                this.profileService.getSecurityQuestion().subscribe((response: any) => {
                        this.loading = false;

                        this.question = response.payload;

                        if (this.question !== null) {
                            this.enableQuestionSection();
                        } else {
                            this.errMsg = 'ODC_RESET_PASSWORD_ERR_INVALID_PROFILE';
                        }
                    },
                    (error: WebException) => {
                        this.loading = false;
                        this.setError(error);
                    });
            },
            (error: WebException) => {
                this.loading = false;
                this.setError(error);
            });
    }

    onSubmitReply() {
        this.loading = true;

        const reply = this.form.get('question.reply').value;
        const reCaptchaResponse = this.getRecaptchaResponse();

        this.profileService.resetPasswordRecaptcha(this.translate.currentLang, reply, reCaptchaResponse).subscribe(() => {
                this.loading = false;

                this.enablePasswordsSection();
            },
            (error: WebException) => {
                this.loading = false;
                this.replyAttempts++;
                this.setError(error);

                if (this.isReplyAttempsExceeded()) {
                    this.form.get('question').disable();
                    this.undisplayBtnContinue();
                }
            });
    }

    onSubmitPasswords() {
        this.loading = true;

        const oldPassword = this.form.get('passwords.oldPassword').value;
        const newPassword = this.form.get('passwords.newPassword').value;
        const confirmNewPassword = this.form.get('passwords.confirmNewPassword').value;

        this.profileService.authenticateTempPassword(oldPassword, newPassword, confirmNewPassword).subscribe((authenticateTempPasswordResponse) => {
                this.loading = false;
                this.sessionService.beginSession(authenticateTempPasswordResponse.payload);
                this.enableListSection();
                this.profileAccountOpeningRequestList.getAccountOpeningRequestList();
            },
            (error: WebException) => {
                this.loading = false;
                this.replyAttempts++;

                if (this.isReplyAttempsExceeded()) {
                    this.modalService.openModal(null, null, '#modal-reset-password');
                } else {
                    this.setError(error);
                }
            });
    }

    setError(error: any): void {
        this.errMsg = 'GLOBAL_ERROR_MSG';

        if (error.listWarning && error.listWarning.length > 0) {
            const warning = error.listWarning[0];

            if (warning.id === 'clientProfile' && warning.message === 'CLIENTPROFILE_NOT_FOUND') {
                this.errMsg = 'ODC_RESET_PASSWORD_ERR_INVALID_ACCOUNT';
            } else if (warning.id === 'codeSecurityQuestion' && warning.message === 'value.invalid') {
                this.errMsg = 'ODC_RESET_PASSWORD_ERR_INVALID_PROFILE';
            } else if (warning.id === 'securityAnswer' && warning.message === 'SECURITY_ANSWER_INVALID') {
                if (this.isReplyAttempsExceeded()) {
                    this.errMsg = 'ODC_RESET_PASSWORD_ERR_CONTACT_CLIENT_SERVICE';
                } else if (this.form.get('question').enabled) {
                    this.errMsg = 'ODC_RESET_PASSWORD_ERR_REPLY';
                }
            } else if ((warning.id === 'temporaryPassword' && warning.message === 'TEMPORARY_PASSWORD_INVALID') ||
                (warning.id === 'authenticatePassword' && warning.message === 'ODC_AUTHENTICATE_ERR_PASSWORD')) {
                if (this.isReplyAttempsExceeded()) {
                    this.errMsg = 'ODC_RESET_PASSWORD_ERR_CONTACT_CLIENT_SERVICE';
                } else {
                    this.errMsg = 'ODC_RESET_PASSWORD_ERR_PASSWORD';
                }
            } else if (warning.id === 'temporaryPassword' && warning.message === 'TEMPORARY_PASSWORD_EXPIRED') {
                if (this.isReplyAttempsExceeded()) {
                    this.errMsg = 'ODC_RESET_PASSWORD_ERR_CONTACT_CLIENT_SERVICE';
                } else {
                    this.errMsg = warning.message;
                }
            } else if (warning.id === 'password' && warning.message === 'PASSWORD_INVALID_HISTORY') {
                this.errMsg = 'PASSWORD_INVALID_HISTORY';
            } else if (warning.id === 'reCaptcha' && warning.message === 'RECAPTCHA_INVALID') {
                this.errMsg = 'RECAPTCHA_INVALID';
            }

            this.resetRecaptcha();
        }

        setTimeout(() => {
            if ($('.bloc-erreur-generique').length > 0) {
                $('.bloc-erreur-generique').focus();
            }
        }, 0);
    }

    showRecaptcha() {
        return this.form.get('question').enabled;
    }

    resetRecaptcha() {
        grecaptcha.enterprise.reset();
    }

    getRecaptchaResponse() {
        return grecaptcha.enterprise.getResponse();
    }

    onPasswordStrengthChanges(value: number) {
        if (value != null) {
            this.form.get('passwords.passwordStrength').setValue(value);
        }
    }
}
