import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {LangChangeEvent} from '@ngx-translate/core/lib/translate.service';
import {Observable, Subscription} from 'rxjs';

declare const grecaptcha: any;

@Component({
    selector: 'app-recatpcha',
    templateUrl: './recaptcha.component.html',
})
export class RecaptchaComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() langueChange: EventEmitter<LangChangeEvent>;
    @Input() langue: string;

    recaptchaChangeLangSubscriptor: Subscription;

    @ViewChild('recaptchaParentNode') recaptchaParentNode: ElementRef;

    siteKey: string = null;

    ngOnInit(): void {
        this.setSiteKey();
    }

    ngAfterViewInit(): void {
        this.renderRecaptcha(this.langue);
        
        this.recaptchaChangeLangSubscriptor = this.langueChange.subscribe(lang => {
            this.renderRecaptcha(lang.lang);
        });
    }

    renderRecaptcha(lang): void {
        this.recaptchaParentNode.nativeElement.innerHTML =
            '<div #recaptchaParentNode>' +
            '    <div id="recaptcha"></div>' +
            '</div>';

        grecaptcha.enterprise.ready(async () => {
            grecaptcha.enterprise.render('recaptcha', {
                sitekey: this.siteKey,
                action: 'authenticate',
                hl: lang
            });
        });
    }

    ngOnDestroy(): void {
        this.recaptchaChangeLangSubscriptor.unsubscribe();
    }

    setSiteKey(): void {
        const host = window.location.host;

        switch (host) {
            case VmdConstants.PROD_HOST_NAME_PRIV_DISNAT:
            case VmdConstants.PROD_HOST_NAME_DISNAT:
            case VmdConstants.PROD_HOST_NAME:
            case VmdConstants.PROD_HOST_NAME_EN:
                this.siteKey = '6LdnlV4eAAAAAGHHoe7r2hwkdqRtdQSQWPSaqrtU';
                break;
            case VmdConstants.PREPROD_HOST_NAME_VMD_PRIV:
            case VmdConstants.PREPROD_HOST_NAME_VMD:
            case VmdConstants.PREPROD_HOST_NAME:
            case VmdConstants.PREPROD_HOST_NAME_EN:
                this.siteKey = '6LfgI1AeAAAAAFvTDX1_DKHjG6gjHLMgBo43_zRE';
                break;
            default:
                this.siteKey = '6LfdQ0oeAAAAAOSbuetvripYPsJn505z08FXLUlw';
        }
    }
}
