import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {RequestsComparisonResult} from '../models/requests-comparison-result.model';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class FormsComparisonService {

    private updateComparisonResults: RequestsComparisonResult[];
    updateComparisonResults$ = new Subject<RequestsComparisonResult[]>();

    private significantChangeState = false;
    significantChangeState$ = new Subject<boolean>();

    constructor(private http: HttpClient) {

        this.updateComparisonResults$.subscribe((comparisonResults: RequestsComparisonResult[]) => {
            this.updateComparisonResults = comparisonResults;

            if (this.isSignificantChangeDetected(comparisonResults)) {
                this.significantChangeState = true;
                this.significantChangeState$.next(true);
            } else {
                this.significantChangeState = false;
                this.significantChangeState$.next(false);
            }
        });
    }

    private isSignificantChangeDetected(results: RequestsComparisonResult[]): boolean {
        if (results == null) {
            return false;
        }
        return Object.values(results)
            .filter(e => e !== null)
            .map(e => e.significantChange)
            .includes(true);
    }

    getSignificantChangeState(): boolean {
        return this.significantChangeState;
    }

    getComparisonResults(): RequestsComparisonResult[] {
        return this.updateComparisonResults;
    }

    setComparisonResults(data: RequestsComparisonResult[]) {
        this.updateComparisonResults = data;
    }

    public getRequestDiff(encryptedUrl: string): Observable<any> {
        const params = new HttpParams().set('encryptedUrl', encryptedUrl);
        return this.http.get('api/request-diff', {params});
    }
}
