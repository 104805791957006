import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {WebException} from '../../components/models';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {NavigationService} from '../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-add-account-submission',
    templateUrl: './add-account-submission.component.html'
})
export class AddAccountSubmissionComponent extends BaseNavComponent implements OnInit {

    loading = false;
    isSaving = false;
    error: WebException = null;

    submitted = false;

    alreadySubmitError: string = null;
    submitLabel: string = null;

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                protected formService: FormService,
                protected translate: TranslateService,
                private translatePipe: TranslatePipe) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.subscriptions.push(this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        }));

        this.form = this.fb.group({
            confirm: [false]
        });

        if (
            VmdConstants.MODES.AUTOMATED === this.formService.getForm().entryMode
            && !this.hasPrintedOrSavedOrCorrectStatus()
        ) {
            this.submitted = true;
        }
        this.initSubmitLabel();
    }

    private initSubmitLabel() {
        return this.isUpdateAccount() && this.isPilotMemberOrModeUpdateAutomatedOpeningActive() ?
            this.submittedLabelForUpdateText() :
            this.submittedLabelText();
    }

    private hasPrintedOrSavedOrCorrectStatus(): boolean {
        return (
            null === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_PRINTED === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_SAVED === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_CORRECT === this.formService.getForm().requestStatus
        );
    }

    private submittedLabelForUpdateText() {
        if (this.isRecoveredRequest()) {
            this.submitLabel = this.translatePipe.transform('SUBMITTED_UPDATE_LABEL');
        } else {
            this.makeSubmitLabelWithName();
        }
    }

    private submittedLabelText() {
        this.submitLabel = this.translatePipe.transform('SUBMITTED_LABEL');
    }

    submitForm() {
        if (this.form.get('confirm').value && this.hasPrintedOrSavedOrCorrectStatus()) {

            // OCE-7596
            if (!this.formService.isCompleted()) {
                if (!this.formService.saveInProgress()) {

                    this.loading = true;
                    this.formService.emitIsSaving(true);
                    this.error = null;
                    this.alreadySubmitError = null;

                    this.formService.completeForm().subscribe((data: any) => {

                            this.loading = false;
                            this.formService.emitIsSaving(false);

                            if (data.status === 'OK' && data.payload && !data.payload.errorMsg) {
                                this.formService.emitSaved();
                            }

                            this.formService.setCompleted(true);
                            this.submitted = true;
                        },
                        (error: WebException) => {
                            this.error = error;
                            this.loading = false;
                            this.formService.emitIsSaving(false);
                        });
                }
            } else {
                this.alreadySubmitError = 'ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED';
            }
        }
    }

    private makeSubmitLabelWithName() {
        this.formService.getSessionUserName().subscribe((data: any) => {
                if (data.status === 'OK' && data.payload && !data.payload.errorMsg) {
                    const updateSubmitUserInfos = data.payload;

                    this.submitLabel = this.translatePipe.transform('SUBMITTED_UPDATE_LABEL_WITH_NAME',
                        {date: updateSubmitUserInfos.submitDate, name: updateSubmitUserInfos.fullName});
                }
            },
            (error: WebException) => {
                this.error = error;
                this.loading = false;
            });
    }
}
