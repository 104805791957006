import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder} from '@angular/forms';
import {VmdFormat} from '../../components/formats/vmd-format';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {WebException} from '../../components/models';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../components/services/form.service';
import {NavigationService} from '../../components/services/navigation.service';
import {ModalService} from '../../components/services/modal.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {VmdConstants} from '../../components/constants/vmd-constants';


declare let $: any;

@Component({
    selector: 'app-transmission-esign',
    templateUrl: './transmission-esign.component.html'
})
export class TransmissionEsignComponent extends BaseNavComponent implements OnInit {

    loading = false;
    loadingStatus = false;
    error: WebException = null;
    isSaving = false;
    emailError = false;

    otherValue = 'OTHER';

    userHomePhone: string;
    userOtherPhone: string;
    userMobilePhone: string;

    userPhonesList: HtmlSelectKV[];
    otherPhoneLabel: string;

    webExceptionMessageList: any;
    webExceptionErrorMessage: string;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.navigationService.emitIsBackDisabled(true);

        this.userHomePhone = this.formService.getForm().requesters[0].userHomePhone;
        this.userOtherPhone = this.formService.getForm().requesters[0].userOtherPhone;
        this.userMobilePhone = this.formService.getForm().requesters[0].userMobilePhone;

        this.userPhonesList = [];
        if (this.userHomePhone) {
            this.userPhonesList.push(new HtmlSelectKV(this.userHomePhone, this.getFormatedPhone(this.userHomePhone)));
        }

        if (this.userOtherPhone) {
            this.userPhonesList.push(new HtmlSelectKV(this.userOtherPhone, this.getFormatedPhone(this.userOtherPhone)));
        }

        if (this.userMobilePhone) {
            this.userPhonesList.push(new HtmlSelectKV(this.userMobilePhone, this.getFormatedPhone(this.userMobilePhone)));
        }

        if (this.isCaisseContext()) {
            this.translate.get('ESIGN_CONTACT_INFORMATION_USER_PHONE_OTHER_CAISSE').subscribe((translation: string) => {
                this.otherPhoneLabel = translation;
            });
        } else {
            this.translate.get('ESIGN_CONTACT_INFORMATION_USER_PHONE_OTHER').subscribe((translation: string) => {
                this.otherPhoneLabel = translation;
            });
        }
        this.userPhonesList.push(new HtmlSelectKV(this.otherValue, this.otherPhoneLabel));

        this.form = this.fb.group({
            esignUserPhoneChoice: [this.initEsignUserPhoneChoice(), [this.requiredValidator('esignUserPhone')]],
            otherPhone: this.fb.group({
                esignUserPhoneOther: [this.initEsignUserPhone(), [this.requiredValidator('esignUserPhone'), VmdValidators.phone]]
            })
        });

        this.subscriptions.push(this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        }));

        this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
            this.translate.get('ESIGN_CONTACT_INFORMATION_USER_PHONE_OTHER').subscribe((translation: string) => {
                this.otherPhoneLabel = translation;
            });
            this.userPhonesList.pop();
            this.userPhonesList.push(new HtmlSelectKV(this.otherValue, this.otherPhoneLabel));
        }));

        this.subscriptions.push(this.form.get('esignUserPhoneChoice').valueChanges.subscribe(value => {

            this.updateControlEnabled(this.form.get('otherPhone'), value === this.otherValue);

            if (value !== this.otherValue) {
                this.formService.setFormProperty('esignUserPhone', value);
            }
        }));
        this.updateControlEnabled(this.form.get('otherPhone'), this.form.get('esignUserPhoneChoice').value === this.otherValue);

        this.subscriptions.push(this.form.get('otherPhone.esignUserPhoneOther').valueChanges.subscribe(value => {
            this.formService.setFormProperty('esignUserPhone', value);
        }));

        this.subscriptions.push(this.modalService.esignClosePopupEmitted.subscribe(() => {
            this.closePopup();
        }));
        this.subscriptions.push(this.modalService.esignCloseConfirmPopupEmitted.subscribe((confirm: boolean) => {
            this.closeConfirmPopup(confirm);
        }));

        $('#modal-esign .modal-body iframe').prop('src', 'about:blank');

        this.setError(null);
    }

    closeConfirmPopup(confirm: boolean = false): void {
        if (!confirm) {
            this.modalService.openModal(null, null, '#modal-esign');
        } else {
            this.setLoading(false);
        }

    }

    closePopup(): void {
        // check esign status
        this.loadingStatus = true;
        this.subscriptions.push(this.formService.isEsignInProgress().subscribe(isInProgress => {
                this.loadingStatus = false;

                if (isInProgress) {
                    this.openConfirmationModal();
                } else {
                    $('#modal-esign').modal('hide');

                    setTimeout(() => {
                        this.navNext();
                    }, 0);
                }
            },
            (error: WebException) => {
                this.loadingStatus = false;
                this.setError(error);
            }));
    }

    getFormatedPhone(phone: string): string {
        return VmdFormat.phone.formatAsPhone(phone);
    }

    initEsignUserPhone(): string {
        const phone = this.formService.getForm().esignUserPhone || this.initEsignUserPhoneChoice();
        if (this.otherValue !== phone) {
            return phone;
        } else {
            return null;
        }
    }

    initEsignUserPhoneChoice(): string {
        const phone = this.userHomePhone || this.userOtherPhone || this.userMobilePhone;

        if (this.formService.getForm().esignUserPhone === null) {
            return null;
        } else if (this.formService.getForm().esignUserPhone === phone) {
            return phone;
        } else {
            return this.otherValue;
        }
    }

    openConfirmationModal(): void {
        // put the confirm modal in front of iframe modal
        $('#modal-esign-confirm-close_background').css('z-index', parseInt($('#modal-esign_background').css('z-index'), 10) + 10);
        $('#modal-esign-confirm-close_wrapper').css('z-index', parseInt($('#modal-esign_wrapper').css('z-index'), 10) + 10);

        this.modalService.openModal(null, null, '#modal-esign-confirm-close');
    }

    openModal(): void {
        setTimeout(() => {
            this.setLoading(true);

            this.modalService.openModal(null, null, '#modal-esign');
        }, 0);
    }

    onEsignSubmit(): void {

        if (!this.formService.saveInProgress()) {

            this.submitForm(false);
            if (this.form.valid) {

                this.setLoading(true);
                this.setError(null);

                this.formService.isEsignInProgress().subscribe(isInProgress => {
                        if (isInProgress) {
                            this.formService.transmitForm().subscribe(() => {

                                    this.formService.setTransmitted(true);

                                    if (this.formService.isCaisseContext()) {
                                        this.submitEsignCaisse();
                                    } else {
                                        this.getEsignUrl();
                                    }

                                },
                                (error: WebException) => {
                                    this.setLoading(false);
                                    this.setError(error);
                                });
                        } else {
                            this.setLoading(false);
                            this.setError(null);
                            this.navNext();
                        }
                    },
                    (error: WebException) => {
                        this.setLoading(false);
                        this.setError(error);
                    });
            }
        }
    }

    private submitEsignCaisse(): void {

        this.setLoading(true);
        this.setError(null);

        this.subscriptions.push(this.formService.submitEsignCaisse().subscribe(data => {
                this.setLoading(false);

                console.log('submitEsignCaisse: ', data);
                this.navNext();
            },
            (error: WebException) => {
                this.setLoading(false);
                this.setError(error);
            }));
    }

    private getEsignUrl(): void {
        this.openModal();

        this.setLoading(true);
        this.setError(null);

        this.subscriptions.push(this.formService.getEsignUrl().subscribe(data => {
                this.setLoading(false);

                setTimeout(() => {
                    this.setEsignUrl(data);
                }, 0);
            },
            (error: WebException) => {
                $('#modal-esign').modal('hide');
                this.setLoading(false);
                this.setError(error);
            }));
    }

    setError(error: WebException): void {
        this.error = error;
        if (this.error && this.error.listWarning) {
            if (this.error.listWarning.length) {
                const exceptionMessageObject = JSON.parse(this.error.listWarning[0].message);
                if (exceptionMessageObject.code === VmdConstants.ESIGN_FUNCTIONAL_ERROR_EMAIL_NOT_VALID) {
                    this.emailError = true;
                }
            }
            this.closeConfirmPopup(true);
        }
    }

    setEsignUrl(url): void {
        $('#modal-esign .modal-body iframe').prop('src', url);
    }

    setLoading(loading: boolean): void {
        this.loading = loading;
        this.formService.emitIsSaving(loading);
        this.modalService.emitEsignLoading(loading);
    }

    isCaisseContext(): boolean {
        return this.formService.isCaisseContext();
    }

    getEsignContactInformationUserPhoneLabel(): string {
        if (this.isCaisseContext()) {
            return 'ESIGN_CONTACT_INFORMATION_USER_PHONE_CAISSE';
        } else {
            return 'ESIGN_CONTACT_INFORMATION_USER_PHONE';
        }
    }

    isEmailError(): boolean {
        return this.emailError;
    }

    isErrorDetected(): boolean {
        if (this.error) {
            return true;
        } else {
            return false;
        }
    }
}
