import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {ListModelService} from '../../components/services/list-model.service';
import {NavigationService} from '../../components/services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {AttachedDocument, WebException} from '../../components/models';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {FileValidatorService} from '../../components/services/file-validator.service';
import {AttachedDocumentService} from '../../components/services/attached-document.service';

declare let $: any;

const ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME = 'userIdentityFile';
const ATTACHED_DOCUMENT_CHECK_SAMPLE_NAME = 'userSpecimenFile';
const ATTACHED_DOCUMENT_ALL_FILES_NAME = 'userAllFiles';
const MAX_ATTACHED_DOCUMENTS_WEB = 2;
const MAX_ATTACHED_DOCUMENTS_CAISSE = 2;

@Component({
    selector: 'app-transmission-attachments',
    templateUrl: './transmission-attachments.component.html'
})
export class TransmissionAttachmentsComponent extends BaseNavComponent implements OnInit {

    userIdentityCodeList: HtmlSelectKV[];
    userIdentityFile: string;
    userSpecimenFile: string;
    accountStatement: string;
    loading: boolean;

    attachedDocumentTypeList: HtmlSelectKV[] = [
        {itemValue: ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME, itemLabel: 'TRANSMISSION_ATTACHED_DOCUMENT_ID_DOC'},
        {itemValue: ATTACHED_DOCUMENT_CHECK_SAMPLE_NAME, itemLabel: 'TRANSMISSION_ATTACHED_DOCUMENT_CHECK_LABEL'},
        {itemValue: ATTACHED_DOCUMENT_ALL_FILES_NAME, itemLabel: 'TRANSMISSION_ATTACHED_DOCUMENT_ALL_FILES_LABEL'}
    ];

    private allowedTypes = [
        ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME,
        ATTACHED_DOCUMENT_CHECK_SAMPLE_NAME,
        ATTACHED_DOCUMENT_ALL_FILES_NAME
    ];

    currentIndex = 0;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private fileValidatorService: FileValidatorService,
                private listModelService: ListModelService,
                private attachedDocumentService: AttachedDocumentService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.formService.setTransmitted(false);

        this.navigationService.emitIsBackDisabled(true);

        this.form = this.fb.group({
            userIdentityCode: [this.formService.getForm().requesters[this.requesterIndex].userIdentityCode, [this.requiredValidator('userIdentityCode')]],

            attachedDocuments: this.fb.array([], VmdValidators.attachedDocumentUnique())
        });

        this.initAttachedDocumentForm();

        const identityPaperListId = this.isGpdOrSfd() ? 'identityPaperGpdList' : 'identityPaperList'
        this.listModelService.getListModel(identityPaperListId, (data: HtmlSelectKV[]) => this.setTypeDocumentLists(data));

        this.attachedDocumentService.loadedEmitted.subscribe(() => {

            this.mergeAttachedFiles();
        });
    }

    private mergeAttachedFiles() {
        this.formService.getForm().userIdentityFile = null;
        this.formService.getForm().userSpecimenFile = null;
        this.formService.getForm().userAllFiles = null;

        this.getAttachedDocumentsControl().controls.forEach((control, index) => {

            const name = control.get('attachedDocumentName').value;

            if (this.allowedTypes.indexOf(name) !== -1) {
                this.formService.getForm()[name] = control.get('attachedDocumentForm').value;
                this.formService.getForm()[name].type = name;
            }
        });
    }

    private initAttachedDocumentForm(): void {

        if (this.isCaisseContext()) {
            this.initAttachedDocumentFormCaisse();
        } else {
            this.initAttachedDocumentFormWeb();
        }
    }

    private initAttachedDocumentFormCaisse(): void {

        const attachedDocumentNames = [
            ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME,
            ATTACHED_DOCUMENT_CHECK_SAMPLE_NAME,
            ATTACHED_DOCUMENT_ALL_FILES_NAME
        ];

        for (const name of attachedDocumentNames) {

            if (this.isAttachedDocumentNotEmpty(this.formService.getForm()[name])) {
                if (this.getAttachedDocumentsControl().length < MAX_ATTACHED_DOCUMENTS_CAISSE) {
                    this.addAttachedDocumentForm(name);
                }
            }
        }

        if (0 === this.getAttachedDocumentsControl().length) {
            this.addAttachedDocumentForm();
        }
    }

    private isAttachedDocumentNotEmpty(attachedDocument: AttachedDocument): boolean {
        return null !== attachedDocument
            && null !== attachedDocument.name
            && null !== attachedDocument.content;
    }

    private initAttachedDocumentFormWeb(): void {

        this.addAttachedDocumentForm(ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME);
        this.addAttachedDocumentForm(ATTACHED_DOCUMENT_CHECK_SAMPLE_NAME);
    }

    getAttachedDocumentsControl(): FormArray {
        return this.form.get('attachedDocuments') as FormArray;
    }

    addAttachedDocumentForm(name: string = null): void {

        if (this.canAddAttachedDocument()) {

            const attachedDocument = name ? this.formService.getForm()[name] : new AttachedDocument();

            const validators = this.getAttachedDocumentValidators(name);

            const attachedDocumentGroup = this.fb.group({
                attachedDocument: [attachedDocument.name, validators],
                attachedDocumentContent: [attachedDocument.content],
                attachedDocumentContentHash: [attachedDocument.contentHash],
                attachedDocumentFile: [null],
                attachedDocumentForm: [attachedDocument],
                attachedDocumentName: [name, Validators.required]
            });

            this.getAttachedDocumentsControl().push(attachedDocumentGroup);

            this.addAttachedDocumentValueChanges(attachedDocumentGroup);
        }
    }

    private canAddAttachedDocument(): boolean {
        const attachedDocumentsControl = this.getAttachedDocumentsControl();
        return attachedDocumentsControl.length < this.getMaxAttachedDocuments();
    }

    private getMaxAttachedDocuments(): number {
        return this.isCaisseContext()
            ? MAX_ATTACHED_DOCUMENTS_CAISSE
            : MAX_ATTACHED_DOCUMENTS_WEB;
    }

    private getAttachedDocumentValidators(name: string): ValidatorFn[] {
        return [this.requiredValidator(name), VmdValidators.fileFormatValidator(['pdf', 'jpeg', 'jpg', 'png'])];
    }

    private addAttachedDocumentValueChanges(attachedDocumentGroup: FormGroup): void {
        attachedDocumentGroup.get('attachedDocumentName').valueChanges.subscribe(value => {
            const validators = this.getAttachedDocumentValidators(value);
            attachedDocumentGroup.get('attachedDocument').setValidators(validators);

            if (!attachedDocumentGroup.get('attachedDocument').value) {
                this.clearControls(attachedDocumentGroup);
            }

            this.mergeAttachedFiles();
        });
    }

    removeAttachedDocumentForm(index: number) {
        if (this.canRemoveAttachedDocument()) {
            this.getAttachedDocumentsControl().removeAt(index);
        }
    }

    private canRemoveAttachedDocument(): boolean {
        return this.isCaisseContext() && this.getAttachedDocumentsControl().length > 1;
    }

    isCaisseContext(): boolean {
        return this.formService.isCaisseContext();
    }

    setTypeDocumentLists(list: HtmlSelectKV[]) {
        this.userIdentityCodeList = list;
        if (this.isHealthInsuranceException()) {

            this.userIdentityCodeList = this.userIdentityCodeList.filter(item => item.itemValue !== this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD);
        }
    }

    isHealthInsuranceException(): boolean {
        for (const province of this.constants.PROVINCES_HEALTH_INSURANCE_EXCEPTION) {
            if (this.formService.getForm().requesters[this.requesterIndex].userAddressProv === province) {
                return true;
            }
        }
        return false;
    }

    getInputFileLabelKey(index: number): string {

        if (this.isCaisseContext()) {
            return 'TRANSMISSION_ATTACHED_DOCUMENT_GENERIC_LABEL';
        }

        const name = this.getAttachedDocumentItemName(index);

        if (ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME === name) {
            return 'TRANSMISSION_ATTACHED_DOCUMENT_ID_DOC';
        }

        if (ATTACHED_DOCUMENT_CHECK_SAMPLE_NAME === name) {
            return 'TRANSMISSION_ATTACHED_DOCUMENT_CHECK_LABEL';
        }

        return null;
    }

    onFileChange(event, index: number): void {

        this.attachedDocumentService.onFileChange(
            event,
            this.form.get('attachedDocuments.' + this.currentIndex + '.attachedDocumentForm').value as AttachedDocument,
            this.form.get('attachedDocuments.' + this.currentIndex + '.attachedDocument'),
            this.form.get('attachedDocuments.' + this.currentIndex + '.attachedDocumentContent'),
            this.form.get('attachedDocuments.' + this.currentIndex + '.attachedDocumentContentHash')
        );
    }

    removeFile(index: number) {

        const name = this.getAttachedDocumentItemName(index);
        if (this.allowedTypes.indexOf(name) !== -1) {
            this.formService.getForm()[name] = new AttachedDocument();
        }

        this.clearControls(this.form.get('attachedDocuments.' + index));
    }

    private clearControls(control: AbstractControl) {

        control.get('attachedDocument').setValue(null);
        control.get('attachedDocumentContent').setValue(null);
        control.get('attachedDocumentContentHash').setValue(null);
        control.get('attachedDocumentFile').setValue(null);

        control.get('attachedDocumentForm').setValue(new AttachedDocument());
    }

    selectFile(index: number) {
        this.currentIndex = index;
        const name = this.getAttachedDocumentItemName(index) + 'Upload';

        $('input[name="' + name + '"],select[name="' + name + '"]').first().click();
        return false;
    }

    private getAttachedDocumentItemName(index: number): string {
        const control = this.form.get('attachedDocuments.' + index + '.attachedDocumentName');

        if (control) {
            return control.value;
        }

        return null;
    }

    submit() {
        const identityFileList: AttachedDocument[] = [];
        if (this.form.valid) {

            this.getAttachedDocumentsControl().controls.forEach((control, index) => {
                const name = control.get('attachedDocumentName').value;
                if (control.get('attachedDocument').value && control.get('attachedDocumentContent').value) {
                    const accountStatement = new AttachedDocument();
                    accountStatement.name = control.get('attachedDocument').value;
                    accountStatement.content = control.get('attachedDocumentContent').value;
                    accountStatement.contentHash = control.get('attachedDocumentContentHash').value;
                    accountStatement.id = name;
                    identityFileList.push(accountStatement);
                }
            });
        }

        if (identityFileList.length) {
            this.formSubmitted = true;
            this.markAsTouched(this.form);
            this.loading = true;
            this.fileValidatorService.validateFile(identityFileList, 'api/verifyIdentityFile').subscribe(data => {
                if (this.form.valid) {
                    this.loading = false;
                    this.submitForm();
                }
            }, (errors: WebException) => {
                this.loading = false;

                if (errors.listWarning && errors.listWarning.length) {
                    for (const warning of errors.listWarning) {

                        const path = this.getAttachedDocumentsPath(warning.id);

                        if (warning.message === 'file.infected') {
                            this.form.get(path).setErrors({fileInfected: true});
                        } else if (warning.message === 'antivirus.notavailable') {
                            this.form.get(path).setErrors({antivirusNotAvailable: true});
                        } else if (warning.message === 'file.weight.invalid') {
                            this.form.get(path).setErrors({weightInvalid: true});
                        } else if (warning.message === 'file.pdf.encrypted') {
                            this.form.get(path).setErrors({fileEncrypted: true});
                        } else if (warning.message === 'file.extension.invalid') {
                            this.form.get(path).setErrors({invalidFormat: true});
                        } else if (warning.message === 'file.invalid.document') {
                            this.form.get(path).setErrors({invalidDocument: true});
                        }
                    }
                }
                this.focusOnErrorsList();
            });
        } else {
            this.submitForm();
        }

    }

    private getAttachedDocumentsPath(name: string): string {
        let path = null;

        this.getAttachedDocumentsControl().controls.forEach((control, index) => {
            if (control.get('attachedDocumentName').value === name) {
                path = 'attachedDocuments.' + index + '.attachedDocument';
            }
        });

        return path;
    }

    displayAddAttachedDocumentButton(): boolean {
        return this.canAddAttachedDocument();
    }

    displayRemoveAttachedDocumentButton(): boolean {
        return this.canRemoveAttachedDocument();
    }

    isRequestSaving(): boolean {
        return this.formService.saveInProgressWithoutModal();
    }
}
