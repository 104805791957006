import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {Entity} from '../models/entity';
import {VmdConstants} from '../constants/vmd-constants';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {SessionService} from "./session.service";

declare let $: any;

@Injectable()
export class ModalService {

    private context: string = null;

    constructor(private translate: TranslateService,
                private sessionService: SessionService,
                @Inject(ODC_CONFIG) public config: IOdcConfig) {
    }

    private emitCloseModalAlertSource = new Subject<string>();
    closeModalAlertEmitted = this.emitCloseModalAlertSource.asObservable();

    private emitValidClickOnChoice1Source = new Subject<string>();
    validClickOnChoice1Emitted = this.emitValidClickOnChoice1Source.asObservable();

    private emitValidClickOnChoice2Source = new Subject<string>();
    validClickOnChoice2Emitted = this.emitValidClickOnChoice2Source.asObservable();

    private emitErrorSource = new Subject<string>();
    errorgEmitted = this.emitErrorSource.asObservable();

    private emitEsignLoadingSource = new Subject<boolean>();
    esignLoadingEmitted = this.emitEsignLoadingSource.asObservable();

    private emitEsignClosePopupSource = new Subject<string>();
    esignClosePopupEmitted = this.emitEsignClosePopupSource.asObservable();

    private emitEsignCloseConfirmPopupSource = new Subject<boolean>();
    esignCloseConfirmPopupEmitted = this.emitEsignCloseConfirmPopupSource.asObservable();

    private emitOpenVideoPopupSource = new Subject<{ id: string, title: string }>();
    openVideoPopupEmitted = this.emitOpenVideoPopupSource.asObservable();

    private emitPdfLinkSource = new Subject<boolean>();
    pdfLinkEmitted = this.emitPdfLinkSource.asObservable();

    private emitPdfLoadingSource = new Subject<boolean>();
    pdfLoadingEmitted = this.emitPdfLoadingSource.asObservable();

    private emitValidClickOnPlatformBackSource = new Subject<string>();
    validClickOnPlatformBack = this.emitValidClickOnPlatformBackSource.asObservable();

    private emitValidClickOnPlatformContinueSource = new Subject<string>();
    validClickOnPlatformContinue = this.emitValidClickOnPlatformContinueSource.asObservable();

    private emitShowAuthenticatePasswordFormSource = new Subject<boolean>();
    showAuthenticatePasswordForm = this.emitShowAuthenticatePasswordFormSource.asObservable();

    private emitShowCreateProfileFormSource = new Subject<boolean>();
    showCreateProfileForm = this.emitShowCreateProfileFormSource.asObservable();

    private emitShowResetPasswordSource = new Subject<boolean>();
    showResetPassword = this.emitShowResetPasswordSource.asObservable();

    private emitShowAuthenticateTempPasswordSource = new Subject<boolean>();
    showAuthenticateTempPassword = this.emitShowAuthenticateTempPasswordSource.asObservable();

    private emitResetPasswordSource = new Subject<string>();
    resetPassword = this.emitResetPasswordSource.asObservable();

    private emitOpenAddEntityPopupSource = new Subject<Entity>();
    openAddEntityPopupEmitted = this.emitOpenAddEntityPopupSource.asObservable();

    emitCloseModalAlert(modalSelector) {
        this.emitCloseModalAlertSource.next(modalSelector);
    }

    emitValidClickOnChoice1(context: string = null) {
        if (null !== context) {
            this.context = context;
        }
        this.emitValidClickOnChoice1Source.next(this.context);
    }


    emitValidClickOnChoice2(context: string = null) {
        if (null !== context) {
            this.context = context;
        }
        this.emitValidClickOnChoice2Source.next(this.context);
    }

    emitError(value: string) {
        this.emitErrorSource.next(value);
    }

    emitEsignLoading(value: boolean) {
        this.emitEsignLoadingSource.next(value);
    }

    emitEsignClosePopup() {
        this.emitEsignClosePopupSource.next();
    }

    emitEsignCloseConfirmPopup(value: boolean) {
        this.emitEsignCloseConfirmPopupSource.next(value);
    }

    emitPdfLink(isAdditional: boolean = false) {
        this.emitPdfLinkSource.next(isAdditional);
    }

    emitPdfLoading(value: boolean) {
        this.emitPdfLoadingSource.next(value);
    }

    emitOpenVideoPopup(value: { id: string, title: string }) {
        this.emitOpenVideoPopupSource.next(value);
    }

    emitOpenAddEntity(entity: Entity) {
        this.emitOpenAddEntityPopupSource.next(entity);
    }

    emitValidClickOnPlatformBack() {
        this.emitValidClickOnPlatformBackSource.next();
    }

    emitValidClickOnPlatformContinue() {
        this.emitValidClickOnPlatformContinueSource.next();
    }

    emitShowAuthenticatePasswordForm(display: boolean) {
        this.emitShowAuthenticatePasswordFormSource.next(display);
    }

    emitShowCreateProfileForm(display: boolean) {
        this.emitShowCreateProfileFormSource.next(display);
    }

    emitShowResetPassword(display: boolean) {
        this.emitShowResetPasswordSource.next(display);
    }

    emitShowAuthenticateTempPassword(hasSecurityQuestion: boolean) {
        this.emitShowAuthenticateTempPasswordSource.next(hasSecurityQuestion);
    }

    emitResetPassword() {
        this.emitResetPasswordSource.next();
        return false;
    }

    openModal(titleI18nKey: string, bodyI18nKey: string, modalSelector = '#modal-alert',
              context: string = null, optionalText?: string, buttonsText?: string[]): void {

        this.context = context;

        if (!$(modalSelector).length) {
            return;
        }

        setTimeout(() => {

            $(modalSelector).on('hidden.bs.modal', () => {
                this.emitCloseModalAlert(modalSelector);
            });

            if (titleI18nKey && bodyI18nKey) {
                this.translate.get(titleI18nKey).subscribe(title => {
                    this.translate.get(bodyI18nKey).subscribe(body => {
                        $(modalSelector + ' .modal-title').html(title);
                        $(modalSelector + ' .modal-body').html(body);
                    });
                });
            }

            if (buttonsText && buttonsText.length > 0) {
                const primaryButtonI18nKey = buttonsText[0];
                const defaultButtonI18nKey = buttonsText[1];

                if (primaryButtonI18nKey) {
                    this.translate.get(primaryButtonI18nKey).subscribe(buttonText => {
                        $(modalSelector + ' .btn-primary').attr('title', buttonText);
                        $(modalSelector + ' .btn-primary').html(buttonText);
                    });
                }

                if (defaultButtonI18nKey) {
                    this.translate.get(defaultButtonI18nKey).subscribe(buttonText => {
                        $(modalSelector + ' .btn-default').attr('title', buttonText);
                        $(modalSelector + ' .btn-default').html(buttonText);
                    });
                }
            }

            if (optionalText) {
                $(modalSelector + ' .modal-specific-text').html(optionalText);
            }

            $(modalSelector).modal({
                backdrop: 'static'
            });

            $(modalSelector).modal('show');
        });
    }

    showCuratorshipResidentQuebecModal() {
        this.openModal('CURATORSHIP_RESIDENT_OF_QUEBEC_TITLE',
            'CURATORSHIP_RESIDENT_OF_QUEBEC_BODY',
            '#modal-alert-generic', 'MODAL_MODIFY_CURATORSHIP_CONTEXT',
            null,
            ['BTN_CHANGE', 'BTN_MODIFY']);
    }

    triggerEnglishQuebecResidentModal() {
        const modalAlreadyTriggered = sessionStorage.getItem(VmdConstants.ENGLISH_QUEBEC_RESIDENT_MODAL_TRIGGERED_SESSION_KEY) === true.toString();

        if (this.config.TOGGLE_PDF_LANG && !this.config.IS_PLEIN_EX &&
            this.sessionService.getOrigin() === 'WEB' && !modalAlreadyTriggered) {
            const isEnglishSpeaker = this.translate.currentLang === VmdConstants.LANG_EN;

            if (isEnglishSpeaker) {

                this.openModal(
                    'ENGLISH_QUEBEC_RESIDENTS_MODAL_TITLE',
                    'ENGLISH_QUEBEC_RESIDENTS_MODAL_MESSAGE',
                    '#modal-alert',
                    null,
                    null,
                    ['BTN_OK']
                );

                sessionStorage.setItem(VmdConstants.ENGLISH_QUEBEC_RESIDENT_MODAL_TRIGGERED_SESSION_KEY, true.toString());
            }
        }
    }
}
