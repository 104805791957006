import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../../questionnaire/base-nav.component';
import {FormBuilder, Validators} from '@angular/forms';
import {NavigationService} from '../../../../components/services/navigation.service';
import {ModalService} from '../../../../components/services/modal.service';
import {FormService} from '../../../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {HtmlSelectKV} from '../../../../components/models/html-select-k-v';
import {ListModelService} from '../../../../components/services/list-model.service';
import {ProfileService} from '../../../../components/services/profile.service';
import {ProfileBean} from '../../../../components/models/ProfileBean';
import {VmdConstants} from '../../../../components/constants/vmd-constants';
import {VmdValidators} from '../../../../components/validators/vmd-validators';
import {SessionService} from '../../../../components/services/session.service';

declare let $: any;

@Component({
    selector: 'app-modal-password',
    templateUrl: './modal-password.component.html'
})
export class ModalPasswordComponent extends BaseNavComponent implements OnInit {

    protected questionSecuriteList: HtmlSelectKV[];
    protected questionSecurite: HtmlSelectKV;
    public displayAuthenticatePasswordGroup = false;
    public displayCreateProfileGroup = false;
    public displayResetPasswordGroup = false;
    public displayAuthenticateTempPasswordGroup = false;
    public displayCustomerService = false;
    protected customerServiceMsg: string;
    private passwordAttempt = 0;
    private resetPasswordAttempt = 0;
    protected loading = false;
    public errMsg: string = null;
    protected showEmailSend = true;
    protected showInputPassword = true;

    constants: any = VmdConstants;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected modalService: ModalService,
                private profileService: ProfileService,
                private listModelService: ListModelService,
                private sessionService: SessionService) {
        super(navigationService, fb, formService, translate);

    }

    ngOnInit() {

        this.form = this.fb.group({

            authenticatePasswordGroup: this.fb.group({
                password: [null, [Validators.required]]
            }),

            createProfileGroup: this.fb.group({
                newProfileSecurityQuestion: [null, [Validators.required]],
                newProfileSecurityAnswer: [null, [Validators.required, Validators.minLength(5)]],
                newPassword: [null, [Validators.required, Validators.minLength(VmdConstants.PASSWORD_LENGTH_MIN)]],
                confirmNewPassword: [null, [Validators.required]]
            }, {validator: VmdValidators.passwordMatchValidator()}),

            resetPasswordGroup: this.fb.group({
                resetPasswordSecurityAnswer: [null, [Validators.required, Validators.minLength(5)]]
            }),

            authenticateTempPasswordGroup: this.fb.group({
                oldPassword: [null, [Validators.required]],
                newPassword: [null, [Validators.required, Validators.minLength(VmdConstants.PASSWORD_LENGTH_MIN)]],
                confirmNewPassword: [null, [Validators.required]]
            }, {validator: VmdValidators.passwordMatchValidator()}),

            passwordStrength: [null, [Validators.min(VmdConstants.PASSWORD_STRENGTH_MIN)]]
        });

        this.listModelService.getListModel('questionSecuriteList', data => this.questionSecuriteList = data);

        this.modalService.showAuthenticatePasswordForm.subscribe((value: boolean) => {
            this.resetModal();
            if (this.passwordAttempt >= 3 || this.resetPasswordAttempt >= 3) {
                this.displayCustomerService = true;
                this.customerServiceMsg = 'PROFIL_SERVICE_CLIENTELE';
            } else {
                this.displayAuthenticatePasswordGroup = value;
                this.updateControlEnabled(this.form.get('authenticatePasswordGroup'), true);
            }
        });

        this.modalService.showCreateProfileForm.subscribe((value: boolean) => {
            this.resetModal();
            this.displayCreateProfileGroup = value;
            this.updateControlEnabled(this.form.get('createProfileGroup'), true);
            this.updateControlEnabled(this.form.get('passwordStrength'), true);
        });

        this.modalService.showAuthenticateTempPassword.subscribe((hasSecurityQuestion: boolean) => {
            this.resetModal();
            this.showEmailSend = hasSecurityQuestion;
            this.displayAuthenticateTempPasswordGroup = true;
            this.updateControlEnabled(this.form.get('authenticateTempPasswordGroup'), true);
            this.updateControlEnabled(this.form.get('passwordStrength'), true);
        });

        this.modalService.showResetPassword.subscribe((value: boolean) => {

            if (this.resetPasswordAttempt >= 3) {
                this.showCustomerService();
            } else {
                this.getSecurityQuestion();
            }
        });

        this.modalService.resetPassword.subscribe(() => {
            this.getSecurityQuestion();
        });
    }

    showCustomerService(text: string = 'PROFIL_SERVICE_CLIENTELE') {
        this.resetModal();
        this.customerServiceMsg = text;
        this.displayCustomerService = true;
    }

    submitModalForm() {
        this.markAsTouched(this.form);
        if (this.form.valid) {
            if (this.displayAuthenticatePasswordGroup) {
                this.authenticatePassword();
            } else if (this.displayCreateProfileGroup) {
                this.createProfile();
            } else if (this.displayResetPasswordGroup) {
                this.resetPassword();
            } else if (this.displayAuthenticateTempPasswordGroup) {
                this.authenticateTempPassword();
            }
        }
    }

    resetModal() {
        this.errMsg = null;
        this.displayAuthenticatePasswordGroup = false;
        this.updateControlEnabled(this.form.get('authenticatePasswordGroup'), false);
        this.clearAuthenticatePasswordGroupValue();

        this.displayCreateProfileGroup = false;
        this.updateControlEnabled(this.form.get('createProfileGroup'), false);
        this.clearCreateProfileGroupValue();

        this.displayResetPasswordGroup = false;
        this.updateControlEnabled(this.form.get('resetPasswordGroup'), false);
        this.clearResetPasswordGroupValue();

        this.displayAuthenticateTempPasswordGroup = false;
        this.updateControlEnabled(this.form.get('authenticateTempPasswordGroup'), false);
        this.clearAuthenticatePasswordTempGroupValue();

        this.updateControlEnabled(this.form.get('passwordStrength'), false);
        this.form.get('passwordStrength').setValue(null);

        this.displayCustomerService = false;
        this.showInputPassword = true;
    }

    clearAuthenticatePasswordGroupValue() {
        this.form.get('authenticatePasswordGroup.password').setValue(null);
    }

    clearCreateProfileGroupValue() {
        this.form.get('createProfileGroup.newPassword').setValue(null);
        this.form.get('createProfileGroup.confirmNewPassword').setValue(null);
        this.form.get('createProfileGroup.newProfileSecurityQuestion').setValue(null);
        this.form.get('createProfileGroup.newProfileSecurityAnswer').setValue(null);
    }

    clearResetPasswordGroupValue() {
        this.form.get('resetPasswordGroup.resetPasswordSecurityAnswer').setValue(null);
    }

    clearAuthenticatePasswordTempGroupValue() {
        this.form.get('authenticateTempPasswordGroup.oldPassword').setValue(null);
        this.form.get('authenticateTempPasswordGroup.newPassword').setValue(null);
        this.form.get('authenticateTempPasswordGroup.confirmNewPassword').setValue(null);
    }

    getSecurityQuestion() {
        this.profileService.getSecurityQuestion().subscribe(
            (data) => {
                this.onGetSecurityQuestionSuccess(data);
            }, (error) => {
                this.onGetSecurityQuestionError();
            });
    }

    onPasswordStrengthChanges(value: number) {
        if (value != null) {
            this.form.get('passwordStrength').setValue(value);
        }
    }

    onGetSecurityQuestionSuccess(data: any) {
        this.resetModal();
        if (data != null && data.payload != null) {
            this.displayResetPasswordGroup = true;
            this.updateControlEnabled(this.form.get('resetPasswordGroup'), true);
            this.questionSecurite = this.questionSecuriteList.filter((item: HtmlSelectKV) => item.itemValue === data.payload)[0];
        } else {
            this.showCustomerService('PROFIL_QUESTION_SERVICE_CLIENTELE');
        }
    }

    onGetSecurityQuestionError() {
        this.showCustomerService('PROFIL_QUESTION_SERVICE_CLIENTELE');
    }

    authenticatePassword() {
        this.loading = true;
        this.profileService.authenticatePassword(this.form.get('authenticatePasswordGroup.password').value).subscribe(
            (data) => {
                this.sessionService.beginSession(data.payload);
                this.onAuthenticatePasswordSuccess();
            },
            (error) => {
                this.onAuthenticatePasswordError(error);
            }
        );
    }

    onAuthenticatePasswordSuccess() {
        this.loading = false;
        this.profileService.emitSave();
        this.closeModal();
    }

    onAuthenticatePasswordError(error) {
        this.loading = false;
        this.passwordAttempt += 1;
        if (this.passwordAttempt >= 3) {
            this.showInputPassword = false;
        } else if (error.listWarning.length) {
            this.errMsg = error.listWarning[0].message;
        } else if (error.listError.length) {
            this.errMsg = error.listError[0].message;
        }
    }

    createProfile() {
        this.loading = true;
        const profileBean = new ProfileBean();
        profileBean.email = this.formService.getForm().requesters[0].userEmail;
        profileBean.password = this.form.get('createProfileGroup.newPassword').value;
        profileBean.confirmPassword = this.form.get('createProfileGroup.confirmNewPassword').value;
        profileBean.securityQuestion = this.form.get('createProfileGroup.newProfileSecurityQuestion').value;
        profileBean.securityAnswer = this.form.get('createProfileGroup.newProfileSecurityAnswer').value;
        this.profileService.createProfile(profileBean).subscribe(
            (data) => {
                this.onCreateProfileSuccess();
            },
            (error) => {
                this.onCreateProfileError(error);
            }
        );
    }

    onCreateProfileSuccess() {
        this.loading = false;
        this.profileService.emitSave();
        this.closeModal();
    }

    onCreateProfileError(error) {
        this.loading = false;
        if (error.listWarning.length) {
            this.errMsg = error.listWarning[0].message;
        } else if (error.listError.length) {
            this.errMsg = error.listError[0].message;
        }
    }

    resetPassword() {
        this.loading = true;
        this.profileService.resetPassword(this.formService.getForm().lang, this.form.get('resetPasswordGroup.resetPasswordSecurityAnswer').value).subscribe(
            (data) => {
                this.onResetPasswordSuccess();
            }, (error) => {
                this.onResetPasswordError(error);
            });
    }

    onResetPasswordSuccess() {
        this.loading = false;
        this.resetModal();
        this.displayAuthenticateTempPasswordGroup = true;
        this.updateControlEnabled(this.form.get('authenticateTempPasswordGroup'), true);
        this.updateControlEnabled(this.form.get('passwordStrength'), true);
    }

    onResetPasswordError(error) {
        this.loading = false;
        this.resetPasswordAttempt += 1;
        if (this.resetPasswordAttempt >= 3) {
            this.displayResetPasswordGroup = false;
            this.customerServiceMsg = 'PROFIL_SERVICE_CLIENTELE';
            this.displayCustomerService = true;
        } else if (error.listWarning.length) {
            this.errMsg = error.listWarning[0].message;
        } else if (error.listError.length) {
            this.errMsg = error.listError[0].message;
        }

    }

    authenticateTempPassword() {
        this.loading = true;
        this.profileService.authenticateTempPassword(this.form.get('authenticateTempPasswordGroup.oldPassword').value, this.form.get('authenticateTempPasswordGroup.newPassword').value, this.form.get('authenticateTempPasswordGroup.confirmNewPassword').value).subscribe(
            (data) => {
                this.sessionService.beginSession(data.payload);
                this.onAuthenticateTempPasswordSuccess();
            }, (error) => {
                this.onAuthenticateTempPasswordError(error);
            });
    }

    onAuthenticateTempPasswordSuccess() {
        this.loading = false;
        this.profileService.emitSave();
        this.closeModal();
    }

    onAuthenticateTempPasswordError(error) {
        this.loading = false;
        if (error.listWarning.length) {
            this.errMsg = error.listWarning[0].message;
        } else if (error.listError.length) {
            this.errMsg = error.listError[0].message;
        }
    }

    closeModal() {
        if (!$('#modal-password').length) {
            return;
        }
        this.ngForm.resetForm();
        $('#modal-password').modal('hide');
    }

    clearErrMsg() {
        this.errMsg = null;
    }
}
