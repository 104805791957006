export const I18N_ERROR_MESSAGES_FR: any = {
    GLOBAL_ERROR_MSG: 'Une erreur est survenue, veuillez réessayer.',
    SERVER_400: 'ERREUR',
    SERVER_400_DESCRIPTION: 'Une erreur s\'est produite lors du traitement de votre demande.',
    SERVER_404: 'PAGE NON TROUVÉE',
    SERVER_404_DESCRIPTION: 'Les liens ci-dessous vous aideront peut-être à trouver l\'information que vous recherchez.',
    SERVER_500: 'ERREUR',
    SERVER_500_DESCRIPTION: 'Une erreur s\'est produite lors du traitement de votre demande.',

    GLOBAL_ERROR_MSG_MAX_LENGTH: 'La longueur maximale pour ce champ est de {{maxlength}} caractères',
    GLOBAL_ERROR_CHOOSE_AN_OPTION: 'Choisir une option',
    GLOBAL_ERROR_SELECT: 'Veuillez sélectionner une réponse',

    SERVER_BRANCH_PDO_ERROR_TITLE: 'Attention',
    SERVER_BRANCH_ERROR: 'Erreur',
    SERVER_BRANCH_ERROR_DESCRIPTION: 'Une erreur s\'est produite lors du traitement de votre demande.',
    SERVER_PDO_ERROR_DESCRIPTION: 'La recherche du membre au préalable est obligatoire.',

    QUERY_CLIENT_CODE_ERROR_TITLE: 'Attention',
    QUERY_DECRYPTION_ERROR: 'Erreur',
    QUERY_DECRYPTION_ERROR_DESCRIPTION: 'Une erreur s\'est produite lors du décryptage de votre demande.',
    QUERY_CLIENT_CODE_ERROR_DESCRIPTION: 'La recherche du code du client au préalable est obligatoire.',

    ODC_ADD_ACCOUNT_AUTO_ERR_HOLD_ALL_ACCOUNT: 'Le client détient déjà tous les types de comptes qu\'il est possible d’ajouter pour le moment.',
    ODC_ADD_ACCOUNT_AUTO_ERR_HOLD_TFSA_CLOSED: 'Le client a déjà été détenteur d\'un compte CELI, qui a été fermé par la suite. Il est impossible d\'ouvrir à nouveau ce type de compte dans la même racine.',
    ODC_ADD_ACCOUNT_AUTO_ERR_ADD_REQUEST_CREATED: 'Une demande d\'ajout de compte a déjà été créée pour ce client. Veuillez reprendre la demande {{pendingrequest}} pour la terminer.',
    ODC_ADD_ACCOUNT_AUTO_ERR_HOLD_DISCRETIONARY_ACCOUNT: 'Le compte du client est carte blanche. Il est impossible de faire un ajout de compte par le bureau « Ouverture de compte ».',
    ODC_ADD_ACCOUNT_AUTO_ERR_GUARANTEED_BY_FINANCIAL_INSTITUTION: 'Le compte du client est en garantie avec une institution financière. Il est impossible de faire un ajout de compte par le bureau « Ouverture de compte ».',
    ODC_ADD_ACCOUNT_AUTO_ERR_NOT_PERSONAL_ACCOUNT: 'Le compte du client n\'est pas un compte personnel. Par conséquent, il est impossible d\'ajouter un compte CELI.',

    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_ERROR: 'Veuillez sélectionner une réponse.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_TYPE_ERROR: 'Veuillez sélectionner une réponse.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_BENEFICIARY_ERROR: ': Veuillez saisir un nombre.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_SUBSCRIBER_ERROR: 'Veuillez sélectionner une réponse.',

    ODC_LOGIN_ERR_INVALID_EMAIL: 'L\'adresse électronique est invalide.',
    ODC_LOGIN_ERR_MISSING_INFO: 'Vous devez entrer votre adresse électronique et votre mot de passe.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_ESIGN: 'La demande que vous tentez de récupérer n\'est pas disponible pour le moment. Veuillez réessayer plus tard.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_NOT_FOUND: 'Les informations entrées sont erronées. Veuillez les vérifier et réessayer.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_RECEIVED: 'La demande a déjà été reçue chez Valeurs mobilières Desjardins. Veuillez communiquer avec le service à la clientèle.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_COMPLETE_OBSOLETE: 'La demande complétée que vous tentez de reprendre est expirée. Veuillez remplir une nouvelle demande ou communiquer avec notre service à la clientèle.',
    ODC_LOGIN_ERR_QUESTIONNAIRE_CURRENT_OBSOLETE: 'La demande en cours que vous tentez de reprendre est expirée. Veuillez refaire une nouvelle demande ou contacter le service à la clientèle.',
    ODC_LOGIN_ERR_LIST_OBSOLETE: 'Les demandes que vous tentez de reprendre sont expirées. Veuillez remplir une nouvelle demande ou communiquer avec notre service à la clientèle.',

    ERROR_FIELD_accountNumberCad: 'Numéro de compte SFD (CAD)',
    ERROR_FIELD_accountNumberCad_required: 'Veuillez indiquer un numéro de compte SFD.',
    ERROR_FIELD_accountNumberCad_invalidRange: 'Veuillez indiquer un numéro de compte SFD valide.',
    ERROR_FIELD_productNumberCad: 'Numéro de produit SFD (CAD)',
    ERROR_FIELD_productNumberCad_required: 'Veuillez indiquer un numéro de produit SFD.',
    ERROR_FIELD_productNumberCad_invalidRange: 'Veuillez indiquer un numéro de produit SFD valide.',
    ERROR_FIELD_accountNumberUsd: 'Numéro de compte SFD (USD)',
    ERROR_FIELD_accountNumberUsd_required: 'Veuillez indiquer un numéro de compte SFD.',
    ERROR_FIELD_accountNumberUsd_invalidRange: 'Veuillez indiquer un numéro de compte SFD valide.',
    ERROR_FIELD_productNumberUsd: 'Numéro de produit SFD (USD)',
    ERROR_FIELD_productNumberUsd_required: 'Veuillez indiquer un numéro de produit SFD.',
    ERROR_FIELD_productNumberUsd_invalidRange: 'Veuillez indiquer un numéro de produit SFD valide.',

    ERROR_FIELD_courriel: 'Adresse électronique',
    ERROR_FIELD_courriel_required: 'Vous devez entrer votre adresse électronique.',
    ERROR_FIELD_courriel_email: 'L\'adresse électronique est invalide.',
    ERROR_FIELD_motDePasse: 'Mot de passe',
    ERROR_FIELD_motDePasse_required: 'Vous devez entrer votre mot de passe.',

    ERROR_FIELD_email: 'Adresse électronique',
    ERROR_FIELD_email_required: 'Vous devez entrer votre adresse électronique.',
    ERROR_FIELD_email_email: 'L\'adresse électronique est invalide.',
    ERROR_FIELD_reply: 'Réponse',
    ERROR_FIELD_reply_required: 'Vous devez entrer votre réponse.',
    ODC_RESET_PASSWORD_ERR_INVALID_EMAIL: 'L\'adresse électronique est invalide.',
    ODC_RESET_PASSWORD_ERR_MISSING_EMAIL: 'Vous devez entrer votre adresse électronique.',
    ODC_RESET_PASSWORD_ERR_CLIENTPROFILE_NOT_FOUND: 'L\'adresse électronique que vous avez saisie n\'est pas valide.',
    ODC_RESET_PASSWORD_ERR_INVALID_PROFILE: 'Vous ne pouvez pas réinitialiser votre mot de passe en ligne. Veuillez communiquer avec le service à la clientèle.',
    ODC_RESET_PASSWORD_ERR_MISSING_REPLY: 'Vous devez entrer votre réponse.',
    ODC_RESET_PASSWORD_ERR_REPLY: 'La réponse que vous avez saisie n\'est pas valide.',
    ODC_RESET_PASSWORD_ERR_PASSWORD: 'Le mot de passe temporaire que vous avez saisi n\'est pas valide.',
    ODC_RESET_PASSWORD_ERR_CONTACT_CLIENT_SERVICE: 'Veuillez communiquer avec le service à la clientèle.',
    ODC_RESET_PASSWORD_ERR_MISSING_OLD_PASSWORD: 'Vous devez entrer votre mot de passe temporaire.',
    ODC_RESET_PASSWORD_ERR_MISSING_NEW_PASSWORD: 'Vous devez entrer votre nouveau mot de passe.',
    ODC_RESET_PASSWORD_ERR_MISSING_CONFIRM_NEW_PASSWORD: 'Vous devez entrer votre confirmation de mot de passe.',
    ODC_RESET_PASSWORD_ERR_INVALID_CONFIRM_NEW_PASSWORD: 'La confirmation du mot de passe ne correspond pas au nouveau mot de passe.',
    ODC_RESET_PASSWORD_ERR_EMPTY_ACCOUNT_OPENING_REQUEST_LIST: 'Aucune demande n\'est rattachée à ce profil',
    ODC_RESET_PASSWORD_ERR_INVALID_ACCOUNT: 'Vous ne pouvez pas réinitialiser votre mot de passe en ligne. Veuillez communiquer avec le service à la clientèle.',

    PASSWORD_CONFIRMPASSWORD_NOT_MATCH: 'La confirmation du mot de passe ne correspond pas au nouveau mot de passe.',
    TEMPORARY_PASSWORD_INVALID: 'Le mot de passe temporaire est invalide.',
    TEMPORARY_PASSWORD_EXPIRED: 'Votre mot de passe temporaire est expiré. Veuillez cliquer à nouveau sur "Mot de passe oublié".',
    SECURITY_ANSWER_INVALID: 'La réponse est invalide.',
    PASSWORD_INVALID: 'Le mot de passe est invalide.',
    RECAPTCHA_INVALID: 'Veuillez cocher la case «Je ne suis pas un robot» pour poursuivre.',
    PASSWORD_INVALID_HISTORY: 'Le mot de passe saisi a déjà été utilisé précédemment.\n' +
        'Veuillez choisir un nouveau mot de passe.',

    ERROR_SUMMARY_INTRO: 'Des erreurs ont été détectées dans les champs suivants :',

    ERROR_ESIGN: 'Le service de signature en ligne n’est pas disponible pour le moment.',
    ERROR_ESIGN_INVALID_EMAIL: 'L\'adresse électronique est invalide.',
    ERROR_ESIGN_DESCRIPTION: 'Vous pouvez prendre en note vos identifiants afin de reprendre votre session au moment désiré ou encore imprimer les documents, les signer, puis nous les retourner par la poste.',

    ERROR_FIELD_platformType_required: 'Choisir une option',

    ERROR_FIELD_borrowingReceivedAndReadBorrowingRisksIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_borrowingReceivedAndReadBorrowingRisksIndicator: 'Mises en garde - Stratégie à effet de levier',

    ERROR_FIELD_newExistAct: 'Type de demande',
    ERROR_FIELD_newExistAct_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_cltNo: 'Code d\'utilisateur',
    ERROR_FIELD_cltNo_required: 'Veuillez entrer un code d\'utilisateur valide.',
    ERROR_FIELD_cltNo_username: 'Veuillez entrer un code d\'utilisateur valide.',
    ERROR_FIELD_actType: 'Type de compte',
    ERROR_FIELD_actType_required: 'Veuillez sélectionner un type de compte.',
    ERROR_FIELD_currency: 'Devise du compte',
    ERROR_FIELD_currency_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_rrspCurrency: 'Devise du compte - REER',
    ERROR_FIELD_rrspCurrency_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_rrspCurrency_onlyOneAccountTypeInUSD: 'Il ne peut y avoir qu\'un seul REER en dollars américains.',
    ERROR_FIELD_rrspAnnuitant: 'Type de cotisation - REER',
    ERROR_FIELD_rrspAnnuitant_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_rrspCurrencySec: 'Devise du compte - REER',
    ERROR_FIELD_rrspCurrencySec_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_rrspCurrencySec_onlyOneAccountTypeInUSD: 'Il ne peut y avoir qu\'un seul REER en dollars américains.',
    ERROR_FIELD_rrspAnnuitantSec: 'Type de cotisation - REER',
    ERROR_FIELD_rrspAnnuitantSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_rrspCurrencyTer: 'Devise du compte - REER',
    ERROR_FIELD_rrspCurrencyTer_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_rrspCurrencyTer_onlyOneAccountTypeInUSD: 'Il ne peut y avoir qu\'un seul REER en dollars américains.',
    ERROR_FIELD_rrspAnnuitantTer: 'Type de cotisation - REER',
    ERROR_FIELD_rrspAnnuitantTer_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_tfsaCurrency: 'Devise du compte - CELI',
    ERROR_FIELD_tfsaCurrency_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_respType: 'Type de REEE',
    ERROR_FIELD_respType_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_respNumberOfBeneficiaries: 'Bénéficiaires REEE',
    ERROR_FIELD_respNumberOfBeneficiaries_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_respNumberOfBeneficiaries_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_respNumberOfBeneficiaries_min: 'Veuillez saisir un nombre entre 1 et 6.',
    ERROR_FIELD_respNumberOfBeneficiaries_max: 'Veuillez saisir un nombre entre 1 et 6.',
    ERROR_FIELD_respIsSubscriberResponsible: 'Souscripteur REEE',
    ERROR_FIELD_respIsSubscriberResponsible_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_liraCurrency: 'Devise du compte - CRI, CRIF, RERI ou REIR',
    ERROR_FIELD_liraCurrency_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_liraCurrency_onlyOneAccountTypeInUSD: 'Il ne peut y avoir qu\'un seul CRI, CRIF, RERI ou REIR en dollars américains.',
    ERROR_FIELD_liraJurisdiction: 'Juridiction du CRI, CRIF, RERI ou REIR',
    ERROR_FIELD_liraJurisdiction_required: 'Veuillez choisir une juridiction.',
    ERROR_FIELD_liraJurisdiction_notSameJuridiction: 'La juridiction des comptes CRI, CRIF, RERI ou REIR ne peut être la même.',
    ERROR_FIELD_liraCurrencySec: 'Devise du compte - CRI, CRIF, RERI ou REIR',
    ERROR_FIELD_liraCurrencySec_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_liraCurrencySec_onlyOneAccountTypeInUSD: 'Il ne peut y avoir qu\'un seul CRI, CRIF, RERI ou REIR en dollars américains.',
    ERROR_FIELD_liraJurisdictionSec: 'Juridiction du CRI, CRIF, RERI ou REIR',
    ERROR_FIELD_liraJurisdictionSec_required: 'Veuillez choisir une juridiction.',
    ERROR_FIELD_liraJurisdictionSec_notSameJuridiction: 'La juridiction des comptes CRI, CRIF, RERI ou REIR ne peut être la même.',
    ERROR_FIELD_rrifCurrency: 'Devise du compte - FERR',
    ERROR_FIELD_rrifCurrency_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_rrifCurrency_onlyOneAccountTypeInUSD: 'Il ne peut y avoir qu\'un seul FERR en dollars américains.',
    ERROR_FIELD_rrifAnnuitant: 'Type de cotisation - FERR',
    ERROR_FIELD_rrifAnnuitant_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_rrifCurrencySec: 'Devise du compte - FERR',
    ERROR_FIELD_rrifCurrencySec_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_rrifCurrencySec_onlyOneAccountTypeInUSD: 'Il ne peut y avoir qu\'un seul FERR en dollars américains.',
    ERROR_FIELD_rrifAnnuitantSec: 'Type de cotisation - FERR',
    ERROR_FIELD_rrifAnnuitantSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_lifCurrency: 'Devise du compte - FRV ou FRVR',
    ERROR_FIELD_lifCurrency_required: 'Veuillez sélectionner la(es) devise(s) du compte.',
    ERROR_FIELD_lifJurisdiction: 'Juridiction du FRV ou FRVR',
    ERROR_FIELD_lifJurisdiction_required: 'Veuillez choisir une juridiction.',
    ERROR_FIELD_ftarrspAnnuitant: 'Type de cotisation - RRNV',
    ERROR_FIELD_ftarrspAnnuitant_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicator: 'Désignation de bénéficiaire au décès',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicator_maxTwoSameDesignationOfBeneficiary: 'Seulement deux comptes RRNV peuvent avoir la même désignation de bénéficiaire.',
    ERROR_FIELD_ftarrspAnnuitantSec: 'Type de cotisation - RRNV',
    ERROR_FIELD_ftarrspAnnuitantSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorSec: 'Désignation de bénéficiaire au décès',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorSec_maxTwoSameDesignationOfBeneficiary: 'Seulement deux comptes RRNV peuvent avoir la même désignation de bénéficiaire.',
    ERROR_FIELD_ftarrspAnnuitantTer: 'Type de cotisation - RRNV',
    ERROR_FIELD_ftarrspAnnuitantTer_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorTer: 'Désignation de bénéficiaire au décès',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorTer_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspRevocableBeneficiaryIndicatorTer_maxTwoSameDesignationOfBeneficiary: 'Seulement deux comptes RRNV peuvent avoir la même désignation de bénéficiaire.',
    ERROR_FIELD_addDiscretionary: 'Carte blanche',
    ERROR_FIELD_addDiscretionary_requiredFalse: 'Le compte Carte blanche n\'est pas disponible pour l\'impression des formulaires et l\'ouverture automatisée.',
    ERROR_FIELD_cashAccountMgntType: 'Type de gestion - Compte Comptant',
    ERROR_FIELD_cashAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_tfsaAccountMgntType: 'Type de gestion - CELI',
    ERROR_FIELD_tfsaAccountMgntType_required: 'Veuillez sélectionner le type de gestion.',
    ERROR_FIELD_tfsaAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_rrspAccountMgntType: 'Type de gestion - REER',
    ERROR_FIELD_rrspAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_rrspAccountMgntTypeSec: 'Type de gestion - REER',
    ERROR_FIELD_rrspAccountMgntTypeSec_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_rrspAccountMgntTypeTer: 'Type de gestion - REER',
    ERROR_FIELD_rrspAccountMgntTypeTer_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_liraAccountMgntType: 'Type de gestion - CRI',
    ERROR_FIELD_liraAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_liraAccountMgntTypeSec: 'Type de gestion - CRI',
    ERROR_FIELD_liraAccountMgntTypeSec_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_rrifAccountMgntType: 'Type de gestion - FERR',
    ERROR_FIELD_rrifAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_rrifAccountMgntTypeSec: 'Type de gestion - FERR',
    ERROR_FIELD_rrifAccountMgntTypeSec_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_lifAccountMgntType: 'Type de gestion - FRV',
    ERROR_FIELD_lifAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_respAccountMgntType: 'Type de gestion - REEE',
    ERROR_FIELD_respAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_ftarrspAccountMgntType: 'Type de gestion - RRNV',
    ERROR_FIELD_ftarrspAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_ftarrspAccountMgntTypeSec: 'Type de gestion - RRNV',
    ERROR_FIELD_ftarrspAccountMgntTypeSec_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',
    ERROR_FIELD_ftarrspAccountMgntTypeTer: 'Type de gestion - RRNV',
    ERROR_FIELD_ftarrspAccountMgntTypeTer_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission, soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',

    // Personal Information
    ERROR_FIELD_formLang: 'Langue du client',
    ERROR_FIELD_formLang_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_gender: 'Titre',
    ERROR_FIELD_gender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userFirstName: 'Prénom',
    ERROR_FIELD_userFirstName_required: 'Veuillez indiquer votre prénom.',
    ERROR_FIELD_userLastName: 'Nom',
    ERROR_FIELD_userLastName_required: 'Veuillez indiquer votre nom.',
    ERROR_FIELD_userDOB: 'Date de naissance',
    ERROR_FIELD_userDOB_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_userDOB_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_userDOB_dateofbirthmajor: 'Veuillez indiquer une date de naissance réelle.',
    ERROR_FIELD_userDOB_dateofbirthyoung: 'Vous devez avoir l\'âge de la majorité pour ouvrir un compte de courtage.',
    ERROR_FIELD_userDOB_dateofbirthchild: 'Ce compte doit être ouvert pour une personne mineure seulement.',
    ERROR_FIELD_userDOB_dateofbirtchildmorethan150: 'Veuillez indiquer une date de naissance valide.',
    ERROR_FIELD_userDOB_dateofbirthrrsp: 'Un compte REER ne peut être ouvert après le 31 décembre de l\'année où vous atteignez l\'âge de 71 ans.',
    ERROR_FIELD_userSIN: 'Numéro d\'assurance sociale',
    ERROR_FIELD_userSIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_userSIN_required: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_userHomePhone: 'Téléphone (domicile)',
    ERROR_FIELD_userHomePhone_phone: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_userHomePhone_required: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_userHomePhone_phoneMatch: 'Téléphone domicile, téléphone travail et téléphone mobile doivent être différents.',
    ERROR_FIELD_userHomePhone_any_required: 'Veuillez saisir un numéro de téléphone (domicile, travail ou mobile).',
    ERROR_FIELD_otherFirstName: 'Prénom de l\'enfant',
    ERROR_FIELD_otherFirstName_required: 'Veuillez indiquer le prénom de l\'enfant.',
    ERROR_FIELD_otherLastName: 'Nom de l\'enfant',
    ERROR_FIELD_otherLastName_required: 'Veuillez indiquer le nom de l\'enfant.',
    ERROR_FIELD_userOtherPhone: 'Téléphone (travail)',
    ERROR_FIELD_userOtherPhone_phone: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_userOtherPhone_required: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_userOtherPhone_phoneMatch: 'Téléphone domicile, téléphone travail et téléphone mobile doivent être différents.',
    ERROR_FIELD_userOtherPhone_any_required: 'Veuillez saisir un numéro de téléphone (domicile, travail ou mobile).',
    ERROR_FIELD_userMobilePhone: 'Téléphone (mobile)',
    ERROR_FIELD_userMobilePhone_phone: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_userMobilePhone_required: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_userMobilePhone_phoneMatch: 'Téléphone domicile, téléphone travail et téléphone mobile doivent être différents.',
    ERROR_FIELD_userMobilePhone_any_required: 'Veuillez saisir un numéro de téléphone (domicile, travail ou mobile).',
    ERROR_FIELD_userEmail: 'Adresse électronique',
    ERROR_FIELD_userEmail_email: 'Veuillez indiquer une adresse électronique valide.',
    ERROR_FIELD_userEmail_required: 'Veuillez indiquer une adresse électronique valide.',
    ERROR_FIELD_companyName: 'Nom de la société',
    ERROR_FIELD_companyName_required: 'Veuillez indiquer un nom de société valide',
    ERROR_FIELD_userAddressStreet: 'N° et rue',
    ERROR_FIELD_userAddressStreet_required: 'Veuillez indiquer votre numéro et nom de rue.',
    ERROR_FIELD_userAddressStreet_addressMatch: 'L\'adresse de correspondance et l\'adresse personnelle doivent être différentes.',
    ERROR_FIELD_userAddressStreetSec: 'Adresse de correspondance n° et rue',
    ERROR_FIELD_userAddressStreetSec_required: 'Veuillez indiquer votre numéro et nom de rue.',
    ERROR_FIELD_userAddressStreetSec_addressMatch: 'L\'adresse de correspondance et l\'adresse personnelle doivent être différentes.',
    ERROR_FIELD_userAddressCity: 'Ville',
    ERROR_FIELD_userAddressCity_required: 'Veuillez indiquer le nom de votre ville.',
    ERROR_FIELD_userAddressCitySec: 'Adresse de correspondance Ville',
    ERROR_FIELD_userAddressCitySec_required: 'Veuillez indiquer le nom de votre ville.',
    ERROR_FIELD_userAddressProv: 'Province',
    ERROR_FIELD_userAddressProv_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_userAddressProv_provincesnotequals: 'La province doit être la même pour tous les titulaires.',
    ERROR_FIELD_userAddressProvSec: 'Adresse de correspondance Province',
    ERROR_FIELD_userAddressProvSec_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_userAddressPostalCode: 'Code postal',
    ERROR_FIELD_userAddressPostalCode_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_userAddressPostalCode_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_userAddressPostalCodeSec: 'Adresse de correspondance Code postal',
    ERROR_FIELD_userAddressPostalCodeSec_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_userAddressPostalCodeSec_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_jointSecondAccountholderSpouse_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_jointSecondAccountholderSpouse: 'Deuxième titulaire - Conjoint',
    ERROR_FIELD_isSameAddressAsFirstIndicator: 'Adresse différente',
    ERROR_FIELD_isSameAddressAsFirstIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_federalBusinessNumber: 'Numéro d\'entreprise au fédéral',
    ERROR_FIELD_federalBusinessNumberExtension: 'Numéro d\'entreprise au fédéral - extension',
    ERROR_FIELD_provincialBusinessNumber: 'Numéro d\'entreprise au provincial',
    ERROR_FIELD_provinceOfBusinessNumber: 'Province ou territoire',
    ERROR_FIELD_federalBusinessNumber_required: 'Veuillez indiquer un numéro d\'entreprise au fédéral.',
    ERROR_FIELD_federalBusinessNumber_required_choice: 'Veuillez indiquer un numéro d\'entreprise au fédéral ou provincial.',
    ERROR_FIELD_federalBusinessNumber_minlength: 'Le numéro d\'entreprise au fédéral (NE) doit être de 9 caractères.',
    ERROR_FIELD_provincialBusinessNumber_required: 'Veuillez indiquer un numéro d\'entreprise au provincial.',
    ERROR_FIELD_provincialBusinessNumber_required_choice: 'Veuillez indiquer un numéro d\'entreprise au provincial ou fédéral.',
    ERROR_FIELD_provincialBusinessNumber_minlength: 'Le numéro d\'entreprise au provincial doit être de 10 caractères.',
    ERROR_FIELD_provincialBusinessNumber_digit: 'Le numéro d\'entreprise au provincial doit être numérique.',
    ERROR_FIELD_provinceOfBusinessNumber_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_federalBusinessNumber_digit: 'Numéro d\'entreprise au fédéral invalide',
    ERROR_FIELD_fiscalPeriodEndDate: 'Date de fin d\'exercice',
    ERROR_FIELD_fiscalPeriodEndDate_required: 'Veuillez indiquer une date de fin d\'exercice.',
    ERROR_FIELD_fiscalPeriodEndDate_date: 'Veuillez indiquer une date de fin d\'exercice valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_fiscalPeriodEndDate_dateinfuture: 'Veuillez indiquer une date ultérieure à celle du jour.',
    ERROR_FIELD_borrowingLoanStartingDate_dateInf: 'La date du début du prêt doit être inférieure à la date d\'échéance du prêt',
    ERROR_FIELD_userIsCurrentAddress: 'Adresse courante',
    ERROR_FIELD_userIsCurrentAddress_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userAddressUnit: 'Unité/App.',
    ERROR_FIELD_userAddressUnit_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_userAddressUnitSec: 'Unité/App.',
    ERROR_FIELD_userAddressUnitSec_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_beneficiaryReerAdressUnit: 'Unité/App.',
    ERROR_FIELD_beneficiaryReerAdressUnit_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_beneficiaryReerAdressUnitSec: 'Unité/App.',
    ERROR_FIELD_beneficiaryReerAdressUnitSec_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_beneficiaryReerAdressUnitTer: 'Unité/App.',
    ERROR_FIELD_beneficiaryReerAdressUnitTer_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_beneficiaryCeliAdressUnit: 'Unité/App.',
    ERROR_FIELD_beneficiaryCeliAdressUnit_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_beneficiaryCeliAdressUnitSec: 'Unité/App.',
    ERROR_FIELD_beneficiaryCeliAdressUnitSec_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_beneficiaryCeliAdressUnitTer: 'Unité/App.',
    ERROR_FIELD_beneficiaryCeliAdressUnitTer_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_attorneyAddressUnit: 'Unité/App.',
    ERROR_FIELD_attorneyAddressUnit_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_beneficiaryRespAddressUnit: 'Unité/App.',
    ERROR_FIELD_beneficiaryRespAddressUnit_alphanumeric: 'Veuillez indiquer une unité/app valide.',
    ERROR_FIELD_addressUnit: 'Unité/App.',
    ERROR_FIELD_addressUnit_alphanumeric: 'Veuillez indiquer une unité/app valide.',

    // tax
    ERROR_FIELD_userCitizenship: 'Citoyenneté',
    ERROR_FIELD_userCitizenship_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userCitizenship_usaRequired: 'Vous devez indiquer que vous êtes citoyen des États-Unis.',
    ERROR_FIELD_userCitizenshipUS: 'Citoyenneté',
    ERROR_FIELD_userCitizenshipUS_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userOtherCitizenship: 'Citoyenneté',
    ERROR_FIELD_userOtherCitizenship_required: 'Veuillez sélectionner le pays.',
    ERROR_FIELD_userOtherCitizenship_usaRequired: 'Vous devez indiquer que vous êtes citoyen des États-Unis.',
    ERROR_FIELD_fiscalUsCitizenshipIndicator: 'Résident des États-Unis',
    ERROR_FIELD_fiscalUsCitizenshipIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userFiscalRelationCanada: 'Citoyen ou résident',
    ERROR_FIELD_userFiscalRelationCanada_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_usaNif: 'NIF - États-Unis',
    ERROR_FIELD_usaNif_required: 'Veuillez indiquer un NIF valide ou nous donner la raison.',
    ERROR_FIELD_usaNif_invalidRange: 'Veuillez indiquer un numéro d\'identification fiscale valide.',
    ERROR_FIELD_userCountryNif2: 'Premier pays',
    ERROR_FIELD_userCountryNif2_required: 'Veuillez sélectionner le pays.',
    ERROR_FIELD_codePremierAutreNif: 'NIF - Premier pays',
    ERROR_FIELD_codePremierAutreNif_required: 'Veuillez indiquer un NIF valide ou nous donner la raison.',
    ERROR_FIELD_codePremierAutreNif_invalidRange: 'Veuillez indiquer un numéro d\'identification fiscale valide.',
    ERROR_FIELD_userCountryNif3: 'Deuxième pays',
    ERROR_FIELD_userCountryNif3_required: 'Veuillez sélectionner le pays.',
    ERROR_FIELD_codeSecondAutreNif: 'NIF - Deuxième pays',
    ERROR_FIELD_codeSecondAutreNif_required: 'Veuillez indiquer un NIF valide ou nous donner la raison.',
    ERROR_FIELD_codeSecondAutreNif_invalidRange: 'Veuillez indiquer un numéro d\'identification fiscale valide.',

    // SPOUSE
    ERROR_FIELD_userMaritalStatus: 'État civil',
    ERROR_FIELD_userMaritalStatus_required: 'Veuillez sélectionner un état civil.',
    ERROR_FIELD_spouseGender: 'Titre',
    ERROR_FIELD_spouseGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_spouseFirstName: 'Prénom',
    ERROR_FIELD_spouseFirstName_required: 'Veuillez indiquer votre prénom.',
    ERROR_FIELD_spouseLastName: 'Nom',
    ERROR_FIELD_spouseLastName_required: 'Veuillez indiquer votre nom.',
    ERROR_FIELD_spouseDOB: 'Date de naissance',
    ERROR_FIELD_spouseDOB_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_spouseDOB_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_spouseDOB_dateofbirthmajor: 'Vous devez avoir l\'âge de la majorité.',
    ERROR_FIELD_spouseSIN: 'Numéro d\'assurance sociale',
    ERROR_FIELD_spouseSIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_spouseSIN_required: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_spouseJobStatus: 'Statut d\'emploi',
    ERROR_FIELD_spouseJobStatus_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_spouseEmployerName: 'Nom de l\'employeur',
    ERROR_FIELD_spouseEmployerName_required: 'Veuillez indiquer le nom de l\'employeur.',
    ERROR_FIELD_spouseJobTitle: 'Titre de l\'emploi',
    ERROR_FIELD_spouseJobTitle_required: 'Veuillez indiquer le titre de l\'emploi.',
    ERROR_FIELD_spouseEmployerActivity: 'Secteur d\'activité',
    ERROR_FIELD_spouseEmployerActivity_required: 'Veuillez sélectionner un secteur d\'activité.',

    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouse: 'Conjoint cotisant - REER',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouse_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouse_contributingSpouseCannotBeCurrentSpouse: 'Vu l\'état civil sélectionné, le conjoint cotisant ne peut pas être le conjoint actuel.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouse_multiple: 'Il n\'est pas possible de spécifier sur une même demande des conjoints cotisants différents.  Veuillez créer des demandes distinctes pour chaque conjoint cotisant différent.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseSec: 'Conjoint cotisant - REER',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseSec_contributingSpouseCannotBeCurrentSpouse: 'Vu l\'état civil sélectionné, le conjoint cotisant ne peut pas être le conjoint actuel.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseSec_multiple: 'Il n\'est pas possible de spécifier sur une même demande des conjoints cotisants différents.  Veuillez créer des demandes distinctes pour chaque conjoint cotisant différent.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseTer: 'Conjoint cotisant - REER',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseTer_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseTer_contributingSpouseCannotBeCurrentSpouse: 'Vu l\'état civil sélectionné, le conjoint cotisant ne peut pas être le conjoint actuel.',
    ERROR_FIELD_rrspContributingSpouseIsCurrentSpouseTer_multiple: 'Il n\'est pas possible de spécifier sur une même demande des conjoints cotisants différents.  Veuillez créer des demandes distinctes pour chaque conjoint cotisant différent.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouse: 'Conjoint cotisant - FERR',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouse_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouse_contributingSpouseCannotBeCurrentSpouse: 'Vu l\'état civil sélectionné, le conjoint cotisant ne peut pas être le conjoint actuel.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouse_multiple: 'Il n\'est pas possible de spécifier sur une même demande des conjoints cotisants différents.  Veuillez créer des demandes distinctes pour chaque conjoint cotisant différent.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouseSec: 'Conjoint cotisant - FERR',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouseSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouseSec_contributingSpouseCannotBeCurrentSpouse: 'Vu l\'état civil sélectionné, le conjoint cotisant ne peut pas être le conjoint actuel.',
    ERROR_FIELD_rrifContributingSpouseIsCurrentSpouseSec_multiple: 'Il n\'est pas possible de spécifier sur une même demande des conjoints cotisants différents.  Veuillez créer des demandes distinctes pour chaque conjoint cotisant différent.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouse: 'Conjoint cotisant - RRNV',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouse_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouse_contributingSpouseCannotBeCurrentSpouse: 'Vu l\'état civil sélectionné, le conjoint cotisant ne peut pas être le conjoint actuel.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouse_multiple: 'Il n\'est pas possible de spécifier sur une même demande des conjoints cotisants différents.  Veuillez créer des demandes distinctes pour chaque conjoint cotisant différent.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseSec: 'Conjoint cotisant - RRNV',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseSec_contributingSpouseCannotBeCurrentSpouse: 'Vu l\'état civil sélectionné, le conjoint cotisant ne peut pas être le conjoint actuel.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseSec_multiple: 'Il n\'est pas possible de spécifier sur une même demande des conjoints cotisants différents.  Veuillez créer des demandes distinctes pour chaque conjoint cotisant différent.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseTer: 'Conjoint cotisant - RRNV',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseTer_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseTer_contributingSpouseCannotBeCurrentSpouse: 'Vu l\'état civil sélectionné, le conjoint cotisant ne peut pas être le conjoint actuel.',
    ERROR_FIELD_ftarrspContributingSpouseIsCurrentSpouseTer_multiple: 'Il n\'est pas possible de spécifier sur une même demande des conjoints cotisants différents.  Veuillez créer des demandes distinctes pour chaque conjoint cotisant différent.',

    ERROR_FIELD_contributingSpouseGender: 'Titre',
    ERROR_FIELD_contributingSpouseGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_contributingSpouseFirstName: 'Prénom',
    ERROR_FIELD_contributingSpouseFirstName_required: 'Veuillez indiquer votre prénom.',
    ERROR_FIELD_contributingSpouseLastName: 'Nom',
    ERROR_FIELD_contributingSpouseLastName_required: 'Veuillez indiquer votre nom.',
    ERROR_FIELD_contributingSpouseDOB: 'Date de naissance',
    ERROR_FIELD_contributingSpouseDOB_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_contributingSpouseDOB_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_contributingSpouseDOB_dateofbirthmajor: 'Vous devez avoir l\'âge de la majorité.',
    ERROR_FIELD_contributingSpouseSIN: 'Numéro d\'assurance sociale',
    ERROR_FIELD_contributingSpouseSIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_contributingSpouseSIN_required: 'Veuillez indiquer un numéro d\'assurance sociale valide.',

    // Occupation
    ERROR_FIELD_userJobStatus: 'Statut d\'emploi',
    ERROR_FIELD_userJobStatus_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userEmployerIsBroker: 'À l\'emploi d\'un courtier en valeurs mobilières',
    ERROR_FIELD_userEmployerIsBroker_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userIsInvestmentAdvisorIndicator: 'Conseiller en placement',
    ERROR_FIELD_userIsInvestmentAdvisorIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userEmployerName: 'Nom de l\'employeur',
    ERROR_FIELD_userEmployerName_required: 'Veuillez indiquer le nom de l\'employeur.',
    ERROR_FIELD_userJobTitle: 'Titre de l\'emploi',
    ERROR_FIELD_userJobTitle_required: 'Veuillez indiquer le titre de l\'emploi.',
    ERROR_FIELD_userEmployerActivity: 'Secteur d\'activité',
    ERROR_FIELD_userEmployerActivity_required: 'Veuillez sélectionner un secteur d\'activité.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeIndicator: 'Même adresse qu\'un employé d\'une firme de courtage',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeVmdIndicator: 'Même adresse qu\'un employé d\'une firme de courtage VMD',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeVmdIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeFirmName: 'Nom de la firme',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeFirmName_required: 'Veuillez indiquer le nom de la firme.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeFirstName: 'Prénom de l\'employé (Firme)',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeFirstName_required: 'Veuillez indiquer le prénom de l\'employé.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLastName: 'Nom de l\'employé (Firme)',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLastName_required: 'Veuillez indiquer le nom de l\'employé.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLink: 'Lien',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLink_required: 'Veuillez indiquer le lien avec l\'employé.',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLinkList: 'Lien',
    ERROR_FIELD_userSameAddressWithBrokerEmployeeLinkList_required: 'Veuillez indiquer le lien avec l\'employé.',
    ERROR_FIELD_percentageOwner: 'Pourcentage de détention du client',
    ERROR_FIELD_percentageOwner_required: 'Veuillez indiquer le pourcentage de détention du client. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_percentageOwner_min: 'Veuillez indiquer un pourcentage de détention du client compris entre 1 et 100.',
    ERROR_FIELD_percentageOwner_max: 'Veuillez indiquer un pourcentage de détention du client compris entre 1 et 100.',

    // Attorney
    ERROR_FIELD_attorneyGender: 'Titre',
    ERROR_FIELD_attorneyGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_attorneyFirstName: 'Prénom',
    ERROR_FIELD_attorneyFirstName_required: 'Veuillez indiquer le prénom du mandataire.',
    ERROR_FIELD_attorneyLastName: 'Nom',
    ERROR_FIELD_attorneyLastName_required: 'Veuillez indiquer le nom du mandataire.',
    ERROR_FIELD_attorneyDOB: 'Date de naissance',
    ERROR_FIELD_attorneyDOB_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_attorneyDOB_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_attorneyDOB_dateofbirthmajor: 'Vous devez avoir l\'âge de la majorité.',
    ERROR_FIELD_attorneySIN: 'Numéro d\'assurance sociale',
    ERROR_FIELD_attorneySIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_attorneySIN_required: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_attorneyHomePhone: 'Téléphone à domicile',
    ERROR_FIELD_attorneyHomePhone_phone: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_attorneyHomePhone_required: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_attorneyHomePhone_phoneMatch: 'Téléphone domicile et téléphone autre doivent être différents.',
    ERROR_FIELD_attorneyOtherPhone: 'Téléphone (autre)',
    ERROR_FIELD_attorneyOtherPhone_phone: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_attorneyOtherPhone_required: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_attorneyOtherPhone_phoneMatch: 'Téléphone domicile et téléphone autre doivent être différents.',
    ERROR_FIELD_attorneyClientNumber: 'Numéro de client',
    ERROR_FIELD_attorneyClientNumber_required: 'Veuillez indiquer un numéro de client valide pour le mandataire.',
    ERROR_FIELD_attorneyClientNumber_username: 'Veuillez indiquer un numéro de client valide pour le mandataire.',
    ERROR_FIELD_attorneyClientRelationship: 'Relation',
    ERROR_FIELD_attorneyClientRelationship_required: 'Veuillez indiquer la relation avec le client.',
    ERROR_FIELD_attorneyAddressStreet: 'N° et rue',
    ERROR_FIELD_attorneyAddressStreet_required: 'Veuillez indiquer votre numéro et nom de rue.',
    ERROR_FIELD_attorneyAddressCity: 'Ville',
    ERROR_FIELD_attorneyAddressCity_required: 'Veuillez indiquer le nom de votre ville.',
    ERROR_FIELD_attorneyAddressProv: 'Province',
    ERROR_FIELD_attorneyAddressProv_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_attorneyAddressPostalCode: 'Code postal',
    ERROR_FIELD_attorneyAddressPostalCode_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_attorneyAddressPostalCode_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_attorneyJobStatus: 'Statut d\'emploi',
    ERROR_FIELD_attorneyJobStatus_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_attorneyEmployerIsBroker: 'À l\'emploi d\'un courtier en valeurs mobilières',
    ERROR_FIELD_attorneyEmployerIsBroker_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_attorneyEmployerName: 'Nom de l\'employeur',
    ERROR_FIELD_attorneyEmployerName_required: 'Veuillez indiquer le nom de l\'employeur.',
    ERROR_FIELD_attorneyJobTitle: 'Titre de l\'emploi',
    ERROR_FIELD_attorneyJobTitle_required: 'Veuillez indiquer le titre de l\'emploi.',
    ERROR_FIELD_attorneyEmployerActivity: 'Secteur d\'activité',
    ERROR_FIELD_attorneyEmployerActivity_required: 'Veuillez sélectionner un secteur d\'activité.',
    ERROR_FIELD_attorneyBankId: 'Quel est le nom de l\'institution financière du mandataire ?',
    ERROR_FIELD_attorneyBankId_required: 'Veuillez sélectionner une institution financière.',
    ERROR_FIELD_attorneyBankBranchNumber: 'Numéro de transit du mandataire',
    ERROR_FIELD_attorneyBankBranchNumber_required: 'Veuillez indiquer le numéro du transit.',
    ERROR_FIELD_attorneyBankBranchNumber_minlength: 'Veuillez indiquer un numéro du transit valide.',
    ERROR_FIELD_attorneyBankAccountNumber: 'Numéro de compte du mandataire',
    ERROR_FIELD_attorneyBankAccountNumber_required: 'Veuillez indiquer le numéro de compte.',
    ERROR_FIELD_attorneyBankPhone: 'Numéro de téléphone de l\'institution financière du mandataire',
    ERROR_FIELD_attorneyBankPhone_required: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_attorneyBankPhone_phone: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_attorneyInitieIndicator: 'Est-ce que le mandataire est un initié assujetti ?',
    ERROR_FIELD_attorneyInitieIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_attorneyInitieCompanyName: 'Initié assujetti - Nom de la compagnie',
    ERROR_FIELD_attorneyInitieCompanyName_required: 'Veuillez indiquer le nom de la compagnie.',
    ERROR_FIELD_attorneyInitieCompanySymbol: 'Initié assujetti - Symbole boursier',
    ERROR_FIELD_attorneyInitieCompanySymbol_required: 'Veuillez indiquer le symbole boursier.',
    ERROR_FIELD_attorneyInitieMarket: 'Initié assujetti - Marché',
    ERROR_FIELD_attorneyInitieMarket_required: 'Veuillez sélectionner un marché.',
    ERROR_FIELD_attorneyMainShareholderIndicator: 'Est-ce que le mandataire est un actionnaire important ?',
    ERROR_FIELD_attorneyMainShareholderIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_attorneyMainShareholderCompanyName: 'Actionnaire important - Nom de la compagnie',
    ERROR_FIELD_attorneyMainShareholderCompanyName_required: 'Veuillez indiquer le nom de la compagnie.',
    ERROR_FIELD_attorneyMainShareholderCompanySymbol: 'Actionnaire important - Symbole boursier',
    ERROR_FIELD_attorneyMainShareholderCompanySymbol_required: 'Veuillez indiquer le symbole boursier.',
    ERROR_FIELD_attorneyMainShareholderMarket: 'Actionnaire important - Marché',
    ERROR_FIELD_attorneyMainShareholderMarket_required: 'Veuillez sélectionner un marché.',

    ERROR_FIELD_userAnnualIncome: 'Revenu annuel',
    ERROR_FIELD_userAnnualIncome_required: 'Veuillez indiquer votre revenu annuel.',
    ERROR_FIELD_userAnnualIncome_digit: 'Le revenu annuel doit être numérique.',
    ERROR_FIELD_userNetCapitalAsset: 'Actif immobilisé',
    ERROR_FIELD_userNetCapitalAsset_required: 'Veuillez indiquer votre actif immobilisé.',
    ERROR_FIELD_userNetLiquidityAsset: 'Actif liquide',
    ERROR_FIELD_userNetLiquidityAsset_required: 'Veuillez indiquer votre actif liquide.',
    ERROR_FIELD_userBankId: 'Institution financière',
    ERROR_FIELD_userBankId_required: 'Veuillez sélectionner une institution financière.',
    ERROR_FIELD_userBankBranchNumber: 'Numéro de transit',
    ERROR_FIELD_userBankBranchNumber_required: 'Veuillez indiquer le numéro du transit de votre institution financière.',
    ERROR_FIELD_userBankBranchNumber_transit: 'Veuillez indiquer un numéro de transit valide.',
    ERROR_FIELD_userBankAccountNumber: 'Numéro de compte',
    ERROR_FIELD_userBankAccountNumber_required: 'Veuillez indiquer votre numéro de compte.',
    ERROR_FIELD_userBankPhone: 'Téléphone institution financière',
    ERROR_FIELD_userBankPhone_phone: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_borrowedForInvestmentPurposeIndicator: 'Avez-vous emprunté des fonds pour financer vos achats de produits de placement par le biais de prêts avancés par des tiers',
    ERROR_FIELD_borrowedForInvestmentPurposeIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_borrowingAmountOfLoan: 'Montant du prêt',
    ERROR_FIELD_borrowingAmountOfLoan_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_borrowingInterestRate: 'Taux d\'intérêt',
    ERROR_FIELD_borrowingInterestRate_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_borrowingInterestRate_max: 'Taux d\'intérêt maximum est 999.99',
    ERROR_FIELD_borrowingLoanStartingDate: 'Date de début du prêt',
    ERROR_FIELD_borrowingLoanStartingDate_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_borrowingLoanStartingDate_date: 'Veuillez indiquer une date de début du prêt valide sous le format aaaa-mm-jj',
    ERROR_FIELD_borrowingLoanStartingDate_dateRange: 'Veuillez indiquer une date entre 1900-01-01 et 2999-12-31',
    ERROR_FIELD_borrowingLoanMaturityDate: 'Date d\'échéance du prêt',
    ERROR_FIELD_borrowingLoanMaturityDate_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_borrowingLoanMaturityDate_date: 'Veuillez indiquer une date d\'échéance du prêt valide sous le format aaaa-mm-jj',
    ERROR_FIELD_borrowingLoanMaturityDate_dateRange: 'Veuillez indiquer une date entre 1900-01-01 et 2999-12-31',
    ERROR_FIELD_borrowingAmountInvested: 'Montant investi à titre d’emprunts à des fins de placement',
    ERROR_FIELD_borrowingAmountInvested_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_optionCode: 'Code d\'option autorisé pour le compte non enregistré',
    ERROR_FIELD_optionCode_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_registeredOptionCode: 'Code d\'option autorisé pour le compte enregistré',
    ERROR_FIELD_registeredOptionCode_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userInvestmentObjectivesIntendedUse: 'Utilisation prévue',
    ERROR_FIELD_userInvestmentObjectivesIntendedUse_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userInvestmentObjectivesNonRegInvHorizon: 'Horizon de placement',
    ERROR_FIELD_userInvestmentObjectivesNonRegInvHorizon_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userInvestmentObjectivesRegInvHorizon: 'Comptes enregistrés',
    ERROR_FIELD_userInvestmentObjectivesRegInvHorizon_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userInvestmentObjectivesRskTol: 'Profil de risque',
    ERROR_FIELD_userInvestmentObjectivesRskTol_required: 'Veuillez sélectionner une réponse.',

    // Legal
    ERROR_FIELD_userDisclaimerIndicator: 'Propriété véritable',
    ERROR_FIELD_userDisclaimerIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userReceiptDocumentSecuritiesHolderIndicator: 'Réception des documents',
    ERROR_FIELD_userReceiptDocumentSecuritiesHolderIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userElectronicDocumentSecuritiesHolderIndicator: 'Consentement à la transmission électronique de documents.',
    ERROR_FIELD_userElectronicDocumentSecuritiesHolderIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_authorizeClientInfoPresence: 'Service de présence.',
    ERROR_FIELD_authorizeClientInfoPresence_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_personalInformationSpecificConsentIndicator: 'Consentement spécifique',
    ERROR_FIELD_personalInformationSpecificConsentIndicator_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_optionActOtherBrokerIndicator: 'Autre compte d\'options',
    ERROR_FIELD_optionActOtherBrokerIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_optionActOtherBrokerFirmName: 'Nom de la firme (Compte d\'option)',
    ERROR_FIELD_optionActOtherBrokerFirmName_required: 'Veuillez indiquer le nom de la firme.',
    ERROR_FIELD_optionActExperienceSpecification: 'Précision (Compte d\'option)',
    ERROR_FIELD_optionActExperienceSpecification_required: 'Veuillez préciser votre réponse.',
    ERROR_FIELD_optionActTradingExperienceIndicator: 'Expérience',
    ERROR_FIELD_optionActTradingExperienceIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_optionActTradingExperienceYears1: 'Acquisition d\'options d\'achat et d\'options de vente - Nombre d\'années',
    ERROR_FIELD_optionActTradingExperienceYears2: 'Vente d\'options couvertes - Nombre d\'années',
    ERROR_FIELD_optionActTradingExperienceYears3: 'Opérations mixtes - Nombre d\'années',
    ERROR_FIELD_optionActTradingExperienceYears4: 'Vente d\'options à découvert - Nombre d\'années',
    ERROR_FIELD_optionActTradingExperienceYears1_required: 'Acquisition d\'options d\'achat et d\'options de vente - Veuillez indiquer le nombre d\'années d\'expérience.',
    ERROR_FIELD_optionActTradingExperienceYears2_required: 'Vente d\'options couvertes - Veuillez indiquer le nombre d\'années d\'expérience.',
    ERROR_FIELD_optionActTradingExperienceYears3_required: 'Opérations mixtes - Veuillez indiquer le nombre d\'années d\'expérience.',
    ERROR_FIELD_optionActTradingExperienceYears4_required: 'Vente d\'options à découvert - Veuillez indiquer le nombre d\'années d\'expérience.',
    ERROR_FIELD_optionActTradingExperienceYears1_digit: 'Acquisition d\'options d\'achat et d\'options de vente - Veuillez saisir un nombre.',
    ERROR_FIELD_optionActTradingExperienceYears1_min: 'Acquisition d\'options d\'achat et d\'options de vente - Veuillez saisir un nombre entre 1 et 99.',
    ERROR_FIELD_optionActTradingExperienceYears1_max: 'Acquisition d\'options d\'achat et d\'options de vente - Veuillez saisir un nombre entre 1 et 99.',
    ERROR_FIELD_optionActTradingExperienceYears2_digit: 'Vente d\'options couvertes - Veuillez saisir un nombre.',
    ERROR_FIELD_optionActTradingExperienceYears2_min: 'Vente d\'options couvertes - Veuillez saisir un nombre entre 1 et 99.',
    ERROR_FIELD_optionActTradingExperienceYears2_max: 'Vente d\'options couvertes - Veuillez saisir un nombre entre 1 et 99.',
    ERROR_FIELD_optionActTradingExperienceYears3_digit: 'Opérations mixtes - Veuillez saisir un nombre.',
    ERROR_FIELD_optionActTradingExperienceYears3_min: 'Opérations mixtes - Veuillez saisir un nombre entre 1 et 99.',
    ERROR_FIELD_optionActTradingExperienceYears3_max: 'Opérations mixtes - Veuillez saisir un nombre entre 1 et 99.',
    ERROR_FIELD_optionActTradingExperienceYears4_digit: 'Vente d\'options à découvert - Veuillez saisir un nombre.',
    ERROR_FIELD_optionActTradingExperienceYears4_min: 'Vente d\'options à découvert - Veuillez saisir un nombre entre 1 et 99.',
    ERROR_FIELD_optionActTradingExperienceYears4_max: 'Vente d\'options à découvert - Veuillez saisir un nombre entre 1 et 99.',
    ERROR_FIELD_optionActTradingTheoriqueKnowledgeIndicator: 'Connaissances théoriques',
    ERROR_FIELD_optionActTradingTheoriqueKnowledgeIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_optionActTradingExperienceOperationLevel1: 'Types d\'opérations - Expérience',
    ERROR_FIELD_optionActTradingPlannedOperationLevel1: 'Types d\'opérations - Opérations prévues',
    ERROR_FIELD_optionActTradingExperienceOperationLevel1_required: 'Veuillez indiquer au moins un type d\'opération pour lequel vous avez de l\'expérience.',
    ERROR_FIELD_optionActTradingPlannedOperationLevel1_required: 'Veuillez indiquer au moins un type d\'opération sur options que vous prévoyez effectuer.',

    ERROR_FIELD_beneficiaryReerActiveIndicator: 'Bénéficiaire (REER)',
    ERROR_FIELD_beneficiaryReerActiveIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryReerGender: 'Titre du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryReerFirstName: 'Prénom du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerFirstName_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryReerLastName: 'Nom du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerLastName_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryReerDOB: 'Date de naissance du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerDOB_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerDOB_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerDOB_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerDOB_dateofbirthmajor: 'Vous devez avoir l\'âge de la majorité.',
    ERROR_FIELD_beneficiaryReerSIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryReerSIN: 'Numéro d\'assurance sociale n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerAdressStreet: 'N° et rue du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerAdressStreet_required: 'Veuillez indiquer le numéro et nom de rue.',
    ERROR_FIELD_beneficiaryReerAdressCity: 'Ville du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerAdressCity_required: 'Veuillez indiquer le nom de la ville.',
    ERROR_FIELD_beneficiaryReerAdressProv: 'Province du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerAdressProv_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_beneficiaryReerAdressPostalCode: 'Code postal du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerAdressPostalCode_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryReerAdressPostalCode_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryReerParentLink: 'Lien de parenté du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerParentLink_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryReerPart: 'Part du bénéficiaire n° 1 (REER)',
    ERROR_FIELD_beneficiaryReerPart_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryReerPart_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryReerPart_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryReerGenderSec: 'Titre du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerGenderSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryReerFirstNameSec: 'Prénom du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerFirstNameSec_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryReerLastNameSec: 'Nom du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerLastNameSec_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryReerDOBSec: 'Date de naissance du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerDOBSec_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerDOBSec_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerDOBSec_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerSINSec_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryReerSINSec: 'Numéro d\'assurance sociale n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerAdressStreetSec: 'N° et rue du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerAdressStreetSec_required: 'Veuillez indiquer le numéro et nom de rue.',
    ERROR_FIELD_beneficiaryReerAdressCitySec: 'Ville du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerAdressCitySec_required: 'Veuillez indiquer le nom de la ville.',
    ERROR_FIELD_beneficiaryReerAdressProvSec: 'Province du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerAdressProvSec_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeSec: 'Code postal du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeSec_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeSec_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryReerParentLinkSec: 'Lien de parenté du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerParentLinkSec_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryReerPartSec: 'Part du bénéficiaire n° 2 (REER)',
    ERROR_FIELD_beneficiaryReerPartSec_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryReerPartSec_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryReerPartSec_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryReerGenderTer: 'Titre du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerGenderTer_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryReerFirstNameTer: 'Prénom du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerFirstNameTer_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryReerLastNameTer: 'Nom du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerLastNameTer_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryReerDOBTer: 'Date de naissance du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerDOBTer_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerDOBTer_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerDOBTer_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryReerSINTer_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryReerSINTer: 'Numéro d\'assurance sociale n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerAdressStreetTer: 'N° et rue du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerAdressStreetTer_required: 'Veuillez indiquer le numéro et nom de rue.',
    ERROR_FIELD_beneficiaryReerAdressCityTer: 'Ville du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerAdressCityTer_required: 'Veuillez indiquer le nom de la ville.',
    ERROR_FIELD_beneficiaryReerAdressProvTer: 'Province du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerAdressProvTer_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeTer: 'Code postal du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeTer_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryReerAdressPostalCodeTer_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryReerParentLinkTer: 'Lien de parenté du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerParentLinkTer_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryReerPartTer: 'Part du bénéficiaire n° 3 (REER)',
    ERROR_FIELD_beneficiaryReerPartTer_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryReerPartTer_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryReerPartTer_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',


    ERROR_FIELD_successorHolderCeliAppActiveIndicator: 'Titulaire remplaçant (CELIAPP)',
    ERROR_FIELD_successorHolderCeliAppActiveIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_successorHolderCeliAppGender: 'Titre du titulaire remplaçant (CELIAPP)',
    ERROR_FIELD_successorHolderCeliAppGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_successorHolderCeliAppFirstName: 'Prénom du titulaire remplaçant (CELIAPP)',
    ERROR_FIELD_successorHolderCeliAppFirstName_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_successorHolderCeliAppLastName: 'Nom du titulaire remplaçant (CELIAPP)',
    ERROR_FIELD_successorHolderCeliAppLastName_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_successorHolderCeliAppSIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_successorHolderCeliAppSIN: 'Numéro d\'assurance sociale (CELIAPP)',
    ERROR_FIELD_successorHolderCeliAppSIN_required: 'Veuillez indiquer le numéro d\'assurance sociale',

    ERROR_FIELD_beneficiaryCeliAppActiveIndicator: 'Bénéficiaire (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppActiveIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliAppGender: 'Titre du bénéficiaire n° 1 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliAppFirstName: 'Prénom du bénéficiaire n° 1 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppFirstName_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryCeliAppLastName: 'Nom du bénéficiaire n° 1 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppLastName_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryCeliAppDOB: 'Date de naissance du bénéficiaire n° 1 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppDOB_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOB_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOB_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOB_dateofbirthmajor: 'Veuillez indiquer une date de naissance réelle.',
    ERROR_FIELD_beneficiaryCeliAppSIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryCeliAppSIN: 'Numéro d\'assurance sociale n° 1 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppParentLink: 'Lien de parenté du bénéficiaire n° 1 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppParentLink_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryCeliAppPart: 'Part du bénéficiaire n° 1 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppPart_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryCeliAppPart_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliAppPart_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliAppGenderSec: 'Titre du bénéficiaire n° 2 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppGenderSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliAppFirstNameSec: 'Prénom du bénéficiaire n° 2 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppFirstNameSec_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryCeliAppLastNameSec: 'Nom du bénéficiaire n° 2 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppLastNameSec_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryCeliAppDOBSec: 'Date de naissance du bénéficiaire n° 2 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppDOBSec_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOBSec_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOBSec_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOBSec_dateofbirthmajor: 'Veuillez indiquer une date de naissance réelle.',
    ERROR_FIELD_beneficiaryCeliAppSINSec_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryCeliAppSINSec: 'Numéro d\'assurance sociale n° 2 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppParentLinkSec: 'Lien de parenté du bénéficiaire n° 2 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppParentLinkSec_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryCeliAppPartSec: 'Part du bénéficiaire n° 2 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppPartSec_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryCeliAppPartSec_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliAppPartSec_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliAppGenderTer: 'Titre du bénéficiaire n° 3 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppGenderTer_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliAppFirstNameTer: 'Prénom du bénéficiaire n° 3 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppFirstNameTer_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryCeliAppLastNameTer: 'Nom du bénéficiaire n° 3 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppLastNameTer_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryCeliAppDOBTer: 'Date de naissance du bénéficiaire n° 3 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppDOBTer_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOBTer_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOBTer_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliAppDOBTer_dateofbirthmajor: 'Veuillez indiquer une date de naissance réelle.',
    ERROR_FIELD_beneficiaryCeliAppSINTer_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryCeliAppSINTer: 'Numéro d\'assurance sociale n° 3 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppParentLinkTer: 'Lien de parenté du bénéficiaire n° 3 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppParentLinkTer_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryCeliAppPartTer: 'Part du bénéficiaire n° 3 (CELIAPP)',
    ERROR_FIELD_beneficiaryCeliAppPartTer_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryCeliAppPartTer_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliAppPartTer_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',


    ERROR_FIELD_beneficiaryCeliActiveIndicator: 'Bénéficiaire (CELI)',
    ERROR_FIELD_beneficiaryCeliActiveIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliConjointTitulaire: 'Titulaire remplaçant (CELI)',
    ERROR_FIELD_beneficiaryCeliConjointTitulaire_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliGender: 'Titre du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliFirstName: 'Prénom du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliFirstName_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryCeliLastName: 'Nom du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliLastName_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryCeliDOB: 'Date de naissance du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliDOB_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliDOB_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliDOB_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliDOB_dateofbirthmajor: 'Vous devez avoir l\'âge de la majorité.',
    ERROR_FIELD_beneficiaryCeliSIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryCeliSIN: 'Numéro d\'assurance sociale n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressStreet: 'N° et rue du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressStreet_required: 'Veuillez indiquer le numéro et nom de rue.',
    ERROR_FIELD_beneficiaryCeliAdressCity: 'Ville du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressCity_required: 'Veuillez indiquer le nom de la ville.',
    ERROR_FIELD_beneficiaryCeliAdressProv: 'Province du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressProv_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCode: 'Code postal du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressPostalCode_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCode_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryCeliParentLink: 'Lien de parenté du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliParentLink_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryCeliPart: 'Part du bénéficiaire n° 1 (CELI)',
    ERROR_FIELD_beneficiaryCeliPart_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryCeliPart_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliPart_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliGenderSec: 'Titre du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliGenderSec_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliFirstNameSec: 'Prénom du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliFirstNameSec_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryCeliLastNameSec: 'Nom du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliLastNameSec_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryCeliDOBSec: 'Date de naissance du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliDOBSec_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliDOBSec_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliDOBSec_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliSINSec_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryCeliSINSec: 'Numéro d\'assurance sociale n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressStreetSec: 'N° et rue du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressStreetSec_required: 'Veuillez indiquer le numéro et nom de rue.',
    ERROR_FIELD_beneficiaryCeliAdressCitySec: 'Ville du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressCitySec_required: 'Veuillez indiquer le nom de la ville.',
    ERROR_FIELD_beneficiaryCeliAdressProvSec: 'Province du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressProvSec_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeSec: 'Code postal du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeSec_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeSec_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryCeliParentLinkSec: 'Lien de parenté du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliParentLinkSec_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryCeliPartSec: 'Part du bénéficiaire n° 2 (CELI)',
    ERROR_FIELD_beneficiaryCeliPartSec_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryCeliPartSec_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliPartSec_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliGenderTer: 'Titre du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliGenderTer_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryCeliFirstNameTer: 'Prénom du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliFirstNameTer_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryCeliLastNameTer: 'Nom du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliLastNameTer_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryCeliDOBTer: 'Date de naissance du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliDOBTer_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliDOBTer_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliDOBTer_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryCeliSINTer_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryCeliSINTer: 'Numéro d\'assurance sociale n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressStreetTer: 'N° et rue du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressStreetTer_required: 'Veuillez indiquer le numéro et nom de rue.',
    ERROR_FIELD_beneficiaryCeliAdressCityTer: 'Ville du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressCityTer_required: 'Veuillez indiquer le nom de la ville.',
    ERROR_FIELD_beneficiaryCeliAdressProvTer: 'Province du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressProvTer_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeTer: 'Code postal du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeTer_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryCeliAdressPostalCodeTer_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryCeliParentLinkTer: 'Lien de parenté du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliParentLinkTer_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryCeliPartTer: 'Part du bénéficiaire n° 3 (CELI)',
    ERROR_FIELD_beneficiaryCeliPartTer_lessthanmaxrequired: 'Le total des pourcentages ne peut dépasser 100 %.',
    ERROR_FIELD_beneficiaryCeliPartTer_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryCeliPartTer_morethanminrequired: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',

    ERROR_FIELD_beneficiaryRespReason: 'Raison',
    ERROR_FIELD_beneficiaryRespReason_reasonrequired: 'Vous devez entrer votre raison.',
    ERROR_FIELD_beneficiaryRespGender: 'Titre du bénéficiaire',
    ERROR_FIELD_beneficiaryRespGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryRespFirstName: 'Prénom du bénéficiaire',
    ERROR_FIELD_beneficiaryRespFirstName_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryRespLastName: 'Nom du bénéficiaire',
    ERROR_FIELD_beneficiaryRespLastName_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryRespDOB: 'Date de naissance du bénéficiaire',
    ERROR_FIELD_beneficiaryRespDOB_required: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryRespDOB_date: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryRespDOB_dateofbirthall: 'Veuillez indiquer une date de naissance valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_beneficiaryRespDOB_dateofbirthmajor: 'Vous devez avoir l\'âge de la majorité.',
    ERROR_FIELD_beneficiaryRespSIN: 'Numéro d\'assurance sociale',
    ERROR_FIELD_beneficiaryRespSIN_required: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryRespSIN_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryRespAddressSameAsSubscriber: 'Adresse differente du souscripteur',
    ERROR_FIELD_beneficiaryRespAddressSameAsSubscriber_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryRespAddressStreet: 'N° et rue du bénéficiaire',
    ERROR_FIELD_beneficiaryRespAddressStreet_required: 'Veuillez indiquer le numéro et nom de rue.',
    ERROR_FIELD_beneficiaryRespAddressCity: 'Ville du bénéficiaire',
    ERROR_FIELD_beneficiaryRespAddressCity_required: 'Veuillez indiquer le nom de la ville.',
    ERROR_FIELD_beneficiaryRespAddressProv: 'Province du bénéficiaire',
    ERROR_FIELD_beneficiaryRespAddressProv_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_beneficiaryRespAddressPostalCode: 'Code postal du bénéficiaire',
    ERROR_FIELD_beneficiaryRespAddressPostalCode_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryRespAddressPostalCode_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_beneficiaryRespParentLink: 'Lien de parenté du bénéficiaire',
    ERROR_FIELD_beneficiaryRespParentLink_required: 'Veuillez indiquer un lien de parenté.',
    ERROR_FIELD_beneficiaryRespDistributionRatio: 'Part du bénéficiaire',
    ERROR_FIELD_beneficiaryRespDistributionRatio_required: 'Veuillez indiquer le pourcentage de la portion léguée au bénéficiaire. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryRespDistributionRatio_percentagecumulrequired: 'La somme des ratios de distribution de tous les bénéficiaires doit être égale à 100%.',
    ERROR_FIELD_beneficiaryRespDistributionRatio_positivedigit: 'Veuillez indiquer un nombre superieur à 0. Ce dernier doit être compris entre 1 et 100.',
    ERROR_FIELD_beneficiaryRespBrotherAndSisterOption: 'Frères et soeurs',
    ERROR_FIELD_beneficiaryRespBrotherAndSisterOption_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryRespCaregiverParentFirstName: 'Prénom du responsable',
    ERROR_FIELD_beneficiaryRespCaregiverParentFirstName_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryRespCaregiverParentLastName: 'Nom du responsable',
    ERROR_FIELD_beneficiaryRespCaregiverParentLastName_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryRespCaregiverParentSin: 'NAS du responsable',
    ERROR_FIELD_beneficiaryRespCaregiverParentSin_required: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryRespCaregiverParentSin_sin: 'Veuillez indiquer un numéro d\'assurance sociale valide.',
    ERROR_FIELD_beneficiaryRespCustodialParentGender: 'Titre du parent ayant la garde',
    ERROR_FIELD_beneficiaryRespCustodialParentGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryRespCustodialParentFirstName: 'Prénom du parent ayant la garde',
    ERROR_FIELD_beneficiaryRespCustodialParentFirstName_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryRespCustodialParentLastName: 'Nom du parent ayant la garde',
    ERROR_FIELD_beneficiaryRespCustodialParentLastName_required: 'Veuillez indiquer le nom.',
    ERROR_FIELD_beneficiaryRespCustodialParentSecGender: 'Titre du parent n° 2 ayant la garde',
    ERROR_FIELD_beneficiaryRespCustodialParentSecGender_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_beneficiaryRespCustodialParentSecFirstName: 'Prénom du parent n° 2 ayant la garde',
    ERROR_FIELD_beneficiaryRespCustodialParentSecFirstName_required: 'Veuillez indiquer le prénom.',
    ERROR_FIELD_beneficiaryRespCustodialParentSecLastName: 'Nom du parent n° 2 ayant la garde',
    ERROR_FIELD_beneficiaryRespCustodialParentSecLastName_required: 'Veuillez indiquer le nom.',

    // Brokerage accounts
    ERROR_FIELD_brokerageOtherAccountsIndicator: 'Autres comptes de courtage',
    ERROR_FIELD_brokerageOtherAccountsFirmName: 'Nom de la firme (Autres comptes)',
    ERROR_FIELD_brokerageOtherAccountsAccountType: 'Type de compte (Autres comptes)',
    ERROR_FIELD_userInitieIndicator: 'Initié assujetti',
    ERROR_FIELD_userInitieCompanyName: 'Initié assujetti - Nom de la compagnie',
    ERROR_FIELD_userInitieCompanySymbol: 'Initié assujetti - Symbole boursier',
    ERROR_FIELD_userInitieMarket: 'Initié assujetti - Marché',
    ERROR_FIELD_userMainShareholderIndicator: 'Actionnaire important',
    ERROR_FIELD_userMainShareholderCompanyName: 'Actionnaire important - Nom de la compagnie',
    ERROR_FIELD_userMainShareholderCompanySymbol: 'Actionnaire important - Symbole boursier',
    ERROR_FIELD_userMainShareholderMarket: 'Actionnaire important - Marché',
    ERROR_FIELD_userPoliticallyExposedForeignPersonIndicator: 'Étranger politiquement vulnérable',
    ERROR_FIELD_userFinancialInterestIndicator: 'Intérêt financier',
    ERROR_FIELD_userTrustedPersonIndicator: 'Personne de confiance',
    ERROR_FIELD_brokeragePurpose: 'Utilisation prévue',
    ERROR_FIELD_brokeragePurposeOther: 'Précision (Utilisation prévue)',
    ERROR_FIELD_userBrokerageKnowledge: 'Connaissances en placement',
    ERROR_FIELD_userBrokerageCreditBalanceList: 'Soldes créditeurs',
    ERROR_FIELD_userBrokerageDirectDeposit: 'Dépôt direct',

    ERROR_FIELD_brokerageOtherAccountsIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_brokerageOtherAccountsFirmName_required: 'Veuillez indiquer le nom de la firme.',
    ERROR_FIELD_brokerageOtherAccountsAccountType_required: 'Veuillez sélectionner un type de compte.',
    ERROR_FIELD_userInitieIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userInitieCompanyName_required: 'Veuillez indiquer le nom de la compagnie.',
    ERROR_FIELD_userInitieCompanySymbol_required: 'Veuillez indiquer le symbole boursier.',
    ERROR_FIELD_userInitieMarket_required: 'Veuillez sélectionner un marché.',
    ERROR_FIELD_userMainShareholderIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userMainShareholderCompanyName_required: 'Veuillez indiquer le nom de la compagnie.',
    ERROR_FIELD_userMainShareholderCompanySymbol_required: 'Veuillez indiquer le symbole boursier.',
    ERROR_FIELD_userMainShareholderMarket_required: 'Veuillez sélectionner un marché.',
    ERROR_FIELD_userPoliticallyExposedForeignPersonIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userFinancialInterestIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userTrustedPersonIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_brokeragePurpose_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_brokeragePurposeOther_required: 'Veuillez préciser votre réponse.',
    ERROR_FIELD_userBrokerageKnowledge_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userOtherTradingPersonIndicator: 'Tierce personne',
    ERROR_FIELD_userOtherTradingPersonIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userOtherGuarantingPersonIndicator: 'D\'autres personnes garantissent ce compte',
    ERROR_FIELD_userOtherGuarantingPersonIndicator_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userBrokerageCreditBalanceList_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userBrokerageDirectDeposit_required: 'Veuillez sélectionner une réponse.',

    // Entity
    ERROR_FIELD_userOtherEntityType: 'Type d\'entité',
    ERROR_FIELD_userOtherEntityType_required: 'Veuillez indiquer le type d\'entité.',
    ERROR_FIELD_userOtherEntityName: 'Nom de l\'entité',
    ERROR_FIELD_userOtherEntityName_required: 'Veuillez indiquer le nom de l\'entité.',
    ERROR_FIELD_userOtherAmericanIndividualsNumber: 'Personnes physiques américaines',
    ERROR_FIELD_userOtherAmericanIndividualsNumber_required: 'Veuillez indiquer le nombre de personnes physiques américaines.',
    ERROR_FIELD_userOtherAmericanIndividualsNumber_digit: 'Veuillez indiquer un nombre entre 0 et 10.',
    ERROR_FIELD_userOtherAmericanIndividualsNumber_min: 'Veuillez indiquer un nombre entre 0 et 10.',
    ERROR_FIELD_userOtherAmericanIndividualsNumber_max: 'Veuillez indiquer un nombre entre 0 et 10.',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber: 'Personnes physiques autres qu\'américaines',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber_required: 'Veuillez indiquer le nombre de personnes physiques autres qu\'américaines.',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber_digit: 'Veuillez indiquer un nombre entre 0 et 10.',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber_min: 'Veuillez indiquer un nombre entre 0 et 10.',
    ERROR_FIELD_userOtherThanAmericanIndividualsNumber_max: 'Veuillez indiquer un nombre entre 0 et 10.',
    ERROR_FIELD_userOtherAmericanResidentIndicator: 'Résident américain',
    ERROR_FIELD_userOtherAmericanResidentIndicator_required: 'Veuillez sélectionner une réponse.',

    // Transfer request choice
    ERROR_FIELD_hasTransfers: 'Transfert depuis une autre institution financière',
    ERROR_FIELD_hasTransfers_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_transfer: 'Comptes avec demande de transfert',
    ERROR_FIELD_transfer_required: 'Veuillez indiquer au moins un compte avec demande de transfert.',
    ERROR_FIELD_jointTransfer: 'Comptes avec demande de transfert',
    ERROR_FIELD_jointTransfer_required: 'Veuillez indiquer au moins un compte avec demande de transfert.',
    ERROR_FIELD_otherTransfer: 'Comptes avec demande de transfert',
    ERROR_FIELD_otherTransfer_required: 'Veuillez indiquer au moins un compte avec demande de transfert.',

    // Transfer request
    ERROR_FIELD_actTypeCurrency_required: 'Veuillez indiquer une devise de compte.',
    ERROR_FIELD_actTypeCurrency: 'Devise du compte',
    ERROR_FIELD_bankName_required: 'Veuillez indiquer le nom de l\'institution cédante.',
    ERROR_FIELD_bankName: 'Nom de l\'institution cédante',
    ERROR_FIELD_bankPhone_phone: 'Veuillez indiquer un numéro de téléphone valide.',
    ERROR_FIELD_bankPhone: 'Numéro de téléphone',
    ERROR_FIELD_addressStreet_required: 'Veuillez indiquer le numéro et nom de rue de l\'institution cédante.',
    ERROR_FIELD_addressStreet: 'Adresse',
    ERROR_FIELD_addressCity_required: 'Veuillez indiquer le nom de la ville de l\'institution cédante.',
    ERROR_FIELD_addressCity: 'Ville',
    ERROR_FIELD_addressProv_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_addressProv: 'Province',
    ERROR_FIELD_addressPostalCode_required: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_addressPostalCode_zipcode: 'Veuillez indiquer un code postal valide.',
    ERROR_FIELD_addressPostalCode: 'Code postal',
    ERROR_FIELD_accountNumber_required: 'Veuillez indiquer un numéro de compte.',
    ERROR_FIELD_accountNumberSec_required: 'Veuillez indiquer un numéro de compte.',
    ERROR_FIELD_accountNumber: 'Numéro de compte chez le cédant',
    ERROR_FIELD_accountNumber_alphanumeric: 'Veuillez indiquer un numéro de compte valide.',
    ERROR_FIELD_accountNumberSec: 'Numéro de compte chez le cédant',
    ERROR_FIELD_accountType_required: 'Veuillez indiquer un type de compte.',
    ERROR_FIELD_accountTypeSec_required: 'Veuillez indiquer un type de compte.',
    ERROR_FIELD_accountType: 'Type de compte',
    ERROR_FIELD_accountTypeSec: 'Type de compte',
    ERROR_FIELD_accountCurrency_required: 'Veuillez indiquer une devise de compte.',
    ERROR_FIELD_accountCurrencySec_required: 'Veuillez indiquer une devise de compte.',
    ERROR_FIELD_accountCurrency: 'Devise du compte',
    ERROR_FIELD_accountCurrencySec: 'Devise du compte',
    ERROR_FIELD_transferType_required: 'Veuillez indiquer un type de transfert.',
    ERROR_FIELD_transferType: 'Type de transfert',
    ERROR_FIELD_transferInstructionType_required: 'Veuillez indiquer des instructions de transfert.',
    ERROR_FIELD_transferInstructionType: 'Instructions de transfert',
    ERROR_FIELD_transferCash_required: 'Veuillez indiquer un montant à transférer.',
    ERROR_FIELD_transferCash_max: 'Veuillez indiquer un montant à transférer valide.',
    ERROR_FIELD_transferCash: 'Montant à transférer',
    ERROR_FIELD_description_required: 'Veuillez indiquer une description.',
    ERROR_FIELD_description_Required: 'Veuillez indiquer une description.',
    ERROR_FIELD_description: 'Description',
    ERROR_FIELD_quantity_required: 'Veuillez indiquer une quantité.',
    ERROR_FIELD_quantity_max: 'Veuillez indiquer une quantité valide.',
    ERROR_FIELD_quantity: 'Quantité',
    ERROR_FIELD_type_required: 'Veuillez indiquer un type.',
    ERROR_FIELD_type: 'Type',
    ERROR_FIELD_accountStatementName: 'Relevés de comptes',
    ERROR_FIELD_accountStatementName_exceedFileSize: 'Vous avez atteint la taille maximale de relevés de compte joints à votre demande (1.5 Mo).',
    ERROR_FIELD_accountStatementName_invalidFormat: 'Veuillez sélectionner un fichier PDF valide.',
    ERROR_FIELD_accountStatementName_fileEncrypted: 'Veuillez utiliser une version non cryptée et non corrompue de votre document.',
    ERROR_FIELD_accountStatementName_fileInfected: 'L\'analyse antivirus du fichier sélectionné a échoué.',
    ERROR_FIELD_accountStatementName_antivirusNotAvailable: 'Nous éprouvons actuellement certains problèmes techniques. Veuillez réessayer plus tard.',
    ERROR_FIELD_accountStatementName_invalidDocument: 'Veuillez sélectionner un document valide.',
    ERROR_FIELD_newExistAct_pattern: 'L\'ajout de compte n\'est pas disponible pour l\'ouverture automatisée.',

    ERROR_FIELD_transmissionMethod: 'Choix de transmission',
    ERROR_FIELD_transmissionMethod_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_attachedDocument_prefix: 'Document {{index}}',
    ERROR_FIELD_attachedDocumentName: 'Type de pièce jointe',
    ERROR_FIELD_attachedDocumentName_required: 'Veuillez indiquer un type.',
    ERROR_FIELD_attachedDocumentName_attachedDocumentUnique: 'Les documents joints doivent tous être d\'un type différent.',
    ERROR_FIELD_attachedDocument: 'Pièce jointe',
    ERROR_FIELD_attachedDocument_required: 'Veuillez sélectionner un fichier à téléverser.',
    ERROR_FIELD_attachedDocument_invalidFormat: 'Veuillez sélectionner un fichier JPEG, PNG ou PDF valide.',
    ERROR_FIELD_attachedDocument_fileEncrypted: 'Veuillez utiliser une version non cryptée et non corrompue de votre document ou le joindre en format JPEG.',
    ERROR_FIELD_attachedDocument_fileInfected: 'L\'analyse antivirus du fichier sélectionné a échoué.',
    ERROR_FIELD_attachedDocument_antivirusNotAvailable: 'Nous éprouvons actuellement certains problèmes techniques. Veuillez réessayer plus tard.',
    ERROR_FIELD_attachedDocument_weightInvalid: 'Le fichier sélectionné dépasse la limite autorisée. Veuillez en réduire la taille.',
    ERROR_FIELD_attachedDocument_invalidDocument: 'Veuillez sélectionner un document valide.',
    ERROR_FIELD_attachedDocumentNameBack: 'Type de pièce jointe',
    ERROR_FIELD_attachedDocumentNameBack_required: 'Veuillez indiquer un type.',
    ERROR_FIELD_attachedDocumentNameBack_attachedDocumentUnique: 'Les documents joints doivent tous être d\'un type différent.',
    ERROR_FIELD_attachedDocumentBack: 'Pièce jointe verso',
    ERROR_FIELD_attachedDocumentBack_required: 'Veuillez sélectionner un fichier à téléverser.',
    ERROR_FIELD_attachedDocumentBack_invalidFormat: 'Veuillez sélectionner un fichier JPEG, PNG ou PDF valide.',
    ERROR_FIELD_attachedDocumentBack_fileEncrypted: 'Veuillez utiliser une version non cryptée et non corrompue de votre document ou le joindre en format JPEG.',
    ERROR_FIELD_attachedDocumentBack_fileInfected: 'L\'analyse antivirus du fichier sélectionné a échoué.',
    ERROR_FIELD_attachedDocumentBack_antivirusNotAvailable: 'Nous éprouvons actuellement certains problèmes techniques. Veuillez réessayer plus tard.',
    ERROR_FIELD_attachedDocumentBack_weightInvalid: 'Le fichier sélectionné dépasse la limite autorisée. Veuillez en réduire la taille.',
    ERROR_FIELD_attachedDocumentBack_invalidDocument: 'Veuillez sélectionner un document valide.',

    ERROR_FIELD_userMetAtOffice: 'Client rencontré en personne',
    ERROR_FIELD_userMetAtOffice_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_userIdentityCodeCaisse: 'Pièce d\'identité',
    ERROR_FIELD_userIdentityCode: 'Pièces d\'identités',
    ERROR_FIELD_firstIdentityCode: 'Document 1 - Pièces d\'identités',
    ERROR_FIELD_secondIdentityCode: 'Document 2 - Pièces d\'identités',
    ERROR_FIELD_userIdentityCode_required: 'Veuillez sélectionner une pièce d\'identité.',
    ERROR_FIELD_firstIdentityCode_required: 'Veuillez sélectionner une pièce d\'identité.',
    ERROR_FIELD_secondIdentityCode_required: 'Veuillez sélectionner une pièce d\'identité.',

    ERROR_FIELD_userIdentityAdvisorFamilyName: 'Nom du conseiller de la Caisse',
    ERROR_FIELD_userIdentityAdvisorFamilyName_required: 'Veuillez indiquer le nom du conseiller de la Caisse.',
    ERROR_FIELD_userIdentityAdvisorFirstName: 'Prenom du conseiller de la Caisse',
    ERROR_FIELD_userIdentityAdvisorFirstName_required: 'Veuillez indiquer le prénom du conseiller de la Caisse.',
    ERROR_FIELD_userIdentityAdvisorBranchName: 'Nom de la Caisse Desjardins',
    ERROR_FIELD_userIdentityAdvisorBranchName_required: 'Veuillez indiquer le nom de la Caisse Desjardins.',
    ERROR_FIELD_userIdentityAdvisorInstitution: 'Institution',
    ERROR_FIELD_userIdentityAdvisorInstitution_required: 'Veuillez indiquer l\'institution.',
    ERROR_FIELD_userIdentityAdvisorTransit: 'Transit',
    ERROR_FIELD_userIdentityAdvisorTransit_required: 'Veuillez indiquer le transit.',

    ERROR_FIELD_userIdentityIDNumber: 'Numéro d\'identité unique (numéro du document)',
    ERROR_FIELD_firstIdentityIDNumber: 'Document 1 - Numéro d\'identité unique (numéro du document)',
    ERROR_FIELD_secondIdentityIDNumber: 'Document 2 - Numéro d\'identité unique (numéro du document)',
    ERROR_FIELD_userIdentityIDNumber_required: 'Veuillez indiquer le numéro d\'identité unique (numéro du document).',
    ERROR_FIELD_firstIdentityIDNumber_required: 'Veuillez indiquer le numéro d\'identité unique (numéro du document).',
    ERROR_FIELD_secondIdentityIDNumber_required: 'Veuillez indiquer le numéro d\'identité unique (numéro du document).',
    ERROR_FIELD_userIdentityExpirationDate: 'Date d\'expiration',
    ERROR_FIELD_firstIdentityExpirationDate: 'Document 1 - Date d\'expiration',
    ERROR_FIELD_secondIdentityExpirationDate: 'Document 2 - Date d\'expiration',
    ERROR_FIELD_firstIdentityDate: 'Document 1 - Date d\'expiration ou d\'émission',
    ERROR_FIELD_secondIdentityDate: 'Document 2 - Date d\'expiration ou d\'émission',
    ERROR_FIELD_userIdentityExpirationDate_required: 'Veuillez indiquer une date d\'expiration valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_userIdentityExpirationDate_date: 'Veuillez indiquer une date d\'expiration valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_userIdentityExpirationDate_dateinfuture: 'La date d\'expiration doit être postérieure à la date du jour.',
    ERROR_FIELD_firstIdentityExpirationDate_required: 'Veuillez indiquer une date d\'expiration valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_firstIdentityExpirationDate_date: 'Veuillez indiquer une date d\'expiration valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_firstIdentityExpirationDate_dateinfuture: 'La date d\'expiration doit être postérieure à la date du jour.',
    ERROR_FIELD_secondIdentityExpirationDate_required: 'Veuillez indiquer une date d\'expiration valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_secondIdentityExpirationDate_date: 'Veuillez indiquer une date d\'expiration valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_secondIdentityExpirationDate_dateinfuture: 'La date d\'expiration doit être postérieure à la date du jour.',
    ERROR_FIELD_firstIdentityDate_required: 'Veuillez indiquer une date d\'expiration ou d\'émission valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_secondIdentityDate_required: 'Veuillez indiquer une date d\'expiration ou d\'émission valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_firstIdentityDate_date: 'Veuillez indiquer une date d\'expiration valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_secondIdentityDate_date: 'Veuillez indiquer une date d\'expiration valide sous le format AAAA-MM-JJ.',
    ERROR_FIELD_userIdentityProvince: 'Province ou territoire émettrice-teur du Canada',
    ERROR_FIELD_firstIdentityProvince: 'Document 1 - Province ou territoire émettrice-teur du Canada',
    ERROR_FIELD_secondIdentityProvince: 'Document 2 - Province ou territoire émettrice-teur du Canada',
    ERROR_FIELD_userIdentityProvince_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_firstIdentityProvince_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_secondIdentityProvince_required: 'Veuillez sélectionner une province.',
    ERROR_FIELD_userIdentityCountry: 'Pays de délivrance',
    ERROR_FIELD_firstIdentityCountry: 'Document 1 - Pays de délivrance',
    ERROR_FIELD_secondIdentityCountry: 'Document 2 - Pays de délivrance',
    ERROR_FIELD_userIdentityCountry_required: 'Veuillez sélectionner un pays.',
    ERROR_FIELD_firstIdentityCountry_required: 'Veuillez sélectionner un pays.',
    ERROR_FIELD_secondIdentityCountry_required: 'Veuillez sélectionner un pays.',
    ERROR_FIELD_userIdentityVerificationDate: 'Date de la verification',
    ERROR_FIELD_userIdentityVerificationDate_required: 'Veuillez indiquer une date de verification valide sous le format aaaa-mm-jj.',
    ERROR_FIELD_userIdentityVerificationDate_date: 'Veuillez indiquer une date de verification valide sous le format aaaa-mm-jj.',

    ERROR_FIELD_esignUserPhoneChoice: 'Veuillez faire un choix',
    ERROR_FIELD_esignUserPhoneChoice_required: 'Veuillez sélectionner un numéro de téléphone.',
    ERROR_FIELD_esignUserPhoneOther: 'Numéro de téléphone',
    ERROR_FIELD_esignUserPhoneOther_required: 'Veuillez indiquer le numéro de téléphone.',
    ERROR_FIELD_esignUserPhoneOther_phone: 'Veuillez indiquer un numéro de téléphone valide.',

    ERROR_FIELD_jointActPeople: 'Nombre de personnes',
    ERROR_FIELD_jointActPeople_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_resQc: 'Résident de la province du Québec',
    ERROR_FIELD_resQc_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_survivorShip: 'Gain de survie',
    ERROR_FIELD_survivorShip_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_associationActPeople: 'Personnes physiques autres qu\'américaines',
    ERROR_FIELD_associationActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_associationActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_associationActPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_associationActPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_associationActAmericanPeople: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ERROR_FIELD_associationActAmericanPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_associationActAmericanPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_associationActAmericanPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_associationActAmericanPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_associationHoldingAccount: 'Détention d\'un compte',
    ERROR_FIELD_associationHoldingAccount_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_associationLinkedAccount: 'Lier un compte',
    ERROR_FIELD_associationLinkedAccount_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_clubActPeople: 'Personnes physiques autres qu\'américaines',
    ERROR_FIELD_clubActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_clubActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_clubActPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_clubActPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_clubActAmericanPeople: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ERROR_FIELD_clubActAmericanPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_clubActAmericanPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_clubActAmericanPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_clubActAmericanPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_clubHoldingAccount: 'Détention d\'un compte',
    ERROR_FIELD_clubHoldingAccount_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_clubLinkedAccount: 'Lier un compte',
    ERROR_FIELD_clubLinkedAccount_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_cooperativeHoldingAccount: 'Détention d\'un compte',
    ERROR_FIELD_cooperativeHoldingAccount_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_cooperativeLinkedAccount: 'Lier un compte',
    ERROR_FIELD_cooperativeLinkedAccount_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_corporationOneShareholder: 'Actionnaires',
    ERROR_FIELD_corporationOneShareholder_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_corporationHoldingAccount: 'Détention d\'un compte',
    ERROR_FIELD_corporationHoldingAccount_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_corporationLinkedAccount: 'Lier un compte',
    ERROR_FIELD_corporationLinkedAccount_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_couniqueActPeople: 'Membres',
    ERROR_FIELD_couniqueActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_couniqueActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_couniqueActPeople_min: 'Veuillez saisir un nombre entre 1 et 10.',
    ERROR_FIELD_couniqueActPeople_max: 'Veuillez saisir un nombre entre 1 et 10.',
    ERROR_FIELD_couniqueHoldingAccount: 'Détention d\'un compte',
    ERROR_FIELD_couniqueHoldingAccount_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_couniqueLinkedAccount: 'Lier un compte',
    ERROR_FIELD_couniqueLinkedAccount_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_enfesqualitesAddSpouse: 'Ajouter votre conjoint au compte',
    ERROR_FIELD_enfesqualitesAddSpouse_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_enfesqualitesResQc: 'Résident de la province du Québec',
    ERROR_FIELD_enfesqualitesResQc_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_enfesqualitesSurvivorShip: 'Gain de survie',
    ERROR_FIELD_enfesqualitesSurvivorShip_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_enfrefAddSpouse: 'Ajouter votre conjoint au compte',
    ERROR_FIELD_enfrefAddSpouse_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_enfrefResQc: 'Résident de la province du Québec',
    ERROR_FIELD_enfrefResQc_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_enfrefSurvivorShip: 'Gain de survie',
    ERROR_FIELD_enfrefSurvivorShip_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_fiducieType: 'Type de fiducie',
    ERROR_FIELD_fiducieType_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_fiducieActPeople: 'Personnes physiques autres qu\'américaines',
    ERROR_FIELD_fiducieActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_fiducieActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_fiducieActPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_fiducieActPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_fiducieHasAmericanResident: 'Résident américain',
    ERROR_FIELD_fiducieHasAmericanResident_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_fiducieActAmericanPeople: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ERROR_FIELD_fiducieActAmericanPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_fiducieActAmericanPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_fiducieActAmericanPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_fiducieActAmericanPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_osblHoldingAccount: 'Détention d\'un compte',
    ERROR_FIELD_osblHoldingAccount_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_osblLinkedAccount: 'Lier un compte',
    ERROR_FIELD_osblLinkedAccount_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_sencActPeople: 'Personnes physiques autres qu\'américaines',
    ERROR_FIELD_sencActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_sencActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_sencActPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_sencActPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_sencActAmericanPeople: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ERROR_FIELD_sencActAmericanPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_sencActAmericanPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_sencActAmericanPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_sencActAmericanPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_sencLegActPeople: 'Sociétaires - Nombre de personnes morales',
    ERROR_FIELD_sencLegActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_sencLegActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_sencLegActPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_sencLegActPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_sencLegActPeople_actPeopleSumMin: 'La somme du nombre de personnes physiques et du nombre de personnes morales doit être entre 1 et 10.',
    ERROR_FIELD_sencLegActPeople_actPeopleSumMax: 'La somme du nombre de personnes physiques et du nombre de personnes morales doit être entre 1 et 10.',
    ERROR_FIELD_sencHoldingAccount: 'Détention d\'un compte',
    ERROR_FIELD_sencHoldingAccount_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_sencLinkedAccount: 'Lier un compte',
    ERROR_FIELD_sencLinkedAccount_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_soccommActPeople: 'Personnes physiques autres qu\'américaines',
    ERROR_FIELD_soccommActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_soccommActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_soccommActPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_soccommActPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_soccommActAmericanPeople: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ERROR_FIELD_soccommActAmericanPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_soccommActAmericanPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_soccommActAmericanPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_soccommActAmericanPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',

    ERROR_FIELD_soccommLegActPeople: 'Sociétaires - Nombre de personnes morales',
    ERROR_FIELD_soccommLegActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_soccommLegActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_soccommLegActPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_soccommLegActPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_soccommLegActPeople_actPeopleSumMin: 'La somme du nombre de personnes physiques et du nombre de personnes morales doit être entre 1 et 10.',
    ERROR_FIELD_soccommLegActPeople_actPeopleSumMax: 'La somme du nombre de personnes physiques et du nombre de personnes morales doit être entre 1 et 10.',
    ERROR_FIELD_soccommHoldingAccount: 'Détention d\'un compte',
    ERROR_FIELD_soccommHoldingAccount_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_soccommLinkedAccount: 'Lier un compte',
    ERROR_FIELD_soccommLinkedAccount_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_succDeceasedProvince: 'Province de résidence du défunt',
    ERROR_FIELD_succDeceasedProvince_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_succAccountValueGreaterLimit: 'Valeur du compte',
    ERROR_FIELD_succAccountValueGreaterLimit_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_succWill: 'Testament ou un contrat de mariage',
    ERROR_FIELD_succWill_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_succActPeople: 'Liquidateurs',
    ERROR_FIELD_succActPeople_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_succActPeople_digit: 'Veuillez saisir un nombre.',
    ERROR_FIELD_succActPeople_min: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_succActPeople_max: 'Veuillez saisir un nombre entre 0 et 10.',
    ERROR_FIELD_succAccountHasAmericanResident: 'Résident américain',
    ERROR_FIELD_succAccountHasAmericanResident_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_password_password: 'Votre mot de passe doit contenir entre 6 et 8 caractères, et au moins une lettre et un chiffre.',
    ERROR_FIELD_newProfileSecurityQuestion: 'Question de sécurité',
    ERROR_FIELD_newProfileSecurityQuestion_required: 'Veuillez sélectionner une question de sécurité.',
    ERROR_FIELD_newProfileSecurityAnswer: 'Réponse',
    ERROR_FIELD_newProfileSecurityAnswer_required: 'Veuillez saisir une réponse de sécurité.',
    ERROR_FIELD_newProfileSecurityAnswer_minlength: 'Votre réponse doit contenir au moins 5 caractères.',

    ERROR_FIELD_newPassword: 'Nouveau mot de passe',
    ERROR_FIELD_newPassword_required: 'Veuillez saisir un nouveau mot de passe.',
    ERROR_FIELD_newPassword_minlength: 'Veuillez saisir un nouveau mot de passe de 8 caractères au minimum.',
    ERROR_FIELD_confirmNewPassword: 'Confirmez le mot de passe',
    ERROR_FIELD_confirmNewPassword_required: 'Veuillez saisir la confirmation du nouveau mot de passe',
    ERROR_FIELD_confirmNewPassword_passwordMatch: 'Les mots de passe que vous avez entrés ne sont pas identiques.',
    ERROR_FIELD_oldPassword: 'Mot de passe temporaire',
    ERROR_FIELD_oldPassword_required: 'Veuillez saisir le mot de passe temporaire',
    ERROR_FIELD_password_required: 'Veuillez saisir un mot de passe ',
    ERROR_FIELD_resetPasswordSecurityQuestion_required: 'Veuillez sélectionner une question sécurité.',
    ERROR_FIELD_resetPasswordSecurityAnswer_required: 'Veuillez saisir une réponse de sécurité.',
    ERROR_FIELD_resetPasswordSecurityAnswer_minlength: 'Votre réponse doit contenir au moins 5 caractères.',

    ERROR_FIELD_ippRegistrationNumber: 'Numéro d\'enregistrement pour RRI',
    ERROR_FIELD_ippRegistrationNumber_required: 'Veuillez saisir un numéro d\'enregistrement pour RRI',
    ERROR_FIELD_ippAuthorizedTradePersonNumber: 'Nombre de personnes autorisées à transiger',
    ERROR_FIELD_ippAuthorizedTradePersonNumber_required: 'Veuillez saisir un nombre de personnes autorisées à transiger',
    ERROR_FIELD_ippAuthorizedTradePersonNumber_min: 'Veuillez saisir une valeur de 1 à 10',
    ERROR_FIELD_ippAuthorizedTradePersonNumber_max: 'Veuillez saisir une valeur de 1 à 10',
    ERROR_FIELD_ippAuthorizedTradePersonNumber_digit: 'Veuillez saisir un nombre.',

    ERROR_FIELD_gouvOrgCity: 'S\'agit-il d\'une ville ?',
    ERROR_FIELD_gouvOrgCity_required: 'Veuillez sélectionner une réponse.',

    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg: 'Objectifs de placements - Titres à revenus à faible risque',
    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg_min: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg_max: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskNonReg_investmentObjectives: 'La répartition des objectifs de placements doit totaliser 100 %.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg: 'Objectifs de placements - Titres à revenus à risque modéré',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg_min: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg_max: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskNonReg_investmentObjectives: 'La répartition des objectifs de placements doit totaliser 100 %.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg: 'Objectifs de placements - Titres à revenus et titres de croissance, de risque modéré à plus élevé',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg_min: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg_max: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskNonReg_investmentObjectives: 'La répartition des objectifs de placements doit totaliser 100 %.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg: 'Objectifs de placements - Titres de risque élevé à très élevé',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg_min: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg_max: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesNonReg_investmentObjectives: 'La répartition des objectifs de placements doit totaliser 100 %.',

    ERROR_FIELD_userInvestmentObjectivesLowRiskReg: 'Comptes enregistrés - Titres à revenus à faible risque',
    ERROR_FIELD_userInvestmentObjectivesLowRiskReg_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskReg_min: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskReg_max: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesLowRiskReg_investmentObjectives: 'La répartition des objectifs de placements doit totaliser 100 %.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg: 'Comptes enregistrés - Titres à revenus à risque modéré',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg_min: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg_max: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesModerateRiskReg_investmentObjectives: 'La répartition des objectifs de placements doit totaliser 100 %.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg: 'Comptes enregistrés - Titres à revenus et titres de croissance, de risque modéré à plus élevé',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg_min: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg_max: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesModHighRiskReg_investmentObjectives: 'La répartition des objectifs de placements doit totaliser 100 %.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg: 'Comptes enregistrés - Titres spéculatifs et stratégies boursières',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg_required: 'Veuillez saisir un nombre.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg_min: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg_max: 'Veuillez saisir une valeur de 0 à 100.',
    ERROR_FIELD_userInvestmentObjectivesSpeculativeStrategiesReg_investmentObjectives: 'La répartition des objectifs de placements doit totaliser 100 %.',

    ERROR_FIELD_fhsaEligibility: 'Admissibilité au CELIAPP',
    ERROR_FIELD_fhsaEligibility_required: 'Veuillez sélectionner une réponse.',
    ERROR_FIELD_fhsaAccountMgntType: 'Type de gestion - CELIAPP',
    ERROR_FIELD_fhsaCurrency: 'Devise du compte - CELIAPP',
    ERROR_FIELD_fhsaCurrency_required: 'Veuillez sélectionner la devise du compte.',
    ERROR_FIELD_fhsaAccountMgntType_invalidMgntType: 'Tous les comptes d\'une même demande doivent être soit en services-conseils à commission,' +
        ' soit en gestion discrétionnaire.  Veuillez faire une autre demande pour les comptes dont le type de gestion diffère.',

    ERROR_FIELD_passwordStrength: 'Mot de passe faible',
    ERROR_FIELD_passwordStrength_min: 'Mot de passe faible détecté. Veuillez créer un mot de passe qui respecte nos suggestions de complexité.',

    NOT_STANDARD_DATA_ERROR: 'Dû à un format non standard récupéré de nos systèmes métiers, la ou les données suivantes ne seront' +
        ' pas tenues en compte dans le processus de mise à jour.'
};
