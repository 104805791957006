import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {I18N_NAV_MESSAGES_FR} from '../../components/i18n/nav-messages_fr';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {I18N_NAV_MESSAGES_EN} from '../../components/i18n/nav-messages_en';
import {FormService} from '../../components/services/form.service';
import {SessionService} from '../../components/services/session.service';
import {RecoveryService} from '../../components/services/recovery.service';
import {WebException} from '../../components/models';
import {RecoveryBean} from '../../components/models/recoveryBean';
import {ProfileAccountOpeningRequestListComponent} from './profile-account-opening-request-list.component';
import {RecaptchaComponent} from './recaptcha.component';
import {ProfileService} from '../../components/services/profile.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {ListModelService} from '../../components/services/list-model.service';
import {ProfileBean} from '../../components/models/ProfileBean';
import {VmdForm} from '../../components/utils/vmd-form';

declare let $: any;
declare const grecaptcha: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    providers: [TranslatePipe]
})
export class LoginComponent implements OnInit {

    @ViewChild(ProfileAccountOpeningRequestListComponent)
    profileAccountOpeningRequestList: ProfileAccountOpeningRequestListComponent;
    @ViewChild(RecaptchaComponent)
    recaptchaComponent: RecaptchaComponent;
    @ViewChild(NgForm) ngForm: NgForm;

    form: FormGroup;
    errMsgLogin: string = null;
    loading = false;
    recoveryBean: RecoveryBean;
    constants = VmdConstants;
    reCaptchaResponse: string = null;


    list: any[] = null;
    showList = false;
    protected questionSecuriteList: HtmlSelectKV[];

    constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder,
                private translate: TranslateService, private formService: FormService,
                private sessionService: SessionService, private recoveryService: RecoveryService,
                private profileService: ProfileService, private listModelService: ListModelService,
                private translatePipe: TranslatePipe) {

        this.createForm();
    }

    ngOnInit() {
        this.listModelService.getListModel('questionSecuriteList', data => this.questionSecuriteList = data);

        this.sessionService.getNewToken().subscribe((data: any) => {

            this.sessionService.beginSession(data.payload);
        });

        const routerState = '_routerState';
        const url = this.route[routerState].snapshot.url;
        if (url === '/' + I18N_NAV_MESSAGES_FR.ODC_NAV_LOGIN_PATH && this.translate.currentLang !== VmdConstants.LANG_FR) {
            this.translate.use(VmdConstants.LANG_FR);
        } else if (url === '/' + I18N_NAV_MESSAGES_EN.ODC_NAV_LOGIN_PATH && this.translate.currentLang !== VmdConstants.LANG_EN) {
            this.translate.use(VmdConstants.LANG_EN);
        }

        this.enableLoginSection();
    }

    createForm() {
        this.form = this.fb.group({
            login: this.fb.group({
                courriel: [null, [Validators.required, VmdValidators.email]],
                motDePasse: [null, Validators.required]
            }),
            passwords: this.fb.group({
                newPassword: [null, [Validators.required, Validators.minLength(VmdConstants.PASSWORD_LENGTH_MIN)]],
                confirmNewPassword: [null, [Validators.required]],
                passwordStrength: [null, [Validators.min(VmdConstants.PASSWORD_STRENGTH_MIN)]]
            }, {validator: VmdValidators.passwordMatchValidator()}),
            question: this.fb.group({
                newProfileSecurityQuestion: [null, [Validators.required]],
                newProfileSecurityAnswer: [null, [Validators.required, Validators.minLength(5)]]
            }),
            recaptcha: []
        });
    }

    displayList() {
        this.enableListSection();

        if (!this.list) {
            this.errMsgLogin = 'GLOBAL_ERROR_MSG';
        } else if (this.list.length === 0) {
            this.errMsgLogin = 'ODC_RESET_PASSWORD_ERR_EMPTY_ACCOUNT_OPENING_REQUEST_LIST';
        } else if (this.list.length === 1) {
            this.profileAccountOpeningRequestList.resumeAccountOpeningRequest(this.list[0]);
        } else {
            this.enableListSection();
            this.profileAccountOpeningRequestList.getAccountOpeningRequestList();
        }
    }

    private enableLoginSection(): void {
        this.form.get('login').enable();
        this.form.get('login').markAsUntouched();
        this.form.get('question').disable();
        this.form.get('passwords').disable();
        $('#content').focus();
    }

    private enableQuestionSection(): void {
        this.form.get('login').disable();
        this.form.get('question').enable();
        this.form.get('question').markAsUntouched();
        this.form.get('passwords').disable();
        $('#content').focus();
    }

    private enablePasswordsSection(): void {
        this.form.get('login').disable();
        this.form.get('question').disable();
        this.form.get('passwords').enable();
        this.form.get('passwords').markAsUntouched();
        $('#content').focus();
    }

    private enablePasswordsAndQuestionSections(): void {
        this.form.get('login').disable();
        this.form.get('question').enable();
        this.form.get('question').markAsUntouched();
        this.form.get('passwords').enable();
        this.form.get('passwords').markAsUntouched();
        $('#content').focus();
    }

    private enableListSection(): void {
        this.showList = true;
        this.form.get('login').disable();
        this.form.get('question').disable();
        this.form.get('passwords').disable();
        $('#content').focus();
    }

    focusOnErrorsList() {
        setTimeout(() => {
            const firstError = ($('#boite-erreurs').first().length > 0) ? $('#boite-erreurs').first() : $('.has-error input, .has-error select').first();
            if (firstError.length > 0) {
                $(firstError).focus();
            }
        }, 0);
    }

    goToResetPassword(): void {
        this.router.navigate(['/', this.translatePipe.transform('ODC_NAV_RESET_PASSWORD_PATH')]);
    }

    isFieldInError(path: string): boolean {
        return VmdForm.isFieldInError(this.form.get(path), this.isFormSubmitted());
    }

    isFormSubmitted(): boolean {
        if (this.ngForm) {
            return this.ngForm.submitted;
        }

        return false;
    }

    login() {
        this.loading = true;

        this.recoveryBean = new RecoveryBean();
        this.recoveryBean.email = this.form.get('login.courriel').value;
        this.recoveryBean.password = this.form.get('login.motDePasse').value;
        this.recoveryBean.reCaptchaResponse = this.reCaptchaResponse;

        this.recoveryService.authenticate(this.recoveryBean).subscribe(
            (data) => {
                this.sessionService.beginSession(data.payload.jSessionId);
                this.onLoginSuccess(data);
            },
            error => this.onRecoveryError(error)
        );
    }

    onLoginSuccess(data: any) {
        this.loading = false;

        if (data.payload) {
            this.list = data.payload.requests;
            if (data.payload.newPasswordType) {
                if (data.payload.temporaryPassword && data.payload.securityQuestionSet) {
                    this.enablePasswordsSection();
                } else if (data.payload.temporaryPassword && !data.payload.securityQuestionSet) {
                    this.enablePasswordsAndQuestionSections();
                } else if (!data.payload.securityQuestionSet) {
                    this.enableQuestionSection();
                } else {
                    this.displayList();
                }
            } else {
                this.displayList();
            }
        }

    }

    onRecoveryError(error: WebException) {
        this.loading = false;
        this.setError(error);
    }

    onSubmit() {
        this.errMsgLogin = null;
        VmdForm.markAsTouched(this.form);

        if (this.form.valid) {
            if (this.form.get('login').enabled) {
                this.login();
            } else if (this.form.get('passwords').enabled && this.form.get('question').enabled) {
                this.onSubmitPasswordsAndQuestion();
            } else if (this.form.get('passwords').enabled) {
                this.onSubmitPasswords();
            } else if (this.form.get('question').enabled) {
                this.onSubmitQuestion();
            }
        } else {
            this.focusOnErrorsList();
        }

        /*else if (
            (this.form.get('login.courriel').errors && this.form.get('login.courriel').errors.required)
            || (this.form.get('login.motDePasse').errors && this.form.get('login.motDePasse').errors.required)
        ) {
            this.errMsgLogin = 'ODC_LOGIN_ERR_MISSING_INFO';
        } else if (this.form.get('login.courriel').errors && this.form.get('login.courriel').errors.email) {
            this.errMsgLogin = 'ODC_LOGIN_ERR_INVALID_EMAIL';
        } else if (this.form.get('passwords.newPassword').errors && this.form.get('passwords.newPassword').errors.required) {
            this.errMsgLogin = 'ODC_RESET_PASSWORD_ERR_MISSING_NEW_PASSWORD';
        } else if (this.form.get('passwords.newPassword').errors && this.form.get('passwords.newPassword').errors.password) {
            this.errMsgLogin = 'ERROR_FIELD_newPassword_pattern';
        } else if (this.form.get('passwords.confirmNewPassword').errors && this.form.get('passwords.confirmNewPassword').errors.required) {
            this.errMsgLogin = 'ODC_RESET_PASSWORD_ERR_MISSING_CONFIRM_NEW_PASSWORD';
        } else if (this.form.get('passwords.confirmNewPassword').errors && this.form.get('passwords.confirmNewPassword').errors.passwordMatch) {
            this.errMsgLogin = 'ODC_RESET_PASSWORD_ERR_INVALID_CONFIRM_NEW_PASSWORD';
        } else if (this.form.get('question.newProfileSecurityQuestion').errors && this.form.get('question.newProfileSecurityQuestion').errors.required) {
            this.errMsgLogin = 'ERROR_FIELD_resetPasswordSecurityQuestion_required';
        } else if (this.form.get('question.newProfileSecurityAnswer').errors && this.form.get('question.newProfileSecurityAnswer').errors.required) {
            this.errMsgLogin = 'ERROR_FIELD_resetPasswordSecurityAnswer_required';
        } else if (this.form.get('question.newProfileSecurityAnswer').errors && this.form.get('question.newProfileSecurityAnswer').errors.minlength) {
            this.errMsgLogin = 'ERROR_FIELD_resetPasswordSecurityAnswer_minlength';
        } else {
            this.errMsgLogin = 'GLOBAL_ERROR_MSG';
        }*/
    }

    onSubmitPasswords() {
        this.loading = true;

        const oldPassword = this.form.get('login.motDePasse').value;
        const newPassword = this.form.get('passwords.newPassword').value;
        const confirmNewPassword = this.form.get('passwords.confirmNewPassword').value;

        this.profileService.authenticateTempPassword(oldPassword, newPassword, confirmNewPassword).subscribe((data) => {
                this.loading = false;
                this.sessionService.beginSession(data.payload);
                this.displayList();
            },
            (error: WebException) => {
                this.loading = false;
                this.setError(error);
            });
    }

    onSubmitQuestion() {
        this.loading = true;

        const profileBean = new ProfileBean();
        profileBean.securityQuestion = this.form.get('question.newProfileSecurityQuestion').value;
        profileBean.securityAnswer = this.form.get('question.newProfileSecurityAnswer').value;

        this.profileService.createSecurityQuestion(profileBean).subscribe(() => {
                this.loading = false;
                this.displayList();
            },
            (error: WebException) => {
                this.loading = false;
                this.setError(error);
            });
    }

    onSubmitPasswordsAndQuestion() {
        this.loading = true;

        const profileBean = new ProfileBean();
        profileBean.securityQuestion = this.form.get('question.newProfileSecurityQuestion').value;
        profileBean.securityAnswer = this.form.get('question.newProfileSecurityAnswer').value;
        profileBean.temporaryPassword = this.form.get('login.motDePasse').value;
        profileBean.password = this.form.get('passwords.newPassword').value;
        profileBean.confirmPassword = this.form.get('passwords.confirmNewPassword').value;

        this.profileService.authenticateTempPasswordAndCreateSecurityQuestion(profileBean).subscribe(() => {
                this.loading = false;
                this.displayList();
            },
            (error: WebException) => {
                this.loading = false;
                this.setError(error);
            });
    }

    setError(error: any): void {
        this.errMsgLogin = 'GLOBAL_ERROR_MSG';

        if (error.listWarning && error.listWarning.length > 0) {
            const warning = error.listWarning[0];

            if (warning.id === 'recovery' && warning.message === 'ODC_LOGIN_ERR_LIST_OBSOLETE') {
                this.errMsgLogin = 'ODC_LOGIN_ERR_LIST_OBSOLETE';
            } else if (warning.id === 'recoverySection' && warning.message === 'ODC_LOGIN_ERR_QUESTIONNAIRE_NOT_FOUND') {
                this.errMsgLogin = 'ODC_LOGIN_ERR_QUESTIONNAIRE_NOT_FOUND';
            } else if (warning.id === 'temporaryPassword' && warning.message === 'TEMPORARY_PASSWORD_INVALID') {
                this.errMsgLogin = 'ODC_RESET_PASSWORD_ERR_PASSWORD';
            } else if (warning.id === 'password' && warning.message === 'PASSWORD_INVALID_HISTORY') {
                this.errMsgLogin = 'PASSWORD_INVALID_HISTORY';
            } else if (warning.id === 'reCaptcha' && warning.message === 'RECAPTCHA_INVALID') {
                this.errMsgLogin = 'RECAPTCHA_INVALID';
            }

            this.resetRecatpcha();
        }

        setTimeout(() => {
            if ($('.bloc-erreur-generique').length > 0) {
                $('.bloc-erreur-generique').focus();
            }
        }, 0);
    }

    resolved() {
        this.reCaptchaResponse = grecaptcha.enterprise.getResponse();
        this.onSubmit();
    }

    resetRecatpcha() {
        grecaptcha.enterprise.reset();
    }
}
