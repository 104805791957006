<app-odc-field *ngIf="hasBack"
               [type]="constants.FIELD_TYPES.CHECKBOX"
               [form]="form.get('attachedDocuments.' + index)"
               [submitted]="submitted"
               [label]="'USER_IDENTITY_ADDRESS_DIFFERENT' | translate"
               [labelSrOnly]="true"
               [choices]="[{
                id: 'attachedDocumentDifferentAddress',
                binding: '',
                label: 'USER_IDENTITY_ADDRESS_DIFFERENT' | translate
            }]"
               [path]="'attachedDocumentDifferentAddress'"
               [errorOnSubmitOnly]="['requiredFalse']"
></app-odc-field>
<div class="form-group">
    <legend *ngIf="isFieldInError('attachedDocument')" [class.has-error]="isFieldInError('attachedDocument')">
        <div>
            <label>
                <b class="sr-only">{{label || 'TRANSMISSION_ATTACHED_DOCUMENT_LABEL'| translate : {index: index + 1} }}</b>
                <app-error [form]="form" [submitted]="submitted"
                           [path]="'attachedDocuments.' + index + '.attachedDocument'"></app-error>
            </label>
        </div>
    </legend>
    <div class="row">
        <div class="col-md-6" *ngIf="showLabel">
            <div>
                <label>
                    <b>{{ (defaultDocumentLabel || label) | translate : {index: documentOrdinal} }}</b>
                </label>
            </div>
        </div>
        <div [class.col-md-12]="showLabel" [class.col-md-18]="!showLabel" [class.has-error]="isFieldInError('attachedDocument')">
            <input type="file"
                   [attr.id]="'userIdentityFirstFile'"
                   [attr.disabled]="isRequestSaving || attrDisabled ? true : null"
                   class="sr-only index-{{index}}"
                   (change)="onFileChange($event, index)"
                   accept=".pdf,.jpg,.jpeg,.png"
                   [attr.name]="'userIdentityFile' + index + 'Upload'"
                   [attr.aria-hidden]="'true'"/>

            <div [class.input-group]="form.get('attachedDocuments.'+ index +'.attachedDocument').value">
                <label
                    class="sr-only">{{(label || 'TRANSMISSION_ATTACHED_DOCUMENT_LABEL')| translate : {index: index + 1} }}</label>
                <input type="text"
                       [attr.id]="'userIdentityFirstFile-text'"
                       class="form-control"
                       (click)="selectFile('userIdentityFile' + index + 'Upload')"
                       name="attachedDocument_{{index}}"
                       [value]="form.get('attachedDocuments.'+ index +'.attachedDocument').value"
                       readonly
                />
                <span class="input-group-addon file-upload-remove"
                      *ngIf="form.get('attachedDocuments.'+ index +'.attachedDocument').value"
                      (click)="removeFile(index)"
                      tabindex="{{index}}"
                >
                            <span class="sr-only">{{'BTN_REMOVE_FILE'|translate}}</span>
                            &times;
                        </span>
            </div>
        </div>
        <div class="col-md-6">
            <button class="btn btn-primary btn-block btn-upload"
                    [attr.disabled]="isRequestSaving ? true : null"
                    (click)="selectFile('userIdentityFile' + index + 'Upload')">
                <span class="sr-only">{{(label || 'TRANSMISSION_ATTACHED_DOCUMENT_LABEL')| translate : {index: index + 1} }} - </span>
                {{'TRANSMISSION_ATTACHED_DOCUMENT_UPLOAD'|translate}}
            </button>
        </div>
    </div>
</div>
<div class="form-group" *ngIf="this.form.get('attachedDocuments.' + this.index + '.attachedDocumentDifferentAddress').value">
    <legend *ngIf="isFieldInError('attachedDocumentBack')" [class.has-error]="isFieldInError('attachedDocumentBack')">
        <div>
            <label>
                <b class="sr-only">{{(labelDocumentBack || 'TRANSMISSION_ATTACHED_DOCUMENT_LABEL')| translate : {index: index + 1} }}</b>
                <app-error [form]="form" [submitted]="submitted"
                           [path]="'attachedDocuments.' + index + '.attachedDocumentBack'"></app-error>
            </label>
        </div>
    </legend>
    <div class="row">
        <div class="col-md-6">
            <div>
                <label>
                    <b>{{(labelDocumentBack || 'TRANSMISSION_ATTACHED_DOCUMENT_BACK_LABEL')| translate : {
                        index: translate.currentLang === constants.LANG_EN ? secondOrdinalEN :
                            index + 1
                    } }}</b>
                </label>
            </div>
        </div>
        <div class="col-md-12" [class.has-error]="isFieldInError('attachedDocumentBack')">
            <input type="file"
                   [attr.id]="'userIdentityFirstFileBack'"
                   [attr.disabled]="isRequestSaving || attrDisabled ? true : null"
                   class="sr-only index-{{index}}"
                   (change)="onFileChange($event, index, true)"
                   accept=".pdf,.jpg,.jpeg,.png"
                   [attr.name]="'userIdentityFileBack' + index + 'Upload'"
                   [attr.aria-hidden]="'true'"/>

            <div [class.input-group]="form.get('attachedDocuments.'+ index +'.attachedDocumentBack').value">
                <label
                    class="sr-only">{{(label || 'TRANSMISSION_ATTACHED_DOCUMENT_BACK_LABEL')| translate : {
                    index: translate.currentLang === constants.LANG_EN
                        ? secondOrdinalEN : index + 1
                } }}</label>
                <input type="text"
                       [attr.id]="'userIdentityFirstFile-text'"
                       class="form-control"
                       (click)="selectFile('userIdentityFileBack' + index + 'Upload')"
                       name="attachedDocument_{{index}}"
                       [value]="form.get('attachedDocuments.'+ index +'.attachedDocumentBack').value"
                       readonly
                />
                <span class="input-group-addon file-upload-remove" *ngIf="form.get('attachedDocuments.'+ index +'.attachedDocumentBack').value"
                      (click)="removeFile(index, true)" tabindex="{{index}}">
                            <span class="sr-only">{{'BTN_REMOVE_FILE'|translate}}</span>
                            &times;
                        </span>
            </div>
        </div>
        <div class="col-md-6">
            <button class="btn btn-primary btn-block btn-upload"
                    [attr.disabled]="isRequestSaving ? true : null"
                    (click)="selectFile('userIdentityFileBack' + index + 'Upload')">
                <span class="sr-only">{{(labelDocumentBack || 'TRANSMISSION_ATTACHED_DOCUMENT_BACK_LABEL')| translate}} - </span>
                {{'TRANSMISSION_ATTACHED_DOCUMENT_UPLOAD'|translate}}
            </button>
        </div>
    </div>
</div>
