import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {AttachedDocument} from '../models';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AttachedDocumentService {

    private emitLoadedSource = new Subject<any>();
    loadedEmitted = this.emitLoadedSource.asObservable();

    constructor(private http: HttpClient) {
    }

    emitLoaded() {
        this.emitLoadedSource.next();
    }

    public onFileChange(event, doc: AttachedDocument,
                        nameControl: AbstractControl,
                        contentControl: AbstractControl,
                        contentHashControl: AbstractControl): void {

        if (event && event.target && event.target.files && event.target.files.length > 0) {
            const attachedFile = event.target.files[0];

            this.upload(attachedFile, doc, nameControl, contentControl, contentHashControl);
        }
    }

    public checkFileContent(attachedDocument) {
        const formData = new FormData();
        formData.append('attachedDocument', attachedDocument);

        return this.http.post('api/checkFileContent', formData);
    }


    private upload(attachedFile, doc: AttachedDocument, nameControl: AbstractControl, contentControl: AbstractControl, contentHashControl: AbstractControl) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(attachedFile);
        fileReader.onload = () => {
            doc.name = attachedFile.name;
            // convert size to kb
            doc.size = Math.round(attachedFile.size).toString();
            doc.content = (fileReader.result as string).split(',')[1];
            nameControl.setValue(doc.name);
            contentControl.setValue(doc.content);
            contentHashControl.setValue(doc.contentHash);

            this.emitLoaded();
        };
    }
}
